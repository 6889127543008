import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get, FileUpload } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import moment from "moment";
import { immediateToast } from "izitoast-react";
import { Link } from 'react-router-dom';
import { CustomerModal, UserModal, TypeModal } from './Modal.js';
const fmt = require('indian-number-format')

const validation = Yup.object().shape({
    name: Yup.string().required(),
    customer: Yup.string().required(),
    executive: Yup.string().required(),
    startDate: Yup.string().required(),
    expiryDate: Yup.string().required(),
    type: Yup.string().required(),
    // item: Yup.string().required(),
    // description: Yup.string().required(),
    // contractCharges: Yup.string().required(),
    // renewalCharges: Yup.string().required(),
});
const Projects = () => {
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const inputRef = useRef(null);
    const [initValue, setinitValue] = useState({
        name: "",
        customer: "",
        executive: "",
        startDate: "",
        expiryDate: "",
        type: "",
        item: "",
        description: "",
        contractCharges: "",
        renewalCharges: "",
    });
    const [data, setData] = useState([]);
    const [Customerlist, setCustomerlist] = useState([]);
    const [pdf, setpdf] = useState("");
    const [executive, setexecutive] = useState([]);
    const [type, settype] = useState([]);
    const [file, setfilepdf] = useState("");
    const [projectadd, setprojectadd] = useState(false);
    const [typeadd, settypeadd] = useState(false);
    const [project, setproject] = useState(0);
    const [useradd, setuseradd] = useState(false);
    const [Manual, setManual] = useState(false);
    const [customeradd, setcustomeradd] = useState(false);
    const [userval, setuserval] = useState(0);
    const [customerval, setcustomerval] = useState(0);
    const [typeval, settypeval] = useState(0);
    useEffect(() => {
        getpost(true);
    }, []);
    useEffect(() => {
        getuserpost();
        getType();
        getcustomerpost();
        Get(`master/getApplicationSetting`)
            .then((res) => {
                setManual(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const getcustomerpost = async () => {
        Post(`customer/getAllCustomer`, { active: true })
            .then((res) => {
                setCustomerlist(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getType = async () => {
        Post(`master/getType`, { active: true })
            .then((res) => {
                settype(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }

    const getuserpost = async () => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setexecutive(res)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }

    const getpost = async (val) => {
        await Post(`contract/getAllContract`, { active: val })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const changeStatus = async (val, status) => {
        await Post(`contract/removeContract`, { id: val, active: status })
            .then((res) => {
                if (res.success == true) {
                    if (status == true) {
                        getpost(false);
                        showTost("Your data has been successfully activated", "success");
                    }
                    else {
                        getpost(true);
                        showTost("Your data has been successfully deactivated", "success");
                    }
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const get = async (val) => {
        if (val != 0) {
            Post(`contract/getContractById/${val}`)
                .then((res) => {
                    setinitValue({
                        name: res.data[0].Name,
                        customer: res.data[0].Customer?._id,
                        executive: res.data[0].executive?._id,
                        startDate: moment(res.data[0].StartDate).format("YYYY-MM-DD"),
                        expiryDate: moment(res.data[0].ExpiryDate).format("YYYY-MM-DD"),
                        type: res.data[0].Type,
                        item: res.data[0].Item,
                        description: res.data[0].Description,
                        contractCharges: res.data[0].ContractCharges,
                        renewalCharges: res.data[0].RenewalCharges,
                    });
                    setfilepdf(res.data[0].Files);
                    setprojectadd(true);
                    setproject(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const clear = () => {
        setinitValue({
            name: "",
            customer: "",
            executive: "",
            startDate: "",
            expiryDate: "",
            type: "",
            item: "",
            description: "",
            contractCharges: "",
            renewalCharges: "",
        });
        setpdf("");
    }
    const handleSubmit = async (val) => {
        let formdata = new FormData();
        formdata.append("file", pdf);
        formdata.append("name", val.name);
        formdata.append("customer", val.customer);
        formdata.append("executive", val.executive);
        formdata.append("startDate", val.startDate);
        formdata.append("expiryDate", val.expiryDate);
        formdata.append("type", val.type);
        formdata.append("item", val.item);
        formdata.append("description", val.description);
        formdata.append("contractCharges", val.contractCharges);
        formdata.append("renewalCharges", val.renewalCharges);
        if (project == 0) {
            await FileUpload(`contract/addContract`, formdata)
                .then((res) => {
                    if (res.success == true) {
                        clear();
                        getpost(true);
                        setprojectadd(false);
                        showTost("Your data has been Successfully added", "success");
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
        else {
            formdata.append("id", project);
            await FileUpload(`contract/editContract`, formdata)
                .then((res) => {
                    if (res.success == true) {
                        clear();
                        getpost(true);
                        setprojectadd(false);
                        showTost("Your data has been successfully updated", "success");
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const setfile = async (val) => {
        setpdf(val.target.files[0]);
    };

    return (
        <div className='content-wrapper'>
            <div className="mb-2 row align-items-center">
                <div className="col-10">
                    <h4>Projects</h4>
                </div>
                <div className="col-2 mt-md-0 mt-2">
                    <span className='btn btn-sm btn-primary float-right' onClick={() => { setprojectadd(true); setproject(0); }}>
                        <i className="fa fa-plus mr-2" /> Add Project
                    </span>
                </div>
                <div className="row m-0">
                    <div className="col-12 mb-2 p-0">
                        <div className="custom-control custom-switch float-right">
                            <input type="checkbox" className="custom-control-input" defaultChecked id="customSwitch1" data-check_true={true} data-check_false={false} onChange={(event) => {
                                let ele = event.target;
                                let selectedId = ele.checked ? event.target.getAttribute("data-check_true") : event.target.getAttribute("data-check_false");
                                getpost(selectedId)
                            }} />
                            <label className="custom-control-label" for="customSwitch1"> Active/Inactive </label>
                        </div>
                    </div>
                </div>
                <div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Customer</th>
                                <th scope="col">Executive</th>
                                <th scope="col">Contract Charges (₹)</th>
                                <th scope="col">Renewal Charges (₹)</th>
                                <th scope="col">Progress</th>
                                <th scope="col">Action</th> 
                                <th scope="col"></th> 
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((x, i) => (
                                <tr className='bg-light'>
                                    <td>{x.Name}</td>
                                    <td>{x.Customer?.FirstName} {x.Customer?.LastName}</td>
                                    <td>{x.executive?.name}</td>
                                    <td>{x.ContractCharges != null ? fmt.format(x.ContractCharges.toFixed(2)) : "0.00"}</td>
                                    <td>{x.RenewalCharges != null ? fmt.format(x.RenewalCharges.toFixed(2)) : "0.00"}</td>
                                    <td>
                                        <div className="d-flex"><p>{x.progress == null ? "0%" : `${x.progress}%`} </p>
                                            <div className="flex-grow-1 text-end"><span>{x.progress == 100 ? 'Complated' : 'In Progress'}</span></div>
                                        </div>
                                        <div className="progress" style={{ height: '5px' }}>
                                            <div className="bg-primary progress-bar-striped" role="progressbar" aria-valuenow={x.progress} aria-valuemin={0} aria-valuemax={100} style={{ width: `${x.progress}%` }} />
                                        </div>
                                    </td>
                                    <td>
                                        {x.is_active == true ?
                                            <div className=" p-0 card-footer">
                                                <ul className="flex-row simple-list list-group">
                                                    <li><span><Link className='btn btn-sm btn-outline-secondary mr-2' to={`/Ganttchart/${x._id}`}>View Gantt Chart</Link></span></li>
                                                    <li><span><Link className='btn btn-sm btn-outline-secondary' to={`/Process/${x._id}`}>View Process</Link></span></li>
                                                </ul>
                                            </div>
                                            : ""}
                                    </td>
                                    <td>
                                        {x.is_active == true ? <>
                                            <div className="justify-content-end d-flex">
                                                <div className='btn btn-sm btn-success rounded-circle'>
                                                    <i className='fa fa-pencil' onClick={() => { get(x._id); }} />
                                                </div>
                                                <div className='btn btn-sm btn-danger ml-2 rounded-circle'>
                                                    <i className='fa fa-trash' onClick={() => { changeStatus(x._id, false); }} />
                                                </div>
                                            </div>
                                        </>
                                            : <div className="text-end">
                                                <div className='btn btn-sm btn-danger ml-2' onClick={() => { changeStatus(x._id, true); }}>
                                                Set Active
                                                </div>
                                            </div>}
                                    </td>
                                </tr>
                            ) )}
                        </tbody>
                    </table>
                </div>
                    {/* <div className="col-12 row">
                        {data.length == 0 ?
                            <div className="mt-5 text-center">
                                No Record Found
                            </div>
                            : data.map((x, i) => (
                                <div className="col-lg-6">
                                    <div className="social-user-card boxShadow mb-3 p-3">
                                        <div className=" socialprofile">
                                            <div className="social-user-top-content">
                                                <div className='row mb-1'>
                                                    <h5 className="col-9 text-capitalize text-dark">{x.Name}</h5>
                                                    {x.is_active == true ? <>
                                                        <div className="col-3 text-end"><div className='btn btn-sm btn-success ml-3 rounded-circle'>
                                                            <i className='fa fa-pencil' onClick={() => { get(x._id); }} />
                                                        </div>
                                                            <div className='btn btn-sm btn-danger ml-3 rounded-circle'>
                                                                <i className='fa fa-trash' onClick={() => { changeStatus(x._id, false); }} />
                                                            </div>
                                                        </div>
                                                    </>
                                                        : <div className="col-3 text-end"><div className='btn btn-sm btn-danger ml-3' onClick={() => { changeStatus(x._id, true); }}>
                                                            Set Active
                                                        </div></div>}
                                                </div>
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Customer Name</h6>
                                                    <h6 className="col-8 f-13 text-capitalize text-dark">{x.Customer?.FirstName} {x.Customer?.LastName}</h6>
                                                </div>
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Executive</h6>
                                                    <h6 className="col-8 font-weight-normal f-13 text-capitalize text-dark">{x.executive?.name}</h6>
                                                </div>
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Contract Charges (₹)</h6>
                                                    <h6 className="col-8 font-weight-normal f-13 text-capitalize text-dark">
                                                        {x.ContractCharges != null ? fmt.format(x.ContractCharges.toFixed(2)) : "0.00"}</h6>
                                                </div>
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Renewal Charges (₹)</h6>
                                                    <h6 className="col-8 font-weight-normal f-13 text-capitalize text-dark">
                                                        {x.RenewalCharges != null ? fmt.format(x.RenewalCharges.toFixed(2)) : "0.00"}</h6>
                                                </div>
                                                <div className="d-flex mt-3"><p>{x.progress == null ? "0%" : `${x.progress}%`} </p>
                                                    <div className="flex-grow-1 text-end"><span>{x.progress == 100 ? 'Complated' : 'In Progress'}</span></div>
                                                </div>
                                                <div className="progress" style={{ height: '5px' }}>
                                                    <div className="bg-primary progress-bar-striped" role="progressbar" aria-valuenow={x.progress} aria-valuemin={0} aria-valuemax={100} style={{ width: `${x.progress}%` }} />
                                                </div>
                                            </div>
                                        </div>
                                        {x.is_active == true ?
                                            <div className=" p-0 card-footer">
                                                <ul className="flex-row simple-list list-group">
                                                    <li className="list-group-item"><span><Link className='text-dark' to={`/Ganttchart/${x._id}`}>View Gantt Chart</Link></span></li>
                                                    <li className="list-group-item"><span><Link className='text-dark' to={`/Process/${x._id}`}>View Process</Link></span></li>
                                                </ul>
                                            </div>
                                            : ""}
                                    </div>
                                </div>
                            ))}
                    </div> */}
                </div>
                <CModal alignment="center" size="lg" visible={projectadd}>
                    <div className="modal-header">
                        <h4 className="modal-title">{project == 0 ? "Add Project" : "Edit Project"}</h4>
                        <button type="button" className="close" onClick={() => { clear(); setprojectadd(false) }}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <Formik
                            onSubmit={handleSubmit}
                            initialValues={initValue}
                            validationSchema={validation}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}>
                                        <div className="col-12 mb-2">
                                            <div className="row">
                                                <div className='mt-2 col-12'>
                                                    <label>Project Name</label>
                                                    <input type='text' name="name" defaultValue={values.name} placeholder='Enter Project Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className='mt-2 col-6'>
                                                    <label>Customer</label>
                                                    <div className='input-group'>
                                                        <select defaultValue={values.customer} className={!!touched.customer && !!errors.customer ? "form-select is-invalid" : "form-select"} name='customer'>
                                                            <option value=''>Select Customer</option>
                                                            {Customerlist
                                                                .map((x, i) => (
                                                                    <option key={i} value={x._id}>{x.Company} ({x.FirstName} {x.LastName})</option>
                                                                ))}
                                                        </select>
                                                        <span className="input-group-text">
                                                            {values.customer ? (
                                                                <i className='fa fa-pencil' onClick={() => { setcustomerval(values.customer); setcustomeradd(true) }} />
                                                            ) : (
                                                                <i className='fa fa-plus' onClick={() => { setcustomerval(0); setcustomeradd(true) }} />
                                                            )}
                                                            {customeradd && (
                                                                <CustomerModal
                                                                    setcustomeradd={setcustomeradd}
                                                                    customeradd={customeradd}
                                                                    getcustomerpost={getcustomerpost}
                                                                    showTost={showTost}
                                                                    Manual={Manual}
                                                                    setcustomerval={setcustomerval}
                                                                    customerval={customerval}
                                                                />)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='mt-2 col-6'>
                                                    <label>Executive</label>
                                                    <div className='input-group'>
                                                        <select defaultValue={values.executive} className={!!touched.executive && !!errors.executive ? "form-select is-invalid" : "form-select"} name='executive'>
                                                            <option value=''>Select Executive</option>
                                                            {executive
                                                                .map((x, i) => (
                                                                    <option key={i} value={x._id}>{x.name}</option>
                                                                ))}
                                                        </select>
                                                        <span className="input-group-text">
                                                            {values.executive ? (
                                                                <i className='fa fa-pencil' onClick={() => { setuserval(values.executive); setuseradd(true) }} />
                                                            ) : (
                                                                <i className='fa fa-plus' onClick={() => { setuserval(0); setuseradd(true) }} />
                                                            )}
                                                            {useradd && (
                                                                <UserModal
                                                                    useradd={useradd}
                                                                    setuseradd={setuseradd}
                                                                    getuserpost={getuserpost}
                                                                    showTost={showTost}
                                                                    userval={userval}
                                                                    setuserval={setuserval}
                                                                />
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <label><i className="fa fa-calendar text-warning mr-2" />Start Date</label>
                                                    <input type="date" placeholder="Start Date" defaultValue={values.startDate} name='startDate' className={!!touched.startDate && !!errors.startDate ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <label><i className="fa fa-calendar text-warning mr-2" />Expiry Date</label>
                                                    <input type="date" placeholder="Expiry Date" min={values.startDate} defaultValue={values.expiryDate} name='expiryDate' className={!!touched.expiryDate && !!errors.expiryDate ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className='mt-2 col-4'>
                                                    <label>Type</label>
                                                    <div className='input-group'>
                                                        <select defaultValue={values.type} className={!!touched.type && !!errors.type ? "form-select is-invalid" : "form-select"} name='type'>
                                                            <option value=''>Select type</option>
                                                            {type
                                                                .map((x, i) => (
                                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                                ))}
                                                        </select>
                                                        <span className="input-group-text">
                                                            {values.type ? (
                                                                <i className='fa fa-pencil' onClick={() => { settypeval(values.type); settypeadd(true) }} />
                                                            ) : (
                                                                <i className='fa fa-plus' onClick={() => { settypeval(0); settypeadd(true) }} />
                                                            )}
                                                            {typeadd && (
                                                                <TypeModal
                                                                    settypeadd={settypeadd}
                                                                    typeadd={typeadd}
                                                                    getType={getType}
                                                                    showTost={showTost}
                                                                    settypeval={settypeval}
                                                                    typeval={typeval}
                                                                />)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='mt-2 col-8'>
                                                    <label>Item</label>
                                                    <input type='text' defaultValue={values.item} name="item" placeholder='Enter Item' className={!!touched.item && !!errors.item ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className='mt-2 col-12'>
                                                    <label>Description</label>
                                                    <textarea type='text' rows="3" defaultValue={values.description} name="description" placeholder='Enter Description' className={!!touched.description && !!errors.description ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <label><i className="fa fa-inr mr-2" />Contract Charges</label>
                                                    <input type='text' defaultValue={values.contractCharges} name="contractCharges" placeholder='Enter Contract Charges' className={!!touched.contractCharges && !!errors.contractCharges ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <label><i className="fa fa-recycle mr-2" />Renewal Charges</label>
                                                    <input type='text' defaultValue={values.renewalCharges} name="renewalCharges" placeholder='Enter Renewal Charges' className={!!touched.renewalCharges && !!errors.renewalCharges ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-12 mt-4">
                                                    <span className='btn btn-sm btn-info mr-2' onClick={() => { inputRef.current.click(); }}>
                                                        <i className="fa fa-file mr-2" /> <span>Attach File</span>
                                                    </span>
                                                    <input
                                                        style={{ display: 'none' }}
                                                        ref={inputRef}
                                                        type="file"
                                                        accept=".pdf"
                                                        onChange={setfile}
                                                    />
                                                    <label className='mt-2'>{pdf !== "" ? pdf.name : project !== 0 ? file : pdf.name}</label>
                                                </div>
                                                <div className='mt-2 col-12'>
                                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </CModal>
            </div>
        </div>
    );
}

export default Projects;
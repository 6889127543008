import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Card, CardBody, Form } from "react-bootstrap";
import { Post, Get, FileUpload } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import { immediateToast } from "izitoast-react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import './lead-page.scss';
import { Bookmark } from 'react-feather';
import { current } from '@reduxjs/toolkit';
import { ButtonGroup } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import Pagination from "react-js-pagination";
import { SourceModal, ProductModal, UserModal } from './Modal.js';
import Select from "react-select";
import { IconModal } from './Modal.js';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const validationaddtask = Yup.object().shape({
    Name: Yup.string().required(),
    Priority: Yup.string().required(),
    Status: Yup.string().required(),
});
const validation = Yup.object().shape({
    company: Yup.string().required(),
    icon: Yup.string().required(),
    // firstname: Yup.string().required(),
    email: Yup.string().email(),
    mobile: Yup.string().nullable().max(10).min(10).matches(/^[+]?[0-9]+$/),
    // city: Yup.string().required(),
    // state: Yup.string().required(),
    // country: Yup.string().required(),
    // sales: Yup.string().required(),
    // source: Yup.string().required(),
    // product: Yup.string().required(),
});

const validationcontact = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    mobile: Yup.string().max(10).min(10).required().matches(/^[+]?[0-9]+$/),
});

const validationnext = Yup.object().shape({
    date: Yup.string().required(),
    note: Yup.string().required(),
});
const validationremove = Yup.object().shape({
    reason: Yup.string().required(),
});
const Leads = () => {
    const location = useLocation();
    useEffect(() => {
        if (location.state?.leadId) {
            leadbyid(location.state.leadId);
        }
    }, [location.state]);
    const $ = window.$;
    const inputRef = useRef(null);
    const [addlead, setaddlead] = useState(false);
    const [sourceadd, setsourceadd] = useState(false);
    const [displaylead, setdisplaylead] = useState(false);
    const [Next, setNext] = useState([]);
    const [product, setproduct] = useState([]);
    const [sales, setsales] = useState([]);
    const [source, setsource] = useState([]);
    const [lead, setlead] = useState(0);
    const [data, setData] = useState([]);
    const [inactive, setinactive] = useState(false);
    const [deletes, setdeletes] = useState(false);
    const [prospect, setprospect] = useState(false);
    const [contacts, setcontacts] = useState(false);
    const [displayuser, setdisplayuser] = useState({});
    const [nextaction, setnextaction] = useState(false);
    const [contactsdetails, setcontactsdetails] = useState([]);
    const [filterproduct, setfilterproduct] = useState("");
    const [filtericon, setfiltericon] = useState("");
    const [filtersource, setfiltersource] = useState("");
    const [filtersales, setfiltersales] = useState("");
    const [productval, setproductval] = useState(0);
    const [userval, setuserval] = useState(0);
    const [sourceval, setsourceval] = useState(0);
    const [iconval, seticonval] = useState(0);
    // const [filterstartDate, setFilterStartDate] = useState('');
    // const [filterendDate, setFilterEndDate] = useState('');
    // const [filtermonth, setfiltermonth] = useState("");
    const [filterfavorite, setfilterfavorite] = useState(false);
    const [productadd, setproductadd] = useState(false);
    const [State, setState] = useState([]);
    const [active, setactive] = useState("");
    const [isGridView, setIsGridView] = useState(false);
    const [filtername, setfiltername] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [useradd, setuseradd] = useState(false);
    const [Country, setCountry] = useState([]);
    const [City, setCity] = useState([]);
    const [Icon, setIcon] = useState([]);
    const [iconadd, seticonadd] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const NexPage = (NexPage) => {
        setPageNo(NexPage);
    };
    useEffect(() => {
        getpost();
    }, [pageNo, perPage, filtername, filterproduct, filtericon, filtersource, filtersales, filterfavorite, startDate, endDate
        // filtermonth, filterstartDate, filterendDate, 
    ]);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const [users, setusers] = useState([]);
    useEffect(() => {
        getsourcepost();
        getproductpost();
        getuserpost();
        getstatuspost();
        geticonpost();
        Post(`master/getCountry`, { active: true })
            .then((res) => {
                setCountry(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getState`, { active: true })
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getCity`, { active: true })
            .then((res) => {
                setCity(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const geticonpost = async () => {
        Post(`master/getIcon`, { active: true })
            .then((res) => {
                setIcon(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getuserpost = async () => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setsales(res);
                setusers(res);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getpost = async (val) => {
        let skipa = perPage * (pageNo - 1);
        await Post(`lead/getAll`, {
            skip: skipa, per_page: perPage, filter: filtername, source: filtersource, sales: filtersales, favorite: filterfavorite, product: filterproduct, icon: filtericon, startdate: startDate, enddate: endDate, active: true
            // startdate: filterstartDate, enddate: filterendDate, month: filtermonth,
        })
            .then((res) => {
                setData(res.data.data)
                setTotalCount(res.data.count);
            })
            .catch((err) => {
                setTotalCount(0);
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }

    const handleThisMonth = () => {
        const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        //     setFilterStartDate(startOfMonth.toISOString().split('T')[0]);
        //     setFilterEndDate(endOfMonth.toISOString().split('T')[0]);
        setDateRange([startOfMonth, endOfMonth]);
    };

    const handleLastMonth = () => {
        const startOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
        const endOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
        //     setFilterStartDate(startOfLastMonth.toISOString().split('T')[0]);
        //     setFilterEndDate(endOfLastMonth.toISOString().split('T')[0]);
        setDateRange([startOfLastMonth, endOfLastMonth]);
    };
    const getsourcepost = async (val) => {
        await Post(`master/getSource`, { active: true })
            .then((res) => {
                setsource(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getproductpost = async (val) => {
        await Post(`master/getProduct`, { active: true })
            .then((res) => {
                setproduct(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const [initValue, setinitValue] = useState({
        company: "",
        gstno: "",
        title: "",
        firstname: "",
        lastname: "",
        designation: "",
        mobile: "",
        email: "",
        city: "",
        state: "",
        icon: "",
        country: "",
        sales: "",
        source: "",
        product: "",
        address: "",
        requirements: "",
        notes: "",
        CustomerRefrence: "",
    });
    const [initValuecontact, setinitValuecontact] = useState({
        name: "",
        email: "",
        mobile: "",
    });
    const [initValuenext, setinitValuenext] = useState({
        date: "",
        note: "",
    });
    const [initValueremove, setinitValueremove] = useState({
        reason: "",
    });
    const clear = () => {
        setinitValue({
            company: "",
            gstno: "",
            title: "",
            firstname: "",
            lastname: "",
            designation: "",
            mobile: "",
            email: "",
            city: "",
            state: "",
            icon: "",
            country: "",
            sales: "",
            source: "",
            product: "",
            address: "",
            requirements: "",
            notes: "",
            CustomerRefrence: "",
        });
    }
    const clearnext = () => {
        setinitValuenext({
            date: "",
            note: "",
            id: "",
            leadid: ""
        });
    }
    const [datatask, setdatatask] = useState([]);
    const leadbyid = async (val) => {
        if (val != 0) {
            Get(`lead/${val}`)
                .then((res) => {
                    setinitValue({
                        company: res.data.leadList[0].Company,
                        gstno: res.data.leadList[0].GSTNo,
                        title: res.data.leadList[0].Title,
                        firstname: res.data.leadList[0].FirstName,
                        lastname: res.data.leadList[0].LastName,
                        designation: res.data.leadList[0].Designation,
                        mobile: res.data.leadList[0].Mobile,
                        email: res.data.leadList[0].Email,
                        city: res.data.leadList[0].City?._id,
                        state: res.data.leadList[0].State?._id,
                        icon: res.data.leadList[0].Icon?._id,
                        country: res.data.leadList[0].Country?._id,
                        sales: res.data.leadList[0].Sales?._id,
                        source: res.data.leadList[0].Source?._id,
                        product: res.data.leadList[0].Product?._id,
                        address: res.data.leadList[0].Address,
                        requirements: res.data.leadList[0].Requirements,
                        notes: res.data.leadList[0].Notes,
                        CustomerRefrence: res.data.leadList[0].CustomerRefrence
                    })
                    setdisplayuser(res.data.leadList[0]);
                    getNext(val);
                    setdatatask(res.data.tasklist);
                    setdisplaylead(true);
                    setlead(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const getNext = async (val) => {
        Get(`lead/getNext/${val}`)
            .then((res) => {
                setNext(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleSubmit = async (val) => {
        if (lead == 0)
            await Post(`lead/add`, val)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.msg, "warning");
                    }
                    else {
                        clear();
                        setaddlead(false);
                        Swal.fire({
                            title: "Your work has been saved",
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: "#FD711A",
                        }).then(async ({ isConfirmed }) => {
                            if (isConfirmed) {
                                getpost();
                            }
                        });
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        else {
            val.id = lead;
            await Post(`lead/edit`, val)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.msg, "warning");
                    }
                    else {
                        clear();
                        setaddlead(false);
                        Swal.fire({
                            title: "Your work has been saved",
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: "#FD711A",
                        }).then(async ({ isConfirmed }) => {
                            if (isConfirmed) {
                                leadbyid(lead);
                                getpost();
                            }
                        });
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const moveprospect = async () => {
        await Post(`lead/moveToProspect/${lead}`)
            .then((res) => {
                setprospect(false);
                setdisplaylead(false);
                getpost();
                showTost("Lead to prospect Converted", "success");

            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const removelead = async (val) => {
        val.id = lead;
        val.active = false;
        await Post(`lead/remove`, val)
            .then((res) => {
                setinactive(false)
                setdisplaylead(false)
                getpost();
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const activelead = async (leadid) => {
        let val = {};
        val.id = leadid;
        val.active = true;
        val.reason = '';
        await Post(`lead/remove`, val)
            .then((res) => {
                getpost({ active: false, appointment: "all" });
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const favoritelead = async (leadid, status) => {
        let val = {};
        val.id = leadid;
        val.favorite = status;
        await Post(`lead/setAsFavorite`, val)
            .then((res) => {
                getpost();
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const importlead = async (val) => {
        let formdata = new FormData();
        formdata.append("file", val.target.files[0]);
        await FileUpload(`lead/importExcel`, formdata)
            .then((res) => {
                if (res.success == true) {
                    getpost();
                }
                else {
                    showTost(res.msg, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const savecontact = async (val) => {
        val.id = lead;
        await Post(`lead/addOtherContact`, val)
            .then((res) => {
                contactclear();
                $('.contactadd').hide()
                contactbyid(lead);
                showTost("Your data has been Successfully added", "success");
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const editnext = (val) => {
        setinitValuenext({
            id: val._id,
            date: moment(val.date).format("YYYY-MM-DD"),
            note: val.note,
        });
        setnextaction(true)
    }
    const contactclear = () => {
        setinitValuecontact({
            name: "",
            email: "",
            mobile: "",
        });
    }
    const contactbyid = async (val) => {
        if (val != 0) {
            Get(`lead/otherContact/${val}`)
                .then((res) => {
                    setcontactsdetails(res.data);
                    setcontacts(true);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const savenext = async (val) => {
        if (val.id) {
            await Post(`lead/editNext`, val)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.msg, "warning");
                    }
                    else {
                        leadbyid(lead);
                        getpost();
                        setnextaction(false)
                        clearnext();
                        showTost("Your data has been successfully updated", "success");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
        else {
            val.leadid = lead;
            await Post(`lead/addNext`, val)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.msg, "warning");
                    }
                    else {
                        leadbyid(lead);
                        getpost();
                        setnextaction(false)
                        clearnext();
                        showTost("Your data has been Successfully added", "success");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    // task functionality
    const [addtask, setaddtask] = useState(false);
    const [task, settask] = useState(0);
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [initValueaddtask, setinitValueaddtask] = useState({
        id: "",
        Name: "",
        Priority: "",
        Assign: "",
        Status: "",
        Description: "",
        StartDate: null,
        EndDate: null,
        Reporter: [],
    });
    const clearaddtask = () => {
        setinitValueaddtask({
            Name: "",
            Priority: "",
            Assign: "",
            Status: "",
            Description: "",
            StartDate: null,
            EndDate: null,
            Reporter: [],
        });
    }
    const [Status, setStatus] = useState([]);
    const getstatuspost = async (val) => {
        await Post(`master/getStatus`, { active: true })
            .then((res) => {
                setStatus(res.data?.filter(f => f.GroupName == "Tasks"))
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const handleSubmitaddtask = async (val) => {
        val.LeadId = lead;
        await Post(`task/add`, val)
            .then((res) => {
                if (res.success == true) {
                    clearaddtask();
                    setaddtask(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            leadbyid(lead);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const handleEditaddtask = async (val) => {
        val.id = task;
        await Post(`task/edit`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setaddtask(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            leadbyid(lead);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const genrateKeys = () => {
        setFormKey(Math.random() * 1000000);
    }
    const changestatus = (detvalues) => {
        let values = { ...detvalues };
        let name = Status.filter(f => f._id == values.Status)
        values.Assign = name[0]?.Assign;
        setinitValue(values);
        // genrateKeys();
    }
    const getbyid = async (val) => {
        if (val != 0) {
            Get(`task/getById/${val}`)
                .then((res) => {
                    setinitValueaddtask({
                        id: res.data._id,
                        Name: res.data.Name,
                        Priority: res.data.Priority,
                        Description: res.data.Description,
                        Assign: res.data.Assign,
                        Status: res.data.Status,
                        StartDate: moment(res.data.StartDate).format("YYYY-MM-DDTHH:mm"),
                        EndDate: moment(res.data.EndDate).format("YYYY-MM-DDTHH:mm"),
                        Reporter: res.data.Reporter,
                    });
                    setaddtask(true);
                    settask(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const removetask = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Post(
                    `task/remove/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            leadbyid(lead);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    return (
        <div className='content-wrapper lead-page'>
            <CModal alignment="center" size="lg" visible={addtask}>
                <div className="modal-header">
                    <h4 className="modal-title">{task == 0 ? "Add Task" : "Update Task"}</h4>
                    <button type="button" className="close" onClick={() => { clearaddtask(); setaddtask(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={task == 0 ? handleSubmitaddtask : handleEditaddtask}
                        initialValues={initValueaddtask}
                        validationSchema={validationaddtask}
                        key={formkey}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className='col-12 mt-2'>
                                                <label>Name</label>
                                                <input type='text' name="Name" defaultValue={values.Name} placeholder='Enter Task' className={!!touched.Name && !!errors.Name ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Status</label>
                                                <select name="Status" defaultValue={values.Status} onChange={handleChange} onBlur={() => { changestatus(values) }} className={!!touched.Status && !!errors.Status ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Status</option>
                                                    {Status
                                                        .map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Assign to :</label>
                                                <select name="Assign" defaultValue={values.Assign} className={!!touched.Assign && !!errors.Assign ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Assign</option>
                                                    {users
                                                        .map((x, i) => (
                                                            <option key={i} value={x._id}>{x.name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Priority</label>
                                                <select name="Priority" defaultValue={values.Priority} className={!!touched.Priority && !!errors.Priority ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Priority</option>
                                                    <option>High</option>
                                                    <option>Normal</option>
                                                    <option>Low</option>
                                                </select>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Start Date</label>
                                                <input type="datetime-local" placeholder="Date" defaultValue={values.StartDate} name='StartDate' className={!!touched.StartDate && !!errors.StartDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>End Date</label>
                                                <input type="datetime-local" placeholder="Date" defaultValue={values.EndDate} name='EndDate' className={!!touched.EndDate && !!errors.EndDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Reporter</label>
                                                <Select
                                                    isMulti
                                                    name="Reporter"
                                                    value={
                                                        users.filter(
                                                            (x) => {
                                                                if (values.Reporter && values.Reporter.length) {
                                                                    return values.Reporter.indexOf(x._id) != -1;
                                                                } else {
                                                                    return values.Reporter == x._id;
                                                                }
                                                            }
                                                        )
                                                            .map((x) => ({
                                                                value: x._id,
                                                                label: x.name,
                                                            }))}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "Reporter",
                                                            e.map((x) => x.value)
                                                        )
                                                    }
                                                    options={users.map((x) => ({
                                                        value: x._id,
                                                        label: x.name,
                                                    }))}
                                                    className={touched.Reporter && errors.Reporter ? "is-invalid" : ""}
                                                />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label>Description</label>
                                                <textarea type='text' rows="3" defaultValue={values.Description} name="Description" placeholder='Enter Description' className={!!touched.Description && !!errors.Description ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <button type="submit" className='btn font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
            <div className=" ">
                <div className="mb-2 mt-2 row justify-content-between ">
                    <div className="col-sm-12 col-md-3">
                        <h2>Raw Leads</h2>
                    </div>
                    <div className="col-sm-12 col-md-9">
                        <div className='top-actionbar'>
                            <span>
                                <button title="Show Starred Leads" className={'btn btn-sm mr-2 ' + (!filterfavorite ? 'btn-outline-warning' : 'btn-warning')} onClick={() => setfilterfavorite(!filterfavorite)}>
                                    {filterfavorite == false ? <i className="fa fa-star-o text-warning" /> :
                                        <i className="fa fa-star text-white" />}
                                </button>
                            </span>

                            <span>
                                <button className='btn btn-sm btn-primary mr-2' onClick={() => { setaddlead(true); clear(); setlead(0); }}>
                                    <i className="fa fa-plus mr-2" /> Add Lead
                                </button>
                            </span>
                            <span>
                                <button className='btn btn-sm btn-info mr-2' onClick={() => { inputRef.current.click(); }}>
                                    <i className="fa fa-long-arrow-down" /> <span>Import</span>
                                </button>
                            </span>
                            <input
                                style={{ display: 'none' }}
                                ref={inputRef}
                                type="file"
                                accept=".xls,.xlsx"
                                onChange={importlead}
                            />
                            <span>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn btn-sm btn-dark mr-2"
                                    table="table-to-xls"
                                    title="Export to Excel"
                                    filename="Leads"
                                    sheet="tablexls"
                                    buttonText={<i className="fa fa-file-excel-o" />}
                                />
                            </span>
                            <span>
                                <ButtonGroup>
                                    <Btn size="sm" color={isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                    </Btn>
                                    <Btn size="sm" color={!isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg>
                                    </Btn>
                                </ButtonGroup>
                            </span>
                        </div>
                    </div>
                </div>
                <div className=" mb-2 mt-2 row">
                    <div className="col-6 col-sm-3">
                        <input type="text" placeholder="Search Company Name" onKeyPress={(event) => {
                            if (event.nativeEvent.key === 'Enter') {
                                setfiltername(event.target.value)
                            }
                        }} className="form-control" />
                    </div>
                    <div className="col-6 col-sm-3">
                        <div className='input-group'>
                            <span className='input-group-text'><i className="fa fa-wifi text-danger" /></span>
                            <select className="form-select" value={filtersource} onChange={(e) => setfiltersource(e.target.value)}>
                                <option value="">Select Source</option>
                                {source
                                    .map((x, i) => (
                                        <option key={i} value={x._id}>{x.Name}</option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-6 col-sm-3">
                        <div className='input-group'>
                            <span className='input-group-text'><i className="fa fa-user text-warning" /></span>
                            <select className="form-select" onChange={(e) => setfiltersales(e.target.value)}>
                                <option value="">Select Sales</option>
                                {sales
                                    .map((x, i) => (
                                        <option key={i} value={x._id}>{x.name}</option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-6 col-sm-3">
                        <div className='input-group'>
                            <span className='input-group-text'><i className="fa fa-th-large text-success" /></span>
                            <select className="form-select" onChange={(e) => setfilterproduct(e.target.value)}>
                                <option value="">Select Product</option>
                                {product
                                    .map((x, i) => (
                                        <option key={i} value={x._id}>{x.Name}</option>
                                    ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row mb-2 mt-2 align-items-center">
                    {/* <div className="col-6 col-sm-3 col-md-3">
                        <span className={active == "this" ? "btn btn-sm btn-info mr-2" : "btn btn-sm border border-info mr-2"} onClick={() => { setfiltermonth("this"); setactive("this"); }}>
                            This Month
                        </span>
                    </div>
                    <div className="col-6 col-sm-3 col-md-2">
                        <span className={active == "last" ? "btn btn-sm btn-primary mr-2" : "btn btn-sm border border-primary mr-2"} onClick={() => { setfiltermonth("last"); setactive("last"); }}>
                            Last Month
                        </span>
                    </div> */}
                    <div className="col-6 col-sm-3 col-md-3">
                        <span className="btn btn-sm border border-info mr-2" onClick={handleThisMonth}>
                            This Month
                        </span>
                        <span className="btn btn-sm border border-info mr-2" onClick={handleLastMonth}>
                            Last Month
                        </span>
                    </div>

                    {/* <div className="col-md-2">
                        <label>Start Date</label>
                        <input type='date' value={filterstartDate} onChange={(e) => setFilterStartDate(e.target.value)} className="form-control" />
                    </div>
                    <div className="col-md-2">
                        <label>End Date</label>
                        <input type='date' value={filterendDate} onChange={(e) => setFilterEndDate(e.target.value)} className="form-control" />
                    </div> */}

                    <div className="col-md-3 text-end">
                        <div className='input-group'>
                            <span className='input-group-text'><i className="fa fa-linode text-success" /></span>
                            <select className="form-select" value={filtericon} onChange={(e) => setfiltericon(e.target.value)}>
                                <option value="">Select Icon</option>
                                {Icon
                                    .map((x, i) => (
                                        <option key={i} value={x._id}>{x.Name}</option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <DatePicker
                            selected={startDate}
                            onChange={(update) => setDateRange(update)}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            isClearable={true}
                            className="form-control datepickerStyle"
                            placeholderText="Select Start to End Date Range"
                        />
                    </div>
                </div>
            </div>

            <div className='bookmark-tabcontent mt-3'>
                <div className={"details-bookmark  text-center " + (isGridView ? "list-bookmark" : '')} id="list-bookmark1">
                    <div className="row" id="bookmarkData">
                        {data.length == 0 ? <div className="mt-5">
                            No Record Found
                        </div> : data.map(item => (
                            <div className="col-xl-3 col-md-4 xl-50">
                                <div className="card card-with-border bookmark-card o-hidden">
                                    <div className="details-website">
                                        <div className="desciption-data">
                                            <div className="title-bookmark">
                                                <div className="row mb-2">
                                                    <div className='col-lg-8 col-md-7 col-12'>
                                                        <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => { leadbyid(item._id); }}>
                                                            <div>
                                                                <h6><i className="fa fa-building mr-2" />{item.Company} &nbsp;</h6>
                                                            </div>
                                                            <div>{item.GSTNo && <span>({item.GSTNo})</span>}</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4 col-md-5 col-12'>
                                                        <div className='d-flex align-items-center justify-content-end'>
                                                            <span className='mr-2' onClick={() => setfiltericon(item.Icon?._id || '')}>
                                                                {item.Icon?.Name && <span className="d-flex ml-auto btn btn-sm btn-info">{item.Icon?.Name}</span>}
                                                            </span>
                                                            <span>
                                                                {item.is_favorite == false ?
                                                                    <i className="btn btn-sm btn-outline-warning fa fa-star-o" onClick={() => { favoritelead(item._id, true); }} />
                                                                    :
                                                                    <i className="btn btn-sm btn-warning fa fa-star" onClick={() => { favoritelead(item._id, false); }} />
                                                                }
                                                                {/* <i className='ml-2 btn btn-sm btn-success fa fa-pencil' onClick={() => { leadbyid(item._id); }} /> */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <span><i className="fa fa-phone text-info mr-2" />{item.Mobile ? <a href={`tel:${item.Mobile}`}>{item.Mobile}</a> : 'N/A'} </span>
                                                    <span className="ml-4"><i className="fa fa-envelope text-primary mr-2" />{item.Email ? item.Email : 'N/A'}</span>
                                                    <span className="d-flex ml-auto" onClick={() => setfiltersource(item.Source?._id || '')}><i className="fa fa-wifi text-danger align-self-center mr-2" />{item.Source?.Name ? item.Source.Name : 'N/A'}</span>
                                                </div>
                                                <div>
                                                    <span><i className="fa fa-map-marker text-warning mr-2" />{item.City?.Name} {item.State?.Name} {item.Country?.Name}</span>
                                                    {!item.City?.Name && !item.State?.Name && !item.Country?.Name && <span>N/A</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {data.length == 0 ? "" :
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={perPage}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            linkclassName={"page-link"}
                            onChange={NexPage}
                            itemClass={"page-item"}
                            linkClass={"page-link"}
                        />
                    }
                </div>
            </div>

            <table id="table-to-xls" style={{ display: 'none' }}>
                <thead>
                    <tr>
                        <th>Company</th>
                        <th>GST No.</th>
                        <th>Contact</th>
                        <th>Designation</th>
                        <th>Sales</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>City</th>
                        <th>Address</th>
                        <th>State</th>
                        <th>Country</th>
                        <th>Source</th>
                        <th>Product</th>
                        <th>Since</th>
                        <th>Requirement</th>
                        <th>Icon</th>
                        <th>Notes</th>
                        <th>Next Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => (
                        <tr>
                            <td>{item.Company}</td>
                            <td>{item.GSTNo}</td>
                            <td>{item.FirstName} {item.LastName}</td>
                            <td>{item.Designation}</td>
                            <td>{item.sales?.name}</td>
                            <td>{item.Mobile}</td>
                            <td>{item.Email}</td>
                            <td>{item.City?.Name}</td>
                            <td>{item.Address}</td>
                            <td>{item.State?.Name}</td>
                            <td>{item.Country?.Name}</td>
                            <td>{item.Source?.Name}</td>
                            <td>{item.Product?.Name}</td>
                            <td>{moment(item.LeadSince).format("DD-MMM-YYYY")}</td>
                            <td>{item.Requirements}</td>
                            <td>{item.Icon?.Name}</td>
                            <td>{item.Notes}</td>
                            <td>{item.NextTalk == null ? "" : moment(item.NextTalk?.date).format("DD-MMM-YYYY")}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <CModal alignment="center" size="lg" visible={addlead}>
                <div className="modal-header">
                    <h4 className="modal-title">{lead == 0 ? "Add Lead" : "Edit Lead"}</h4>
                    <button type="button" className="close" onClick={() => { setaddlead(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className="col-8 mb-2">
                                                <label><i className="fa fa-building mr-2" />Company</label>
                                                <input type="text" placeholder="Company" defaultValue={values.company} name='company' className={!!touched.company && !!errors.company ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-4 mb-2">
                                                <label>GST No.</label>
                                                <input type="text" defaultValue={values.gstno} className={!!touched.gstno && !!errors.gstno ? "form-control is-invalid" : "form-control"} name='gstno' placeholder="GST No." />
                                            </div>
                                            <div className="col-3 mb-2">
                                                <label><i className="fa fa-user text-danger mr-2" />Title</label>
                                                <select defaultValue={values.title} className={!!touched.title && !!errors.title ? "form-select is-invalid" : "form-select"} name='title'>
                                                    <option value="">Select</option>
                                                    <option value="Mr.">Mr.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                    <option value="Ms.">Ms.</option>
                                                </select>
                                            </div>
                                            <div className="col-4 mb-2">
                                                <label>First Name</label>
                                                <input type="text" defaultValue={values.firstname} className={!!touched.firstname && !!errors.firstname ? "form-control is-invalid" : "form-control"} name='firstname' placeholder="First Name" />
                                            </div>
                                            <div className="col-4 mb-2">
                                                <label>Last Name</label>
                                                <input type="text" defaultValue={values.lastname} className={!!touched.lastname && !!errors.lastname ? "form-control is-invalid" : "form-control"} name='lastname' placeholder="Last Name" />
                                            </div>
                                            <div className="col-6 mb-2">
                                                <label><i className="fa fa-briefcase text-warning mr-2" />Designation</label>
                                                <input type="text" defaultValue={values.designation} placeholder="Designation" name='designation' className={!!touched.designation && !!errors.designation ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-6 mb-2">
                                                <label><i className="fa fa-th-large text-success mr-2" />Product</label>
                                                <div className='input-group'>
                                                    <select
                                                        defaultValue={values.product || ""}
                                                        className={!!touched.product && !!errors.product ? "form-select is-invalid" : "form-select"}
                                                        name='product'
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select Product</option>
                                                        {product.map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.product ? (
                                                            <i className='fa fa-pencil' onClick={() => { setproductval(values.product); setproductadd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setproductval(0); setproductadd(true) }} />
                                                        )}
                                                        {productadd && (
                                                            <ProductModal
                                                                setproductadd={setproductadd}
                                                                productadd={productadd}
                                                                getproductpost={getproductpost}
                                                                showTost={showTost}
                                                                setproductval={setproductval}
                                                                productval={productval}
                                                            />)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-2">
                                                <label><i className="fa fa-phone text-info mr-2" />Mobile No.</label>
                                                <input type="text" placeholder="Mobile No." defaultValue={values.mobile} name='mobile' maxLength={10} className={!!touched.mobile && !!errors.mobile ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-6 mb-2">
                                                <label><i className="fa fa-envelope text-primary mr-2" />Email</label>
                                                <input type="email" placeholder="Email" defaultValue={values.email} name='email' className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-6 mb-2">
                                                <label><i className="fa fa-wifi mr-2" />Source</label>
                                                <div className='input-group'>
                                                    <select defaultValue={values.source} className={!!touched.source && !!errors.source ? "form-select is-invalid" : "form-select"} name='source'>
                                                        <option>Select Source</option>
                                                        {source
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.Name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.source ? (
                                                            <i className='fa fa-pencil' onClick={() => { setsourceval(values.source); setsourceadd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setsourceval(0); setsourceadd(true) }} />
                                                        )}
                                                        {sourceadd && (
                                                            <SourceModal
                                                                setsourceadd={setsourceadd}
                                                                sourceadd={sourceadd}
                                                                getsourcepost={getsourcepost}
                                                                showTost={showTost}
                                                                setsourceval={setsourceval}
                                                                sourceval={sourceval}
                                                            />)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-2">
                                                <label>Customer Refrence</label>
                                                <input type="text" placeholder="Customer Refrence" defaultValue={values.CustomerRefrence} name='CustomerRefrence' maxLength={10} className={!!touched.CustomerRefrence && !!errors.CustomerRefrence ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-6 mb-2">
                                                <label><i className="fa fa-user text-secondary mr-2" />Sales</label>
                                                <div className='input-group'>
                                                    <select defaultValue={values.sales} className={!!touched.sales && !!errors.sales ? "form-control is-select" : "form-select"} name='sales'>
                                                        <option>Select Sales</option>
                                                        {sales
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.sales ? (
                                                            <i className='fa fa-pencil' onClick={() => { setuserval(values.sales); setuseradd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setuserval(0); setuseradd(true) }} />
                                                        )}
                                                        {useradd && (
                                                            <UserModal
                                                                useradd={useradd}
                                                                setuseradd={setuseradd}
                                                                getuserpost={getuserpost}
                                                                showTost={showTost}
                                                                userval={userval}
                                                                setuserval={setuserval}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-2">
                                                <label>Icon</label>
                                                <div className='input-group'>
                                                    <select defaultValue={values.icon} className={!!touched.icon && !!errors.icon ? "form-select is-invalid" : "form-select"} name='icon'>
                                                        <option>Select Icon</option>
                                                        {Icon.map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.icon ? (
                                                            <i className='fa fa-pencil' onClick={() => { seticonval(values.icon); seticonadd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { seticonval(0); seticonadd(true) }} />
                                                        )}
                                                        {iconadd && (
                                                            <IconModal
                                                                seticonadd={seticonadd}
                                                                iconadd={iconadd}
                                                                geticonpost={geticonpost}
                                                                showTost={showTost}
                                                                seticonval={seticonval}
                                                                iconval={iconval}
                                                            />)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4 mb-2">
                                                <label><i className="fa fa-globe text-danger mr-2" />Country</label>
                                                <select defaultValue={values.country} className={!!touched.country && !!errors.country ? "form-select is-invalid" : "form-select"} name='country'>
                                                    <option>Select Country</option>
                                                    {
                                                        Country.map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-4 mb-2">
                                                <label><i className="fa fa-map-marker text-warning mr-2" />State</label>
                                                <select defaultValue={values.state} className={!!touched.state && !!errors.state ? "form-select is-invalid" : "form-select"} name='state'>
                                                    <option>Select State</option>
                                                    {State.filter((x) => x.Country?._id == values.country).map((x, i) => (
                                                        <option key={i} value={x._id}>{x.Name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-4 mb-2">
                                                <label><i className="fa fa-fort-awesome text-success mr-2" />City</label>
                                                <select name='city' defaultValue={values.city} className={!!touched.city && !!errors.city ? "form-control is-invalid" : "form-control"}>
                                                    <option>Select City</option>
                                                    {
                                                        City.filter((x) => x.State?._id == values.state).map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <label><i className="fa fa-address-card-o text-info mr-2" />Address</label>
                                                <textarea type="text" defaultValue={values.address} placeholder="Address" name='address' className={!!touched.address && !!errors.address ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-12 mb-2">
                                                <label><i className="fa fa-comment text-primary mr-2" />Requirements</label>
                                                <textarea rows="3" type="text" defaultValue={values.requirements} placeholder="Requirements" name='requirements' className={!!touched.requirements && !!errors.requirements ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-12 mb-2">
                                                <label><i className="fa fa-sticky-note text-danger mr-2" />Notes</label>
                                                <textarea rows="3" type="text" defaultValue={values.notes} placeholder="Notes" name='notes' className={!!touched.notes && !!errors.notes ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </CModal>
            <CModal alignment="center" visible={inactive}>
                <div className="modal-header">
                    <h4 className="modal-title">Mark as Inactive</h4>
                    <button type="button" className="close" onClick={() => setinactive(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={removelead}
                        initialValues={initValueremove}
                        validationSchema={validationremove}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <input type='text' name='reason' className={!!touched.reason && !!errors.reason ? "form-control is-invalid" : "form-control"} placeholder='Reason' />
                                    <button type="submit" className='btn btn-sm btn-success font-weight-bold mt-3'><i className='fa fa-check' /> Mark as Inactive</button>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </CModal>
            <CModal alignment="center" size="sm" visible={deletes}>
                <div className="modal-header">
                    <h4 className="modal-title">Allegiance Water Management</h4>
                    <button type="button" className="close" onClick={() => setdeletes(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <label>Are you sure you wish to delete this lead?</label>
                </div>
                <div className="modal-footer">
                    <button type="button" className='btn btn-sm font-weight-bold'> YES</button>
                    <button type="button" className='btn btn-sm font-weight-bold' onClick={() => setdeletes(false)}> NO</button>
                </div>
            </CModal>
            <CModal alignment="center" size="sm" visible={prospect}>
                <div className="modal-header">
                    <h4 className="modal-title">Allegiance Water Management</h4>
                    <button type="button" className="close" onClick={() => setprospect(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <label>Are you sure you wish to convert this lead to prospect?</label>
                </div>
                <div className="modal-footer">
                    <button type="button" className='btn btn-sm font-weight-bold' onClick={() => moveprospect()}> YES</button>
                    <button type="button" className='btn btn-sm font-weight-bold' onClick={() => setprospect(false)}> NO</button>
                </div>
            </CModal >
            <CModal alignment="center" visible={contacts}>
                <div className="modal-header">
                    <h4 className="modal-title">Contacts - {displayuser.Company}</h4>
                    <button type="button" className="close" onClick={() => setcontacts(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <h4 className='text-success'>Primary Contact</h4>
                    <div className='row'>
                        <div className='col-4'>{displayuser.Title} {displayuser.FirstName} {displayuser.LastName}</div>
                        <div className='col-3'>{displayuser.Mobile}</div>
                        <div className='col-3'>{displayuser.Email}</div>
                    </div>
                    <h4 className='text-info'>Contacts</h4>
                    {contactsdetails.map((x, i) => {
                        return (
                            <div className='row'>
                                <div className='col-4'>{x.Name}</div>
                                <div className='col-3'>{x.Mobile}</div>
                                <div className='col-3'>{x.Email}</div>
                            </div>
                        );
                    })}
                    <div className='row mt-3'>
                        <div className='text-warning col-4'>Other Contacts</div>
                        <div className='btn btn-sm btn-warning col-2' onClick={() => $('.contactadd').show()}><i className="fa fa-plus mr-2" />Add</div>
                    </div>
                    <Formik
                        onSubmit={savecontact}
                        initialValues={initValuecontact}
                        validationSchema={validationcontact}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='row mt-3 contactadd' style={{ display: "none" }}>
                                        <div className='col-4'><input type='text' placeholder='Contact Name' defaultValue={values.name} name='name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} /></div>
                                        <div className='col-3'><input type="text" placeholder="Mobile No." defaultValue={values.mobile} name='mobile' maxLength={10} className={!!touched.mobile && !!errors.mobile ? "form-control is-invalid" : "form-control"} /></div>
                                        <div className='col-3'><input type='email' placeholder='Email' defaultValue={values.email} name='email' className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} /></div>
                                        <div className='col-1'><button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /></button></div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </CModal>
            <CModal alignment="center" visible={nextaction}>
                <div className="modal-header">
                    <h4 className="modal-title">Enter Next Action for {displayuser.Company}</h4>
                    <button type="button" className="close" onClick={() => setnextaction(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={savenext}
                        initialValues={initValuenext}
                        validationSchema={validationnext}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='row'>
                                        <div className='col-6'><input type='date' name='date' value={values.date} className={!!touched.date && !!errors.date ? "form-control is-invalid" : "form-control"} /></div>
                                        <div className='col-12 mt-2'><textarea type='text' value={values.note} name='note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} placeholder='Note' /></div>
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </CModal>
            <CModal alignment="center" size="lg" visible={displaylead}>
                <div className="modal-header">
                    <h4 className="modal-title">{displayuser.Company}</h4>
                    <button type="button" className="close" onClick={() => setdisplaylead(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className='row m-0'>
                        <div className='col-6'>
                            <div>
                                <i className="fa fa-user mr-2" /> {displayuser.Title} {displayuser.FirstName} {displayuser.LastName}
                            </div>
                            <div>
                                <i className="fa fa-envelope mr-2" />{displayuser.Email}
                            </div>
                            <div>
                                <i className="fa fa-phone mr-2" /> {displayuser.Mobile}
                            </div>
                        </div>
                        <div className='col-6'>
                            <div>
                                <i className="fa fa-calendar mr-2" /> Since {moment(displayuser.LeadSince).format("DD-MMM")}
                            </div>
                            <div>
                                <i className="fa fa-wifi mr-2" /> {displayuser.Source?.Name}
                            </div>
                            <div>
                                <i className="fa fa-user mr-2 ml-1" /> {displayuser.Sales?.name}
                            </div>
                        </div>
                        <div className='col-12'>
                            {displayuser.is_active == true ?
                                <span className='btn btn-success btn-sm mr-1' onClick={() => setinactive(true)}>
                                    <i className="fa fa-ban mr-2" />
                                    Inactive
                                </span>
                                : ""}
                            <span className='btn btn-info btn-sm mr-1' onClick={() => setaddlead(true)}>
                                <i className="fa fa-pencil mr-2" /> Edit
                            </span>
                            <span className='btn btn-success btn-sm mr-1' onClick={() => setprospect(true)}>
                                <i className="fa fa-check-square-o mr-2" /> Mark Prospect
                            </span>
                            <span className='btn btn-warning btn-sm mr-1' onClick={() => contactbyid(lead)}>
                                <i className="fa fa-phone mr-2" /> Other Contacts
                            </span>
                            <span className='btn btn-warning btn-sm mr-1' onClick={() => { setaddtask(true); settask(0); }}>
                                <i className="fa fa-plus mr-2" /> Add Task
                            </span>
                        </div>
                        <div className='col-5'>
                            <div className="border rounded border-danger mt-2 p-2">
                                <div>
                                    <div className="col-12 text-danger">
                                        <span><i className="fa fa-comment" /> Needs</span>
                                    </div>
                                    <div className="col-12">
                                        <span>{displayuser.Requirements}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="col-12 text-danger">
                                        <span><i className="fa fa-sticky-note" /> Notes</span>
                                    </div>
                                    <div className="col-12">
                                        <span>{displayuser.Notes}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="border rounded border-success p-2 mt-2" >
                                <div>
                                    <div className="col-12 text-success" >
                                        <span><i className="fa fa-calendar" /> Next Action</span>
                                    </div>
                                    <div className="col-12">
                                        {displayuser.NextTalk !== null ?
                                            <span>{displayuser.NextTalk?.note}<br />{moment(displayuser.NextTalk?.date).format("DD-MMM-YYYY")}</span>
                                            : ""}</div>
                                    <div className="col-12">
                                        <span className='btn btn-sm btn-success' onClick={() => { clearnext(); setnextaction(true) }}>Enter Next Action</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-7'>
                            <div className="border rounded border-info mt-2 p-2">
                                <div className="col-12 text-info">
                                    <span>Interactions</span>
                                </div>
                                <div style={{ overflow: "auto", height: '250px' }}>
                                    {Next.map((x, i) => (
                                        <div className='row card p-3'><div className='text-secondary' style={{ fontSize: '12px' }}>{x.user?.name}<div className='float-right'>{moment(x.date).format("DD-MMM")} <button className='btn btn-sm btn-success' onClick={() => editnext(x)}><i className='fa fa-pencil' /></button></div></div>
                                            <div>{x.note}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="border rounded border-info mt-2 p-2">
                                <div className="text-info">
                                    <span>Tasks</span>
                                </div>
                                {datatask.map((x, i) => (
                                    <div className='card p-2'>
                                        <div className='row'>
                                            <div className='col-sm-10'>
                                                <div>Task Name: <b className='ml-2'>{x.Name}</b></div>
                                                <div>Assign: <b className='ml-2'>{x.Assign?.name}</b></div>
                                                <div>Status: <b className='ml-2'>{x.Status?.Name}</b></div>
                                                <div>End Date: <b className='ml-2'>{moment(x.EndDate).format('lll')}</b></div>
                                            </div>
                                            <div className='col-sm-2 text-end'>
                                                <i className='btn btn-sm btn-success fa fa-pencil mr-2' onClick={() => getbyid(x._id)} />
                                                <i className='btn btn-danger btn-sm fa fa-trash' onClick={() => removetask(x._id)} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </CModal>
        </div >
    );
}

export default Leads;
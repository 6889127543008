import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get, FileUpload } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import moment from "moment";
import Select from "react-select";
import { immediateToast } from "izitoast-react";
import { ButtonGroup } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { Link } from 'react-router-dom';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { CustomerModal, TypeModal } from './Modal.js';

const validation = Yup.object().shape({
    customer: Yup.string().required(),
    contractNo: Yup.string().required(),
    startDate: Yup.string().required(),
    expiryDate: Yup.string().required(),
    type: Yup.string().required(),
    item: Yup.string().required(),
    description: Yup.string().required(),
    contractCharges: Yup.string().required(),
    renewalCharges: Yup.string().required(),
});
const Subscription = () => {
    const $ = window.$;
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const inputRef = useRef(null);
    const [initValue, setinitValue] = useState({
        customer: "",
        contractNo: "",
        startDate: "",
        expiryDate: "",
        type: "",
        item: "",
        description: "",
        contractCharges: "",
        renewalCharges: "",
    });
    const [data, setData] = useState([]);
    const [Customerlist, setCustomerlist] = useState([]);
    const [pdf, setpdf] = useState("");
    const [type, settype] = useState([]);
    const [file, setfilepdf] = useState("");
    const [Manual, setManual] = useState(false);
    const [Contractadd, setContractadd] = useState(false);
    const [typeadd, settypeadd] = useState(false);
    const [Contract, setContract] = useState(0);
    const [customeradd, setcustomeradd] = useState(false);
    const [isGridView, setIsGridView] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(1);
    const [customerval, setcustomerval] = useState(0);
    const [typeval, settypeval] = useState(0);
    const [filtername, setfiltername] = useState("");
    useEffect(() => {
        getType();
        getcustomerpost();
        getpost(true);
        Get(`master/getApplicationSetting`)
            .then((res) => {
                setManual(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const NexPage = (NexPage) => {
        setPageNo(NexPage);
    };
    useEffect(() => {
        getpost(true);
    }, [pageNo, perPage, filtername]);
    const getcustomerpost = async () => {
        Post(`customer/getAllCustomer`, { active: true })
            .then((res) => {
                setCustomerlist(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }

    const getType = async () => {
        Post(`master/getType`, { active: true })
            .then((res) => {
                settype(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }

    const getpost = async (val) => {
        let skipa = perPage * (pageNo - 1);
        await Post(`subscription/getAllSubscription`, { active: val, skip: skipa, per_page: perPage, filter: filtername })
            .then((res) => {
                setData(res.data.data)
                setTotalCount(res.data.count);
            })
            .catch((err) => {
                setTotalCount(0);
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const get = async (val) => {
        if (val != 0) {
            Post(`subscription/getSubscriptionById/${val}`)
                .then((res) => {
                    setinitValue({
                        contractNo: res.data[0].ContractNo,
                        customer: res.data[0].Customer?._id,
                        startDate: moment(res.data[0].StartDate).format("YYYY-MM-DD"),
                        expiryDate: moment(res.data[0].ExpiryDate).format("YYYY-MM-DD"),
                        type: res.data[0].Type,
                        item: res.data[0].Item,
                        description: res.data[0].Description,
                        contractCharges: res.data[0].ContractCharges,
                        renewalCharges: res.data[0].RenewalCharges,
                    });
                    setfilepdf(res.data[0].Files);
                    setContractadd(true);
                    setContract(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const deleteSubscription = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Get(
                    `subscription/deleteSubscription/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(true);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const clear = () => {
        setinitValue({
            customer: "",
            contractNo: "",
            startDate: "",
            expiryDate: "",
            type: "",
            item: "",
            description: "",
            contractCharges: "",
            renewalCharges: "",
        });
        setpdf("");
    }
    const handleSubmit = async (val) => {
        let formdata = new FormData();
        formdata.append("file", pdf);
        formdata.append("customer", val.customer);
        formdata.append("contractNo", val.contractNo);
        formdata.append("startDate", val.startDate);
        formdata.append("expiryDate", val.expiryDate);
        formdata.append("type", val.type);
        formdata.append("item", val.item);
        formdata.append("description", val.description);
        formdata.append("contractCharges", val.contractCharges);
        formdata.append("renewalCharges", val.renewalCharges);
        if (Contract == 0) {
            await FileUpload(`subscription/addSubscription`, formdata)
                .then((res) => {
                    if (res.success == true) {
                        clear();
                        setContractadd(false);
                        Swal.fire({
                            title: "Your work has been saved",
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: "#FD711A",
                        }).then(async ({ isConfirmed }) => {
                            if (isConfirmed) {
                                getpost(true);
                            }
                        });
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
        else {
            formdata.append("id", Contract);
            await FileUpload(`subscription/editSubscription`, formdata)
                .then((res) => {
                    if (res.success == true) {
                        clear();
                        setContractadd(false);
                        Swal.fire({
                            title: "Your work has been saved",
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: "#FD711A",
                        }).then(async ({ isConfirmed }) => {
                            if (isConfirmed) {
                                getpost(true);
                            }
                        });
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const setfile = async (val) => {
        setpdf(val.target.files[0]);
    };
    return (
        <div className='content-wrapper'>
            <div className="my-2 row">
                <div className="col-lg-4">
                    <h2>Contract</h2>
                </div>
                <div className="col-10">
                    <div className="float-right">
                        <div className='d-flex mb-2 align-items-center'>
                            <div>
                                <input type="text" placeholder="Search Company Name" onKeyPress={(event) => {
                                    if (event.nativeEvent.key === 'Enter') {
                                        setfiltername(event.target.value)
                                    }
                                }} className="form-control mr-2 w-auto" />
                            </div>
                            <div className='btn btn-sm btn-primary mr-2' onClick={() => { setContractadd(true); setContract(0); }}>
                                <div><i className="fa fa-plus mr-2" /> Add Contract</div>
                            </div>
                            <span>
                                <ButtonGroup>
                                    <Btn size="sm" color={isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                    </Btn>
                                    <Btn size="sm" color={!isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg>
                                    </Btn>
                                </ButtonGroup>
                            </span>
                        </div>
                    </div>
                    <span>
                        <ButtonGroup>
                            <Btn size="sm" color={isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                            </Btn>
                            <Btn size="sm" color={!isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg>
                            </Btn>
                        </ButtonGroup>
                    </span>
                </div>
            </div>
            <div className='bookmark-tabcontent'>
                <div className={"details-bookmark text-center " + (isGridView ? "list-bookmark" : '')} id="list-bookmark1">
                    <div className="row" id="bookmarkData">
                        {data.length == 0 ? <div className="mt-5">
                            No Record Found
                        </div> : data.map(item => (
                            <div className="col-xl-3 col-md-4 xl-50">
                                <div className="card card-with-border bookmark-card o-hidden">
                                    <div className="details-website">
                                        <div className="desciption-data">
                                            <div className="title-bookmark">
                                                <div className='row mb-3'>
                                                    <div className='col-md-9'>
                                                        <h6><i className="fa fa-building mr-2" />{item.Customer?.Company}</h6>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="d-flex justify-content-end">
                                                            <span className='ml-auto'>{item.is_active == false ? "" : <><i className='btn btn-sm btn-success fa fa-pencil mr-1' onClick={() => { get(item._id) }} /><i className='btn btn-sm btn-danger fa fa-trash' onClick={() => { deleteSubscription(item._id); }} /></>}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Name </h6>
                                                    <h6 className="col-8 f-13 text-capitalize text-dark">{item.Customer?.FirstName} {item.Customer?.LastName} </h6>
                                                </div>
                                                <div className="row mb-1">
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Contract No </h6>
                                                    <h6 className="col-8 font-weight-normal f-13 text-secondary">{item.ContractNo}</h6>
                                                </div>
                                                <div className="row mb-1">
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Due date</h6>
                                                    <h6 className="col-8 font-weight-normal f-13 text-secondary">{moment(item.ExpiryDate).format('DD-MMM-YYYY')}</h6>
                                                </div>
                                                <div className="row mb-1">
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Customer Phone</h6>
                                                    <h6 className="col-8 font-weight-normal f-13 text-secondary">{item.Customer?.Mobile}</h6>
                                                </div>
                                                <div className="row mb-1">
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Customer Email</h6>
                                                    <h6 className="col-8 font-weight-normal f-13 text-secondary">{item.Customer?.Email}</h6>
                                                </div>
                                                <div className="row mb-1">
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Renewal Charges</h6>
                                                    <h6 className="col-8 font-weight-normal f-13 text-secondary">{item.RenewalCharges}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                    {data.length == 0 ? "" :
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={perPage}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            linkclassName={"page-link"}
                            onChange={NexPage}
                            itemClass={"page-item"}
                            linkClass={"page-link"}
                        />
                    }
                </div>
            </div>
            <CModal alignment="center" size="lg" visible={Contractadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{Contract == 0 ? "Add Contract" : "Edit Contract"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setContractadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className='mt-2 col-6'>
                                                <label>Contract No.</label>
                                                <input type='text' defaultValue={values.contractNo} name="contractNo" placeholder='Enter Contract No' className={!!touched.contractNo && !!errors.contractNo ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-6'>
                                                <label>Customer</label>
                                                <div className='input-group'>
                                                    <Select
                                                        name="customer"
                                                        value={Customerlist.map((x) => ({
                                                            value: x._id,
                                                            label: `${x.Company} (${x.FirstName} ${x.LastName})`,
                                                        })).find((option) => option.value === values.customer) || null}
                                                        onChange={(e) => {
                                                            setFieldValue("customer", e.value);
                                                        }}
                                                        options={Customerlist.map((x) => ({
                                                            value: x._id,
                                                            label: `${x.Company} (${x.FirstName} ${x.LastName})`,
                                                        }))}
                                                        className={touched.customer && errors.customer ? "is-invalid w-75" : "w-75"}
                                                    />
                                                    <span className="input-group-text">
                                                        {values.customer ? (
                                                            <i className='fa fa-pencil' onClick={() => { setcustomerval(values.customer); setcustomeradd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setcustomerval(0); setcustomeradd(true) }} />
                                                        )}
                                                        {customeradd && (
                                                            <CustomerModal
                                                                setcustomeradd={setcustomeradd}
                                                                customeradd={customeradd}
                                                                getcustomerpost={getcustomerpost}
                                                                showTost={showTost}
                                                                Manual={Manual}
                                                                setcustomerval={setcustomerval}
                                                                customerval={customerval}
                                                            />)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Start Date</label>
                                                <input type="date" placeholder="Start Date" defaultValue={values.startDate} name='startDate' className={!!touched.startDate && !!errors.startDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Expiry Date</label>
                                                <input type="date" placeholder="Expiry Date" min={values.startDate} defaultValue={values.expiryDate} name='expiryDate' className={!!touched.expiryDate && !!errors.expiryDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-4'>
                                                <label>Type</label>
                                                <div className='input-group'>
                                                    <select defaultValue={values.type} className={!!touched.type && !!errors.type ? "form-select is-invalid" : "form-select"} name='type'>
                                                        <option value=''>Select type</option>
                                                        {type
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.Name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.type ? (
                                                            <i className='fa fa-pencil' onClick={() => { settypeval(values.type); settypeadd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { settypeval(0); settypeadd(true) }} />
                                                        )}
                                                        {typeadd && (
                                                            <TypeModal
                                                                settypeadd={settypeadd}
                                                                typeadd={typeadd}
                                                                getType={getType}
                                                                showTost={showTost}
                                                                settypeval={settypeval}
                                                                typeval={typeval}
                                                            />)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='mt-2 col-8'>
                                                <label>Product/Service</label>
                                                <input type='text' defaultValue={values.item} name="item" placeholder='Enter Product / Service' className={!!touched.item && !!errors.item ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <label>Description</label>
                                                <textarea type='text' rows="3" defaultValue={values.description} name="description" placeholder='Enter Description' className={!!touched.description && !!errors.description ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-inr mr-2" />Contract Charges</label>
                                                <input type='text' defaultValue={values.contractCharges} name="contractCharges" placeholder='Enter Contract Charges' className={!!touched.contractCharges && !!errors.contractCharges ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-recycle mr-2" />Renewal Charges</label>
                                                <input type='text' defaultValue={values.renewalCharges} name="renewalCharges" placeholder='Enter Renewal Charges' className={!!touched.renewalCharges && !!errors.renewalCharges ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-12 mt-4">
                                                <span className='btn btn-sm btn-info mr-2' onClick={() => { inputRef.current.click(); }}>
                                                    <i className="fa fa-file mr-2" /> <span>Attach File</span>
                                                </span>
                                                <input
                                                    style={{ display: 'none' }}
                                                    ref={inputRef}
                                                    type="file"
                                                    accept=".pdf"
                                                    onChange={setfile}
                                                />
                                                <label className='mt-2'>{pdf !== "" ? pdf.name : Contract !== 0 ? file : pdf.name}</label>
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div>
    );
}

export default Subscription;
import React, { useEffect, useState } from 'react';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import Swal from "sweetalert2";
import { CModal } from '@coreui/react';
import { immediateToast } from "izitoast-react";
const Taskconfiguration = ({ configuration, setconfiguration, Status, getpost, getStatus, groupName }) => {
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const defaultvalue = {
        _id: "",
        role: "",
        assign: "",
        color: "",
        priority: ""
    };
    const [Role, setRole] = useState([]);
    const [User, setUser] = useState([]);
    useEffect(() => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setUser(res)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getRole`, { active: true })
            .then((res) => {
                setRole(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    useEffect(() => {
        if (Status && Status.length > 0) {
            const initialValues = {
                status: Status.map((x) => ({
                    _id: x._id || "",
                    role: x.Role || "",
                    assign: x.Assign || "",
                    color: x.Color || "",
                    priority: x.Priority || ""
                }))
            };
            setinitValue(initialValues);
        }
    }, [Status]);
    const [initValue, setinitValue] = useState({
        status: [defaultvalue]
    });
    const handleSubmitTaskConfiguration = async (val) => {
        await Post(`master/editConfigurationStatus`, val)
            .then((res) => {
                if (res.success == true) {
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                            getStatus();
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    return (
        <CModal alignment="center" size="lg" visible={configuration}>
            <div className="modal-header">
                <h4 className="modal-title">{groupName} Status Configuration</h4>
                <button type="button" className="close" onClick={() => setconfiguration(false) }>&times;</button>
            </div>
            <div className="modal-body">
                <div className='row'>
                    <div className='col-12'>

                        <Formik
                            onSubmit={handleSubmitTaskConfiguration}
                            initialValues={initValue}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}>
                                        {Status.map((x, i) => (
                                            <div className="row align-items-center m-0">
                                                <div className='mt-2 col-md-3 col-lg-2'>
                                                    <label>Color</label>
                                                    <input type='color' name={`status[${i}].color`} onChange={handleChange} defaultValue={x.Color} placeholder='Enter Status Name' className={!!touched.color && !!errors.color ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className='mt-2 col-md-3 col-lg-2'>
                                                    <span>{x.Name}</span>
                                                </div>
                                                <div className='mt-2 col-md-4 col-lg-2'>
                                                    <label>priority</label>
                                                    <input type='text' name={`status[${i}].priority`}  defaultValue={x.Priority} placeholder='Enter priority' className={!!touched.priority && !!errors.priority ? "form-control is-invalid" : "form-control"} />
                                                </div>                                                
                                                <div className='mt-2 col-md-4 col-lg-3'>
                                                    <label>Assign</label>
                                                    <select name={`status[${i}].assign`} defaultValue={x.Assign}
                                                        className={!!touched.assign && !!errors.assign ? "form-select is-invalid" : "form-select"}>
                                                        <option value="">Select Assign</option>
                                                        {User.map((x, i) => (
                                                            <option key={i} value={x._id}>{x.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='mt-2 col-md-4 col-lg-3'>
                                                    <label>Role</label>
                                                    <select name={`status[${i}].role`} defaultValue={x.Role} className={!!touched.role && !!errors.role ? "form-select is-invalid" : "form-select"}>
                                                        <option value="">Select Role</option>
                                                        {Role
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.Name}</option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="row align-items-center m-0 mt-2">
                                            <div className='col-12 my-2 text-center'>
                                                <button type="submit" className='btn btn-sm font-weight-bold btn-success' ><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>

                    </div>
                </div>
            </div>
        </CModal>
    );
}
export default Taskconfiguration;
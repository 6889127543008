
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { Post, Get, FileUpload } from '../../Layout/ajax';
// import { CModal } from '@coreui/react';
import { immediateToast } from "izitoast-react";
import * as Yup from "yup";
import { CModal, CImage } from '@coreui/react';
import { Link, useNavigate } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";
import Swal from "sweetalert2";
import moment from "moment";

const validation = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().required(),
    DateTime: Yup.string().required(),
});
const Taskboard = () => {

    let navigate = useNavigate();
    const [createpostmodal, setcreatepostmodal] = useState(false);
    const [data, setdata] = useState([])
    const [postbyid, setpostbyid] = useState({});
    const [post, setpost] = useState([]);
    const [newsfeed, setnewsfeed] = useState(false);
    const [newsfeedid, setnewsfeedid] = useState(0);
    const [task, settask] = useState([]);
    const [selectedFile, setFile] = useState(null);

    const [preview, setPreview] = useState(null);
    const [initValue, setinitValue] = useState({
        title: "",
        image: "",
        description: "",
        DateTime: "",
        // url: "",
    });
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const handleChange1 = (image) => {
        setFile(image);
    };

    useEffect(() => {
        getpost();
        gettaskpost();
    }, []);

    const getpost = async () => {
        await Post(`dashboard/getAllNews`, { active: true })
            .then((res) => {
                setpost(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        await Get(`task/gettaskboardCount`)
            .then((res) => {
                setdata(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }

    const getpostbyid = async (id, show) => {
        await Get(`dashboard/getNewsById/${id}`)
            .then((res) => {
                if (show == "show") {
                    setpostbyid(res.data[0]);
                    setnewsfeed(true);
                }
                else {
                    setinitValue({
                        title: res.data[0].title,
                        // image: res.data[0],
                        description: res.data[0].description,
                        DateTime: moment(res.data[0].DateTime).format("YYYY-MM-DDTHH:mm"),
                        // url: res.data[0].url,
                    });
                    setPreview(process.env.REACT_APP_APIURL + 'static/' + res.data[0].image);
                    setnewsfeedid(id);
                    setcreatepostmodal(true);
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const clear = () => {
        setPreview();
        setinitValue({
            title: "",
            description: "",
            // url: "",
            image: "",
            DateTime: "",
        });
    }
    const gettaskpost = async (val) => {
        await Post(`task/getAll`, { active: true, user: JSON.parse(window.localStorage.getItem('Id')), status: '' })
            .then((res) => {
                settask(res.data.Inbox);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const remove = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Post(
                    `dashboard/deleteNewsFeed/${val}`, { active: false }
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(true);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const handleSubmit = async (val) => {
        debugger
        let formdata = new FormData();
        formdata.append("image", selectedFile);
        formdata.append("title", val.title);
        formdata.append("description", val.description);
        formdata.append("DateTime", val.DateTime);
        // formdata.append("url", val.url);
        if (newsfeedid == 0) {

            if (selectedFile == null) {
                showTost("Please Choose Image File.", "warning");
                return;
            }
            else {
                await FileUpload(`dashboard/addNewsFeed`, formdata)
                    .then((res) => {
                        if (res.success == true) {
                            clear();
                            setcreatepostmodal(false);
                            Swal.fire({
                                title: "Your work has been saved",
                                icon: "success",
                                confirmButtonText: "OK",
                                confirmButtonColor: "#FD711A",
                            }).then(async ({ isConfirmed }) => {
                                if (isConfirmed) {
                                    getpost();
                                }
                            });
                        }
                        else {
                            showTost(res.message, "warning");
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        }
        else {
            formdata.append("id", newsfeedid);
            await FileUpload(`dashboard/editNewsFeed`, formdata)
                .then((res) => {
                    if (res.success == true) {
                        clear();
                        setcreatepostmodal(false);
                        Swal.fire({
                            title: "Your work has been saved",
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: "#FD711A",
                        }).then(async ({ isConfirmed }) => {
                            if (isConfirmed) {
                                getpost();
                                setFile(null);
                                setPreview(null);
                            }
                        });
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-8 box-col-8">
                    <div className="col-xl-12 xl-100 col-md-12 box-col-12">
                        <div className="col-xl-12 xl-100 box-col-12">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="mt-4 mb-4">Taskboard</h2>
                                </div>
                                <div className="col-12">
                                    <div className="project-overview">
                                        <div className="row">
                                            {data
                                                .map((x, i) => (
                                                    <div className="col-xl-2 col-sm-4 col-6" onClick={() => navigate(`/Tasks`, { state: x._id })}>
                                                        <Link>
                                                            <h2 className="f-w-600 counter font-primary">{x.count}</h2>
                                                            <p className="mb-0">{x.Name}</p>
                                                        </Link>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 box-col-12">
                            <div className="table-responsive project-tables">
                                <table className="table-bordernone tableThemeCrl">
                                    <thead>
                                        <tr>
                                            <th>Task</th>
                                            <th>Status</th>
                                            <th>Deadline</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {task
                                        .filter((x) => x.Status?.Name !== "Completed")
                                        .map((x, i) => (
                                            <tr>
                                                <td>{x.Name}</td>
                                                <td><div className="badge badge-light-info">{x.Status?.Name}</div></td>
                                                <td><span>
                                                    {
                                                       x.EndDate === null ? "-" : moment(x.EndDate).format('LL')
                                                    }
                                                    {/* {moment(x.EndDate).format('LL')} */}
                                                    </span></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 box-col-4">
                    <div className="col-xl-12 xl-100 col-md-12 box-col-12">
                        <div className='card p-3'>
                            <div className='row'>
                                <div className="col ml-3">
                                    <h4>Newsfeed</h4>
                                </div>
                                <div className="col mr-3">
                                    <span className='btn btn-warning btn-sm float-right' onClick={() => { clear(); setnewsfeedid(0); setcreatepostmodal(true) }}>
                                        <i className="fa fa-plus mr-2" /> Create Post
                                    </span>
                                </div>
                            </div>
                            <div style={{ overflow: 'auto' }}>
                                <hr />
                                {post.slice(0, 5)
                                    .map((x, i) => (
                                        <>
                                            <div className="col-12">
                                                <div className='row'>
                                                    <div className='col-9' onClick={() => getpostbyid(x._id, "show")}>
                                                        <Link> <h5 className='text-dark'>{x.title}</h5></Link>
                                                    </div>
                                                    <div className='col-3'>
                                                        <img src={`${process.env.REACT_APP_APIURL}static/${x.image}`} style={{ width: 60 }} /></div>
                                                </div>
                                                {JSON.parse(window.localStorage.getItem("Id")) == x.addedBy?._id || JSON.parse(window.localStorage.getItem("RoleName")) == "SuperAdmin" || JSON.parse(window.localStorage.getItem('RoleName')) == "Admin" ? <div className='mb-2'>
                                                    <i className='btn btn-sm btn-success mr-2 fa fa-pencil' onClick={() => { getpostbyid(x._id); }} />
                                                    <i className='btn btn-sm btn-danger fa fa-trash' onClick={() => { remove(x._id); }} />
                                                </div> : ""}
                                                <span className='text-justify small truncate'>{x.description}</span>
                                                <span className="mb-3 text-justify">{moment(x.DateTime).format('lll')}</span>
                                            </div>
                                            <hr />
                                        </>
                                    ))}
                                {/* <div className="col-12 text-center text-danger">
                                <h5>See All</h5>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CModal alignment="center" visible={createpostmodal}>
                <div className="modal-header">
                    <h4 className="modal-title">{newsfeedid == 0 ? "Create Post":"Edit Post"}</h4>
                    <button type="button" className='close' onClick={() => { setcreatepostmodal(false); setFile(null); setPreview(null); }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="row">
                                        <div className='col-12'>
                                            <label>Title</label>
                                            <input type='text' name="title" defaultValue={values.title} className={!!touched.title && !!errors.title ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-12'>
                                            <label>Details</label>
                                            <textarea type="text" rows="3" name="description" defaultValue={values.description} className={!!touched.description && !!errors.description ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-12'>
                                            <label>Date & Time</label>
                                            <input type="datetime-local" placeholder="Date" defaultValue={values.DateTime} name='DateTime' className={!!touched.DateTime && !!errors.DateTime ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-8'>
                                            <label>Upload Photo</label>
                                            <FileUploader handleChange={handleChange1} multiple={false} name="image" types={["JPEG", "JPG", "PNG"]} />
                                        </div>
                                        <div className='col-4 mt-2'>
                                            {preview && <CImage rounded thumbnail src={preview} width={100} height={100} />}
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <button type="submit" className='btn btn-sm btn-primary'><i className='fa fa-paper-plane' /> Post</button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
            <CModal alignment="center" size='lg' visible={newsfeed}>
                <div className="modal-header">
                    <button type="button" className="close" onClick={() => setnewsfeed(false)}>&times;</button>
                </div>
                <div className="modal-body p-4">
                    <div className='text-center'>
                        <h5 className='mb-3'>{postbyid.title}</h5>
                    </div>
                    <div className='text-center mb-3'>
                        <img src={`${process.env.REACT_APP_APIURL}static/${postbyid.image}`} style={{ width: "50%" }} />
                    </div>
                    <div className="mb-3 text-justify">{postbyid.description}</div>
                    <div className="mb-3 text-justify">{moment(postbyid.DateTime).format('lll')}</div>
                </div>
            </CModal>
        </div >


    );
}


export default Taskboard;
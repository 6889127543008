import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import axios from "axios";
import { Post, Get } from '../../Layout/ajax';
import { Form, Button } from "react-bootstrap";
import { immediateToast } from 'izitoast-react';
import * as Yup from "yup";

const addDataSchema = Yup.object().shape({
  role: Yup.string().required(),
});

const RolesResponsibility = () => {
  const [initValue, setValue] = useState({
    role: "",
    moduleId: "",
    read: false,
    write: false,
    delete: false,
  });

  const [role, setrole] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    getPost();
    Post(`master/getRole`, { active: true })
      .then((res) => {
        setrole(res.data);
      })
      .catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
      });
  }, []);

  const [showModules, setShowModules] = useState(false);
  const [showsubmit, setShowSubmit] = useState(false);

  const showTost = (msg, theme) => {
    theme = theme || "info";
    immediateToast(theme, {
      message: msg,
      timeout: 3000
    });
  }

  const handleSubmit = async (val) => {
    let x = { ...val }
    if (x.role !== "") {
      setShowModules(true);
    }
  };

  const changeCheckBox = async (event) => {
    let modid = event.target.getAttribute("data-moduleId");
    let role = event.target.getAttribute("data-role");
    let group = event.target.getAttribute("data-group");
    let checkBoxes = document.querySelectorAll(`[data-moduleId='${modid}']`);
    let saveobj = { "role": role, "moduleId": modid, "read": false, "write": false, "delete": false, "groupname": group };
    checkBoxes.forEach(ele => {
      if (ele.getAttribute("name") == "read") {
        saveobj.read = ele.checked;
      }
      else if (ele.getAttribute("name") == "write") {
        if (ele.checked) {
          saveobj.read = true;
        }
        saveobj.write = ele.checked;
      }
      else if (ele.getAttribute("name") == "delete") {
        if (ele.checked) {
          saveobj.read = true;
        }
        saveobj.delete = ele.checked;
      }
    })
    await
      Post(`ModuleRight/AddModuleRight`, saveobj)
        .then((res) => {
          showTost("Saved successfully.");
        })
        .catch((err) => {
          showTost("Something went wrong please try after sometime");
        });
  }

  const getPost = async () => {
    await Get(`master/getModulegroup`)
      .then((res) => {
        if (res) {
          setData(res);
        }
      })
      .catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "warning");
      });
  }

  const GetModuleRight = async (role) => {
    if (!role) {
      setShowSubmit(false);
      return;
    }
    await Get(`ModuleRight/GetModuleRightByRole/${role}`)
      .then((res) => {
        if (res.data) {
          let rightmod = res.data;
          let modulesrights = { ...data };
          for (const menu in modulesrights) {
            modulesrights[menu].obj.forEach(menuMod => {
              let findmod = rightmod.find(f => f.modulename == menuMod.Name);
              if (findmod) {
                menuMod.read = findmod.read;
                menuMod.write = findmod.write;
                menuMod.delete = findmod.delete;
              }
            })
          }
        }

        setShowSubmit(true);
      })
      .catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "warning");
      });

  }

  return <>
    <Formik
      enableReinitialize={true}
      onSubmit={handleSubmit}
      initialValues={initValue}
      validationSchema={addDataSchema}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
      }) => {
        return (
          <div className='content-wrapper'>
            <div className="row ">
              <div className="mb-2 mt-2 row col-12">
                <div className="col-9">
                  <h2>Roles Responsibility</h2>
                </div>
              </div>
              <div className="col-12 mt-3">
                <Form
                  onSubmit={handleSubmit}
                  onChange={handleChange}>
                  <div className="card">
                    <div className="card-header text-uppercase">
                      <h6>Role :</h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <Form.Select
                              as="select"
                              name="role"
                              onChange={(event) => {
                                setShowModules(false);
                                GetModuleRight(event.target.value);
                              }}
                              value={values.role}
                              isInvalid={!!touched.role && !!errors.role}
                            >
                              <option value="" >Select Backend User</option>
                              {role
                                .map((x, i) => (
                                  <option key={i} value={x._id}>{x.Name}</option>
                                ))}
                            </Form.Select>
                          </div>
                          {showsubmit && <button type="submit" className="btn btn-sm btn-danger">Submit</button>}
                        </div>
                      </div>
                    </div>
                  </div>
                  {showModules &&
                    <div className="card" id="hidediv">
                      <div className="card-header text-uppercase">SELECT RIGHTS :</div>
                      <div className="card-body">
                        <div className="row">
                          {data.map(item => <>
                            <div className="col-12">
                              <div className="h4">{item._id}</div>
                            </div>
                            {item.obj.map(subitem =>
                              <>
                                <div className="col-6 col-lg-6 col-xl-6">
                                  <ul>
                                    <li><label>{subitem.Name}</label></li>
                                  </ul>
                                </div>
                                <div className="col-6 col-lg-6 col-xl-6">
                                  <div className="row">
                                    <div className="col-3 col-lg-3 col-xl-3"><label><input type="checkbox" name="read" defaultChecked={subitem.read} data-group={item._id} data-moduleId={subitem._id} data-role={values.role} onClick={(event) => changeCheckBox(event)} className="subOption" /> Read</label></div>
                                    <div className="col-3 col-lg-3 col-xl-3"><label><input type="checkbox" name="write" defaultChecked={subitem.write} data-group={item._id} data-moduleId={subitem._id} data-role={values.role} onClick={(event) => changeCheckBox(event)} className="subOption" /> Write</label></div>
                                    <div className="col-3 col-lg-3 col-xl-3"><label><input type="checkbox" name="delete" defaultChecked={subitem.delete} data-group={item._id} data-moduleId={subitem._id} data-role={values.role} onClick={(event) => changeCheckBox(event)} className="subOption" /> Delete</label></div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                          )}
                        </div>
                      </div>
                    </div>
                  }
                </Form>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  </>;
}
export default RolesResponsibility;
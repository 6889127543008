import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import Swal from "sweetalert2";
import { immediateToast } from "izitoast-react";
import { Link } from 'react-router-dom';

const validation = Yup.object().shape({
    company: Yup.string().required(),
    // gstno: Yup.string().required(),
    // title: Yup.string().required(),
    firstname: Yup.string().required(),
    // lastname: Yup.string().required(),
    // designation: Yup.string().required(),
    email: Yup.string().email(),
    mobile: Yup.string().nullable().max(10).min(10).matches(/^[+]?[0-9]+$/),
    // address: Yup.string().required(),
    // city: Yup.string().required(),
    // state: Yup.string().required(),
    // notes: Yup.string().required(),
});
const Customer = () => {
    const [data, setData] = useState([]);
    const [customeradd, setcustomeradd] = useState(false);
    const [customer, setcustomer] = useState(0);
    const [Country, setCountry] = useState([]);
    const [City, setCity] = useState([]);
    const [State, setState] = useState([]);
    const [Manual, setManual] = useState(false);
    const [lastCustomerCode, setLastCustomerCode] = useState('');
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    useEffect(() => {
        getpost(true);
        Post(`master/getCountry`, { active: true })
            .then((res) => {
                setCountry(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getState`, { active: true })
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getCity`, { active: true })
            .then((res) => {
                setCity(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Get(`master/getApplicationSetting`)
            .then((res) => {
                setManual(res.data.Customer)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const getpost = async (val) => {
        await Post(`customer/getAllCustomer`, { active: val })
            .then((res) => {
                setData(res.data);
                setLastCustomerCode(res.lastCustomerCode?.CustomerCode)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const [initValue, setinitValue] = useState({
        company: "",
        gstno: "",
        title: "",
        firstname: "",
        lastname: "",
        designation: "",
        CustomerCode: "",
        mobile: "",
        email: "",
        address: "",
        city: "",
        state: "",
        country: "",
        notes: "",
    });
    const clear = () => {
        setinitValue({
            company: "",
            gstno: "",
            title: "",
            firstname: "",
            lastname: "",
            designation: "",
            CustomerCode: "",
            mobile: "",
            email: "",
            address: "",
            city: "",
            state: "",
            country: "",
            notes: "",
        });
    }
    const get = async (val) => {
        if (val != 0) {
            Get(`customer/getCustomerById/${val}`)
                .then((res) => {
                    setinitValue({
                        company: res.data[0].Company,
                        gstno: res.data[0].GSTNo,
                        title: res.data[0].Title,
                        firstname: res.data[0].FirstName,
                        lastname: res.data[0].LastName,
                        designation: res.data[0].Designation,
                        CustomerCode: res.data[0].CustomerCode,
                        mobile: res.data[0].Mobile,
                        email: res.data[0].Email,
                        address: res.data[0].Address,
                        city: res.data[0].City?._id,
                        state: res.data[0].State?._id,
                        country: res.data[0].Country?._id,
                        notes: res.data[0].Notes,
                    });
                    setcustomeradd(true);
                    setcustomer(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const handleSubmit = async (val) => {
        await Post(`customer/addCustomer`, val)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.msg, "warning");
                }
                else {
                    clear();
                    setcustomeradd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleEdit = async (val) => {
        val.id = customer;
        await Post(`customer/editCustomer`, val)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.msg, "warning");
                }
                else {
                    clear();
                    setcustomeradd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const changeStatus = async (val, status) => {
        await Post(`customer/removeCustomer/${val}`, { active: status })
            .then((res) => {
                if (res.success == true) {
                    if (status == true) {
                        getpost(false);
                        showTost("Your data has been successfully activated", "success");
                    }
                    else {
                        getpost(true);
                        showTost("Your data has been successfully deactivated", "success");
                    }
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    // const columns = [
    //     {
    //         name: "Company",
    //         selector: (row) => row.Company,
    //     },
    //     {
    //         name: "Name",
    //         selector: (row) => <>{row.FirstName} {row.LastName}</>,
    //     },
    //     {
    //         name: "Mobile",
    //         selector: (row) => row.Mobile,
    //     },
    //     {
    //         name: "Email",
    //         selector: (row) => row.Email,
    //     },
    //     {
    //         name: "Country",
    //         selector: (row) => row.Country?.Name,
    //     },
    //     {
    //         name: "State",
    //         selector: (row) => row.State?.Name,
    //     },
    //     {
    //         name: "City",
    //         selector: (row) => row.City?.Name,
    //     },
    //     {
    //         name: "Status",
    //         selector: (row) =>
    //             <input type='checkbox'
    //                 value={row.is_active} checked={row.is_active == true ? "checked" : ""}
    //                 onClick={() => { row.is_active == true ? changeStatus(row._id, false) : changeStatus(row._id, true) }} />,
    //     },
    //     {
    //         name: "",
    //         selector: (row) => <Link className='text-success' to={`/Customeraddress/${row._id}`}>View Addess</Link>,
    //     },
    //     {
    //         name: "",
    //         selector: (row) => row.is_active == true ? <i className='btn btn-sm btn-success fa fa-pencil' onClick={() => { get(row._id); }} /> : "",
    //     },
    // ];

    return (
        <div className='content-wrapper'>

            <div className="mb-2 mt-2 row ">
                <div className="col-10">
                    <h2>Customer</h2>
                </div>
                <div className="col-2 mt-2">
                    <span className='btn btn-sm btn-warning float-right' onClick={() => { setcustomeradd(true); setcustomer(0); }}>
                        <i className="fa fa-plus mr-2" />
                        Add Customer
                    </span>
                </div>
                <div className="col-12">
                    <div className="custom-control custom-switch float-right">
                        <input type="checkbox" className="custom-control-input" defaultChecked id="customSwitch1" data-check_true={true} data-check_false={false} onChange={(event) => {
                            let ele = event.target;
                            let selectedId = ele.checked ? event.target.getAttribute("data-check_true") : event.target.getAttribute("data-check_false");
                            getpost(selectedId)
                        }} />
                        <label className="custom-control-label" for="customSwitch1"> Active/Inactive </label>
                    </div>
                </div>
            </div>
            {/* <div className="row ">
                <div className="col-12 mt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </div>
            </div> */}
            <CModal alignment="center" visible={customeradd} size='lg'>
                <div className="modal-header">
                    <h4 className="modal-title">{customer == 0 ? "Add Customer" : "Update Customer"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setcustomeradd(false); }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={customer == 0 ? handleSubmit : handleEdit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='row'>
                                        {Manual == true ?
                                            <><div className="col-6 mb-2">
                                                <label>Customer Code</label>
                                                <input type="text" placeholder="Customer Code" value={values.CustomerCode} defaultValue={values.CustomerCode} name='CustomerCode' className={!!touched.CustomerCode && !!errors.CustomerCode ? "form-control is-invalid" : "form-control"} />
                                                {customer == 0 ? <div>
                                                    <span>Last Customer Code:</span>
                                                    <span className='fw-bold ms-1'>
                                                        {lastCustomerCode}
                                                    </span>
                                                </div> : ""}
                                            </div> <div className='col-6 mb-2'></div></> : ""}
                                        <div className='col-6 mb-2'>
                                            <label>Company Name</label>
                                            <input type='text' defaultValue={values.company} name="company" placeholder='Enter Company' className={!!touched.company && !!errors.company ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>GST No.</label>
                                            <input type="text" defaultValue={values.gstno} name='gstno' placeholder="GST No." className={!!touched.gstno && !!errors.gstno ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-3 mb-2'>
                                            <label>Title</label>
                                            <select defaultValue={values.title} className={!!touched.title && !!errors.title ? "form-select is-invalid" : "form-select"} name='title'>
                                                <option value="">Select</option>
                                                <option value="Mr.">Mr.</option>
                                                <option value="Mrs.">Mrs.</option>
                                                <option value="Ms.">Ms.</option>
                                            </select>
                                        </div>
                                        <div className='col-4 mb-2'>
                                            <label>First Name</label>
                                            <input type='text' name="firstname" defaultValue={values.firstname} placeholder='Enter Firstname' className={!!touched.firstname && !!errors.firstname ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-4 mb-2'>
                                            <label>Last Name</label>
                                            <input type='text' name="lastname" defaultValue={values.lastname} placeholder='Enter Lastname' className={!!touched.lastname && !!errors.lastname ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Designation</label>
                                            <input type='text' name="designation" defaultValue={values.designation} placeholder='Enter Designation' className={!!touched.designation && !!errors.designation ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Mobile Number</label>
                                            <input type='text' name="mobile" defaultValue={values.mobile} placeholder='Enter Mobile' className={!!touched.mobile && !!errors.mobile ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Email</label>
                                            <input type='email' name="email" defaultValue={values.email} placeholder='Enter Email' className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Address</label>
                                            <input type='text' name="address" defaultValue={values.address} placeholder='Enter Address' className={!!touched.address && !!errors.address ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className="col-4 mb-2">
                                            <label>Country</label>
                                            <select defaultValue={values.country} className={!!touched.country && !!errors.country ? "form-select is-invalid" : "form-select"} name='country'>
                                                <option>Select Country</option>
                                                {
                                                    Country.map((x, i) => (
                                                        <option key={i} value={x._id}>{x.Name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-4 mb-2">
                                            <label>State</label>
                                            <select defaultValue={values.state} className={!!touched.state && !!errors.state ? "form-select is-invalid" : "form-select"} name='state'>
                                                <option>Select State</option>
                                                {State.filter((x) => x.Country?._id == values.country).map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-4 mb-2">
                                            <label>City</label>
                                            <select name='city' defaultValue={values.city} className={!!touched.city && !!errors.city ? "form-control is-invalid" : "form-control"}>
                                                <option>Select City</option>
                                                {
                                                    City.filter((x) => x.State?._id == values.state).map((x, i) => (
                                                        <option key={i} value={x._id}>{x.Name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className='col-12 mb-2'>
                                            <label>Notes</label>
                                            <textarea type='text' rows="3" name="notes" defaultValue={values.notes} placeholder='Enter Notes' className={!!touched.notes && !!errors.notes ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-12'>
                                            <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
            {
                data.length == 0 ? <div className="mt-5 text-center">
                    No Record Found
                </div> : data.map(x => (
                    <div className='boxShadow my-3 p-3'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='d-flex align-items-center mb-1'>
                                    <p className='mt-0'><i class="fa fa-building-o mr-2 icnClr"></i></p>
                                    <p className='text-dark f-14 font-weight-bold mt-0 text-capitalize'>{x.Company}</p>
                                </div>
                            </div>
                            <div className='col-lg-6 d-flex flex-wrap align-items-center justify-content-end'>
                                {x.is_active ? (<i className='btn btn-sm btn-success fa fa-pencil-square-o pt-2'
                                    onClick={() => get(x._id)} ></i>) : ""}
                                <i
                                    className={`btn btn-sm pt-2 ${x.is_active ? 'btn-danger' : 'btn-secondary'} fa fa-trash ml-2`}
                                    onClick={() => changeStatus(x._id, !x.is_active)}
                                />
                                <button className='btn btn-sm bg-white btnBrdr ml-2 f-13 text-capitalize'
                                    onClick={() => window.location.href = `/Customeraddress/${x._id}`} >View Address</button>
                            </div>
                        </div>
                        <div className='d-flex mb-1'>
                            <p className='mt-0'><i class="fa fa-user-o mr-2 icnClr"></i></p> <p className='text-dark f-14 font-weight-bold text-capitalize mt-0'>{x.FirstName} {x.LastName}</p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='d-flex align-items-center flex-wrap'>
                                    <div className='d-flex'><p className='mt-0'><i class="fa fa-phone mt-2 mr-2 icnClr"></i></p> <p className='text-dark f-14 mt-0'>{x.Mobile}</p></div>
                                    <div className='dot mx-2'></div>
                                    <div className='d-flex ml-1'>
                                        <p className='mt-0'><i class="fa fa-envelope-o mr-2 mt-2 icnClr"></i></p> <p className='text-dark f-14 mt-0'>{x.Email}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 justify-content-end d-flex'>
                                <div className='d-flex align-items-center flex-wrap'>
                                    <p className='mt-0'><i className='fa fa-map-marker mr-2 f-14 icnClr'></i></p>
                                    <div className='d-flex align-items-center'>
                                        <p className='icnClr f-14 mt-0'>Country</p>
                                        <p className='text-dark f-14 mt-0 font-weight-normal ml-2'>{x.Country?.Name}</p>
                                    </div>
                                    <div className='dot mx-2'></div>
                                    <div className='d-flex align-items-center'>
                                        <p className='icnClr f-14 mt-0'>State</p>
                                        <p className='text-dark f-14 mt-0 font-weight-normal ml-2'>{x.State?.Name}</p>
                                    </div>
                                    <div className='dot mx-2'></div>
                                    <div className='d-flex align-items-center'>
                                        <p className='icnClr f-14 mt-0'>City</p>
                                        <p className='text-dark f-14 mt-0 font-weight-normal ml-2'>{x.City?.Name}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className='col-sm-6 brdrLeft'>
                                <h6>Shipping Address</h6>
                                {x.ShippingAddress.filter(sa => sa.is_default).map((address, idx) => (
                                    <div key={idx} className='d-flex algn-items-center flex-wrap mt-2'>
                                        <p className='mt-0'><i className='fa fa-map-marker mr-2 icnClr f-14'></i></p>
                                        <p className='mt-0 text-capitalize f-14 tex-dark'>{address.Address}, <br /> {address.City?.Name}, {address.State?.Name}, {address.Country?.Name}</p>
                                    </div>
                                ))}
                            </div>
                            <div className='col-sm-6'>
                                <h6>Billing Address</h6>
                                {x.BillingAddress.filter(ba => ba.is_default).map((address, idx) => (
                                    <div key={idx} className='d-flex algn-items-center flex-wrap mt-2'>
                                        <p className='mt-0'><i className='fa fa-map-marker mr-2 f-14 icnClr'></i></p>
                                        <p className='mt-0 text-capitalize f-14 tex-dark'>{address.Address}, <br /> {address.City?.Name}, {address.State?.Name}, {address.Country?.Name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))
            }
        </div >
    );
}

export default Customer;
import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Post, Get, FileUpload } from '../../../Layout/ajax';
import { immediateToast } from "izitoast-react";
import { useNavigate } from 'react-router-dom';

const ArchivePage = () => {
  const [Data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getPost(true);
  }, []);
  const showTost = (msg, theme) => {
    immediateToast(theme, {
      message: msg,
      timeout: 3000
    });
  }
  const getPost = async (val) => {
    await Post(`task/getAllArchiveTask`, { archive: val })
      .then((res) => {
        if (res.success == true) {
          setData(res.data.AllList);
          console.log(res.data.AllList);
        }
      }).catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
      });
  }
  const archiveTask = async (Id, archivestatus) => {
    await Post(`task/archiveUnarchive`, { id: Id, archive: archivestatus })
      .then((res) => {
        if(res.success == true) {
          getPost(true); 
        } else {
            showTost(res.msg, "warning");
        }
      }).catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
    });
  };
  return (
    <div className="container mt-4">
      <div className='row mb-2 align-items-center'>
        <div className='col-sm-10'>
          <h4> <i class="fa fa-archive" aria-hidden="true"></i> Archived Tasks</h4>
        </div>
        <div className='col-sm-2'>
          <h4 className="btn btn-sm btn-primary mr-2 float-right" onClick={() => navigate('/Tasks')}>  View Task Page</h4>
        </div>
      </div>

      {Data.length === 0 ? (
        <p className="text-center mt-4">No archived tasks found.</p>
      ) : (
        <div className="row">
          {Data.map((task) => (
            <div key={task._id} className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="card">
                <div className="card-body p-2">
                  <div className="row mb-1 align-items-center">
                    <h6 className='col-8 card-title'>{task.Name} </h6>
                    <h6 className='col-4 f-12 text-capitalize text-end'>
                      <button
                        className="btn btn-secondary btn-sm px-1 py-0"
                      onClick={() => archiveTask(task._id, false)}
                      >
                        Unarchive
                      </button>
                    </h6>
                  </div>

                  <div className="row mb-1">
                    <h6 className='col-4 font-weight-normal f-12 text-secondary'>Status </h6>
                    <h6 className='col-8 f-12 text-capitalize' style={{ color: task.Status?.Color }}> {task.Status?.Name}</h6>
                  </div>
                  <div className="row mb-1">
                    <h6 className='col-4 font-weight-normal f-12 text-secondary'>Priority</h6>
                    <h6 className='col-8 f-12 text-capitalize'> {task.Priority}</h6>
                  </div>
                  <div className="row mb-1">
                    <h6 className='col-4 font-weight-normal f-12 text-secondary'>Assign to</h6>
                    <h6 className='col-8 text-dark f-12 text-capitalize'> {task.Assign?.name}</h6>
                  </div>
                  <div className='row mb-1'>
                    <h6 className='col-4 font-weight-normal f-12 text-secondary'>End Date</h6>
                    <h6 className="col-8 font-weight-normal f-12 text-secondary">
                      {moment(task.EndDate).format('lll')}
                    </h6>
                  </div>
                  <div className="row mb-1">
                    <h6 className='col-4 font-weight-normal f-12 text-secondary'>Created By</h6>
                    <h6 className='col-8 font-weight-normal f-12 text-capitalize text-secondary'> {task.addedBy?.name}</h6>
                  </div>
                  {task.Reason ? (
                    <div className="row mb-1 ">
                      <h6 className="col-4 font-weight-normal f-12 text-secondary">Reason</h6>
                      <h6 className="col-8 font-weight-normal f-12 text-capitalize text-secondary">{task.Reason}</h6>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ArchivePage
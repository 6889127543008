import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get, File, FileBlob } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import { immediateToast } from "izitoast-react";
import moment from "moment";
import Select from "react-select";
import Swal from "sweetalert2";
import { Bookmark } from 'react-feather';
import { current } from '@reduxjs/toolkit';
import { ButtonGroup } from 'reactstrap';
import { Btn, Spinner } from '../../AbstractElements';
import styles from './Quotes.module.scss';
import PDFViewer from '../../Components/PDFViewer';
import Pagination from "react-js-pagination";
import CKEditor from 'react-ckeditor-component';
import { CustomerModal, ProductModal, UserModal, UnitModal } from './Modal.js';
const fmt = require('indian-number-format')

const validation = Yup.object().shape({
    customer: Yup.string().required(),
    QuatationName: Yup.string().required(),
    products: Yup.array()
        .of(
            Yup.object().shape({
                quantity: Yup.string().required(),
                price: Yup.string().required(),
                product: Yup.string().required(),
                unit: Yup.string().required(),
                TotalAmount: Yup.string().required(),
                FinalAmount: Yup.string().required(),
            })).required(),
});
const templatevalidation = Yup.object().shape({
    template: Yup.string().required(),
});
const Quotes = () => {
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const $ = window.$;
    const [data, setData] = useState([]);
    const [addquotes, setaddquotes] = useState(false);
    const [print, setprint] = useState(false);
    const [quotes, setquotes] = useState(0);
    const [customer, setcustomer] = useState([]);
    const [customeradd, setcustomeradd] = useState(false);
    const [sales, setsales] = useState([]);
    const [ShippingAddress, setShippingAddress] = useState([]);
    const [BillingAddress, setBillingAddress] = useState([]);
    const [template1, settemplate] = useState([]);
    const [productlist, setproductlist] = useState([]);
    const [order, setorder] = useState(false);
    const [unitlist, setunitlist] = useState([]);
    const [QuatationNamelist, setQuatationNamelist] = useState([]);
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [productadd, setproductadd] = useState(false);
    const [Manual, setManual] = useState(false);
    const [unitadd, setunitadd] = useState(false);
    const [isGridView, setIsGridView] = useState(false);
    const [downloadSpinner, setDownloadSpinner] = useState(false);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [pdfmodal, setPdfModal] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const [filtername, setfiltername] = useState("");
    const [useradd, setuseradd] = useState(false);
    const [lastQuatationCode, setLastQuatationCode] = useState('');
    const [productval, setproductval] = useState(0);
    const [customerval, setcustomerval] = useState(0);
    const [userval, setuserval] = useState(0);
    const [unitval, setunitval] = useState(0);
    const genrateKeys = () => {
        setFormKey(Math.random() * 1000000);
    }
    const defaultvalue = {
        quantity: "",
        price: "",
        CGST: 0,
        SGST: 0,
        product: "",
        note: "",
        unit: "",
        TotalAmount: "",
        FinalAmount: "",
    };

    const [initValue, setinitValue] = useState({
        customer: "",
        shippingAddress: "",
        billingAddress: "",
        AfterTaxPrice: "0",
        CGST: "0",
        SGST: "0",
        discount: "0",
        totalTax: "0",
        BeforeTaxPrice: "0",
        finalPrice: "0",
        Amount: "0",
        RoundOff: "0",
        QuatationName: "",
        Descriptionofwork: "",
        OtherChargeName: "",
        OtherCharge: 0,
        quatattionDate: "",
        QuatationCode: "",
        vaidDate: "",
        sales: "",
        note: "",
        TermsAndCondition: "",
        products: [defaultvalue],
    });

    const NexPage = (NexPage) => {
        setPageNo(NexPage);
    };
    useEffect(() => {
        getpost(false);
    }, [pageNo, perPage, filtername]);
    const AddMore = (values) => {
        let newobject = {
            ...values,
            products: [...values.products, defaultvalue]
        };
        setinitValue(newobject);
    };

    const onDelete = (index, values) => {
        let productarr = [...values.products];
        productarr.splice(index, 1)
        let newobject = {
            ...values,
            products: productarr
        };
        setinitValue(newobject);
    };
    const TotalCalculator = (detvalues) => {
        let values = { ...detvalues };
        values.AfterTaxPrice = 0;
        values.CGST = 0;
        values.SGST = 0;
        values.totalTax = 0;
        values.BeforeTaxPrice = 0;
        values.Amount = 0;
        values.RoundOff = 0;
        values.finalPrice = 0;
        detvalues.products.forEach((product, index) => {
            let price = parseFloat(product.price);
            let quantity = parseFloat(product.quantity);
            let AfterTaxPrice = quantity * price;
            let cgst = (AfterTaxPrice * parseFloat(product.CGST)) / 100 || 0;
            let sgst = (AfterTaxPrice * parseFloat(product.SGST)) / 100 || 0;
            let totalTax = cgst + sgst;
            let finalPrice = AfterTaxPrice + totalTax;

            if (isNaN(price) || isNaN(quantity) || isNaN(cgst) || isNaN(sgst)) {
                return;
            }
            values.products[index].TotalAmount = AfterTaxPrice;

            values.products[index].FinalAmount = finalPrice;
            values.BeforeTaxPrice += AfterTaxPrice;
            values.AfterTaxPrice += AfterTaxPrice + totalTax;
            values.CGST += cgst;
            values.SGST += sgst;
            values.totalTax += totalTax;
        });
        let finalcharge = values.AfterTaxPrice + parseFloat(values.OtherCharge);
        let final = (finalcharge * parseFloat(values.discount)) / 100 || 0;
        values.Amount = (finalcharge - final).toFixed(2);
        values.finalPrice = Math.round(finalcharge - final);
        values.RoundOff = (values.finalPrice - values.Amount).toFixed(2);
        setinitValue(values);
        genrateKeys();
    }

    const clear = () => {
        setinitValue({
            customer: "",
            shippingAddress: "",
            billingAddress: "",
            AfterTaxPrice: "0",
            CGST: "0",
            SGST: "0",
            discount: "0",
            totalTax: "0",
            BeforeTaxPrice: "0",
            finalPrice: "0",
            Amount: "0",
            RoundOff: "0",
            QuatationName: "",
            Descriptionofwork: "",
            OtherChargeName: "",
            OtherCharge: 0,
            quatattionDate: "",
            QuatationCode: "",
            vaidDate: "",
            sales: "",
            note: "",
            TermsAndCondition: "",
            products: [defaultvalue],
        });
    }
    useEffect(() => {
        getpost(false);
        Post(`template/getAll`, { active: true })
            .then((res) => {
                settemplate(res.filter(
                    (x) => x.TemplateFor == "Quotation"
                ))
                var set = res.filter(x => x.TemplateFor == "Quotation" && x.is_default == true).map(x => x._id)
                setinittemplateValue({
                    template: set[0]
                });
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        getcustomerpost();
        getuserpost();
        getproductpost();
        getunitpost();
        getQuatationNamepost();
        Get(`master/getApplicationSetting`)
            .then((res) => {
                setManual(res.data);
                setinitValue(prevValue => ({
                    ...prevValue,
                    TermsAndCondition: res.data.TermsAndCondition
                }));
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const getcustomerpost = async () => {
        Post(`customer/getAllCustomer`, { active: true })
            .then((res) => {
                setcustomer(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getuserpost = async () => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setsales(res)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getunitpost = async (val) => {
        Post(`master/getUnit`, { active: true })
            .then((res) => {
                setunitlist(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getQuatationNamepost = async (val) => {
        Post(`master/getQuatationName`, { active: true })
            .then((res) => {
                setQuatationNamelist(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getproductpost = async (val) => {
        Post(`master/getProduct`, { active: true })
            .then((res) => {
                setproductlist(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getpost = async (val) => {
        let skipa = perPage * (pageNo - 1);
        await Post(`quatation/getAll`, { active: val, skip: skipa, per_page: perPage, filter: filtername })
            .then((res) => {
                setData(res.data.data)
                setTotalCount(res.data.count);
                setLastQuatationCode(res.lastQuationCode?.QuatationCode);
            })
            .catch((err) => {
                setTotalCount(0);
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const [inittemplateValue, setinittemplateValue] = useState({
        template: "",
    });
    const quatationbyid = async (val) => {
        if (val != 0) {
            Get(`quatation/getById/${val}`)
                .then((res) => {
                    getcustomer(res.data[0].Customer?._id)
                    setinitValue({
                        customer: res.data[0].Customer?._id,
                        shippingAddress: res.data[0].ShippingAddress?._id,
                        billingAddress: res.data[0].BillingAddress?._id,
                        AfterTaxPrice: res.data[0].Amount,
                        CGST: res.data[0].CGST,
                        SGST: res.data[0].SGST,
                        discount: res.data[0].Discount,
                        totalTax: res.data[0].TotalTax,
                        finalPrice: res.data[0].FinalPrice,
                        RoundOff: res.data[0].RoundOff,
                        Amount: res.data[0].Amount,
                        BeforeTaxPrice: res.data[0].BeforeTaxPrice,
                        Descriptionofwork: res.data[0].Descriptionofwork,
                        QuatationName: res.data[0].QuatationName,
                        OtherChargeName: res.data[0].OtherChargeName,
                        OtherCharge: res.data[0].OtherCharge,
                        sales: res.data[0].Sales?._id,
                        QuatationCode: res.data[0].QuatationCode,
                        quatattionDate: moment(res.data[0].QuatationDate).format("YYYY-MM-DD"),
                        vaidDate: moment(res.data[0].ValidDate).format("YYYY-MM-DD"),
                        note: res.data[0].Note,
                        products: res.data[0].Products.map((x, i) => ({
                            note: x.Note,
                            CGST: x.CGST,
                            price: x.Price,
                            quantity: x.Quantity,
                            SGST: x.SGST,
                            unit: x.Unit,
                            TotalAmount: x.TotalAmount,
                            FinalAmount: x.FinalAmount,
                            product: x.Product,
                        })),
                        TermsAndCondition: res.data[0].TermsAndCondition
                    });
                    setQuatattionDate(moment(res.data[0].QuatationDate).format("YYYY-MM-DD"));
                    setVaidDate(moment(res.data[0].ValidDate).format("YYYY-MM-DD"));
                    setaddquotes(true)
                    setquotes(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const getcustomer = async (val) => {
        if (val !== "") {
            await Get(`customer/getCustomerById/${val}`)
                .then((res) => {
                    let shipdefault = res.data[0]?.ShippingAddress.find(x => x.is_default == true)
                    let billdefault = res.data[0]?.BillingAddress.find(x => x.is_default == true)
                    setinitValue({
                        ...initValue,
                        shippingAddress: shipdefault?._id,
                        billingAddress: billdefault?._id,
                    })
                    setShippingAddress(res.data[0]?.ShippingAddress)
                    setBillingAddress(res.data[0]?.BillingAddress)
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
        else {
            setShippingAddress([])
            setBillingAddress([])
        }
    }
    const handleSubmit = async (val) => {
        val.quatattionDate = quatattionDate;
        val.vaidDate = vaidDate;

        if (quotes == 0)
            await Post(`quatation/add`, val)
                .then((res) => {
                    if (res.success == true) {
                        clear();
                        setaddquotes(false);
                        Swal.fire({
                            title: "Your work has been saved",
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: "#FD711A",
                        }).then(async ({ isConfirmed }) => {
                            if (isConfirmed) {
                                getpost(false);
                            }
                        });
                    }
                    else {
                        showTost(res.msg, "error");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        else {
            val.id = quotes;
            await Post(`quatation/edit`, val)
                .then((res) => {
                    clear();
                    setaddquotes(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(false);
                        }
                    });
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const deleteQuatation = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Get(
                    `quatation/deleteQuatation/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(false);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const download = async (val) => {
        setDownloadSpinner(true);
        await FileBlob(`quatation/Quatationpdfcreate`, { id: quotes, template_id: val.template }, "Quation.pdf")
            .then((resblob) => {
                setDownloadSpinner(false);
                setprint(false);
                setPdfModal(true);
                setTimeout(() => {
                    setPdfBlob(resblob);
                }, 2000);
            })
            .catch((err) => {
                setDownloadSpinner(false);
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const moveorder = async () => {
        await Post(`quatation/moveToOrder/${quotes}`)
            .then((res) => {
                setorder(false);
                getpost(false);
                showTost("Quotation to Order Converted", "success");
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const [quatattionDate, setQuatattionDate] = useState(new Date().toISOString().split('T')[0]);
    const [vaidDate, setVaidDate] = useState(new Date());
    useEffect(() => {
        const sevenDaysAfter = new Date(quatattionDate);
        sevenDaysAfter.setDate(sevenDaysAfter.getDate() + 7);
        setVaidDate(sevenDaysAfter.toISOString().split('T')[0]);
    }, [quatattionDate]);

    const handleQuatattionDateChange = (e) => {
        setQuatattionDate(new Date(e.target.value));
    };

    const duplicateLead = async (quotationId) => {
        await Post(`quatation/duplicateLead`, { quotationId })
            .then((res) => {
                if (res.success == true) {
                    getpost(false);
                    showTost("Lead created successfully", "success");
                } else {
                    showTost(res.msg, "warning");
                }
            })
            .catch((err) => {
                showTost("Error duplicating lead", "error");
            });
    }
    return (
        <div className='content-wrapper page'>
            <div className="row mb-2 mt-2">
                <div className="col-md-2">
                    <h2>Quotations</h2>
                </div>
                <div className="col-md-10 mt-2 mt-md-0">
                    <div className="float-right">
                        <div className='d-flex mb-2'>
                            <span className='btn btn-sm btn-primary mr-2' onClick={() => { setaddquotes(true); setquotes(0); }}>
                                <i className="fa fa-plus mr-2" /> Create Quotation
                            </span>
                            <span>
                                <ButtonGroup>
                                    <Btn size="sm" color={isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                    </Btn>
                                    <Btn size="sm" color={!isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg>
                                    </Btn>
                                </ButtonGroup>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="float-right">
                        <input type="text" placeholder="Search Company Name" onKeyPress={(event) => {
                            if (event.nativeEvent.key === 'Enter') {
                                setfiltername(event.target.value)
                            }
                        }} className="form-control w-auto" />
                    </div>
                </div>
            </div>
            <div className='bookmark-tabcontent mt-3'>
                <div className={"details-bookmark text-center " + (isGridView ? "list-bookmark" : '')} id="list-bookmark1">
                    <div className="row" id="bookmarkData">
                        {data.length == 0 ? <div className="mt-5">
                            No Record Found
                        </div> : data.map(item => (
                            <div className="col-xl-3 col-md-4 xl-50">
                                <div className="card card-with-border bookmark-card o-hidden">
                                    <div className="details-website">
                                        <div className="desciption-data">
                                            <div className="title-bookmark">
                                                <div className='row mb-3'>
                                                    <div className='col-md-7'>
                                                        <h6 className="weburl_0">
                                                            <i className="fa fa-building mr-2" />
                                                            {item.Customer?.Company} ({item.Customer?.FirstName} {item.Customer?.LastName})
                                                        </h6>
                                                    </div>
                                                    <div className='col-md-5 d-flex justify-content-end'>
                                                        <div className="d-flex">
                                                            <span>
                                                                <i className='fa fa-print btn btn-success btn-sm rounded-circle' onClick={() => { setquotes(item._id); setprint(true); }} />
                                                                <i className='btn btn-sm btn-success fa fa-pencil ml-1' onClick={() => { quatationbyid(item._id); }} />
                                                                <i className='btn btn-danger btn-sm fa fa-trash ml-1' onClick={() => { deleteQuatation(item._id); }} />
                                                                <button onClick={() => duplicateLead(item._id)} className='btn btn-sm ml-1 bg-light'><div class="text-body"> <i class="fa fa-files-o mr-1"></i> Lead</div></button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Quatation No </h6>
                                                    <h6 className="col-8 f-13 text-capitalize text-dark">{item.QuatationCode}</h6>
                                                </div>
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Amount </h6>
                                                    <h6 className="col-8 f-13 font-weight-normal text-capitalize text-dark">{fmt.format(item.FinalPrice)}</h6>
                                                </div>
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Issued on </h6>
                                                    <h6 className="col-8 f-13 text-capitalize text-success">{moment(item.QuatationDate).format("DD-MMM")}</h6>
                                                </div>
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Valid Till </h6>
                                                    <h6 className="col-8 f-13 text-capitalize text-danger">{moment(item.ValidDate).format("DD-MMM")}</h6>
                                                </div>
                                                <div>
                                                    <span>{item.Stage == 'New' ? <button className='btn btn-sm btn-info mb-3' onClick={() => { setorder(true); setquotes(item._id); }} >Convert to Order</button> : <label className='f-13 font-weight-bold text-success'>Converted</label>}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {data.length == 0 ? "" :
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={perPage}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            linkclassName={"page-link"}
                            onChange={NexPage}
                            itemClass={"page-item"}
                            linkClass={"page-link"}
                        />
                    }
                </div>
            </div>


            <CModal alignment="center" size="sm" visible={order}>
                <div className="modal-header">
                    <h4 className="modal-title">Allegiance Water Management</h4>
                    <button type="button" className="close" onClick={() => setorder(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <label>Are you sure you wish to convert this Quotation to Order?</label>
                </div>
                <div className="modal-footer">
                    <button type="button" className='btn btn-sm font-weight-bold' onClick={() => moveorder()}> YES</button>
                    <button type="button" className='btn btn-sm font-weight-bold' onClick={() => setorder(false)}> NO</button>
                </div>
            </CModal >
            <CModal alignment="center" size="xl" visible={addquotes}>
                <div className="modal-header">
                    <h4 className="modal-title">{quotes == 0 ? "Create Quotation" : "Update Quotation"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setaddquotes(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initValue}
                        validationSchema={validation}
                        key={formkey}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className='row'>
                                                {Manual?.Quotation == true ?
                                                    <div className="col-6 mb-2">
                                                        <label>Quatation Code</label>
                                                        <input type="text" placeholder="Quatation Code" value={values.QuatationCode} defaultValue={values.QuatationCode} name='QuatationCode' className={!!touched.QuatationCode && !!errors.QuatationCode ? "form-control is-invalid" : "form-control"} />
                                                        {quotes == 0 ? <div>
                                                            <span>Last Quatation Code:</span>
                                                            <span className='fw-bold ms-1'>{lastQuatationCode}</span>
                                                        </div> : ""}
                                                    </div> : ""}
                                                <div className="col-6 mb-2">
                                                    <label>Quatation Name</label>
                                                    <select defaultValue={values.QuatationName} className={!!touched.QuatationName && !!errors.QuatationName ? "form-select is-invalid" : "form-select"} name='QuatationName'>
                                                        <option value="">Select Quatation Name</option>
                                                        {QuatationNamelist
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.Name}</option>
                                                            ))}
                                                    </select>
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label>Description of work</label>
                                                    <textarea type="text" rows="3" placeholder="Description of Work" defaultValue={values.Descriptionofwork} name='Descriptionofwork' className={!!touched.Descriptionofwork && !!errors.Descriptionofwork ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Customer</label>
                                                    <div className='input-group'>
                                                        <Select
                                                            name="customer"
                                                            value={customer.map((x) => ({
                                                                value: x._id,
                                                                label: `${x.Company} (${x.FirstName} ${x.LastName})`,
                                                            })).find((option) => option.value === values.customer) || null}
                                                            onChange={(e) => {
                                                                setFieldValue("customer", e.value);
                                                                getcustomer(e.value);
                                                            }}
                                                            options={customer.map((x) => ({
                                                                value: x._id,
                                                                label: `${x.Company} (${x.FirstName} ${x.LastName})`,
                                                            }))}
                                                            className={touched.customer && errors.customer ? "is-invalid w-75" : "w-75"}
                                                        />
                                                        <span className="input-group-text">
                                                            {values.customer ? (
                                                                <i className='fa fa-pencil' onClick={() => { setcustomerval(values.customer); setcustomeradd(true) }} />
                                                            ) : (
                                                                <i className='fa fa-plus' onClick={() => { setcustomerval(0); setcustomeradd(true) }} />
                                                            )}
                                                            {customeradd && (
                                                                <CustomerModal
                                                                    setcustomeradd={setcustomeradd}
                                                                    customeradd={customeradd}
                                                                    getcustomerpost={getcustomerpost}
                                                                    showTost={showTost}
                                                                    Manual={Manual}
                                                                    setcustomerval={setcustomerval}
                                                                    customerval={customerval}
                                                                />)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Sales</label>
                                                    <div className='input-group'>
                                                        <select defaultValue={values.sales} className={!!touched.sales && !!errors.sales ? "form-select is-invalid" : "form-select"} name='sales'>
                                                            <option value="">Select Sales</option>
                                                            {sales
                                                                .map((x, i) => (
                                                                    <option key={i} value={x._id}>{x.name}</option>
                                                                ))}
                                                        </select>
                                                        <span className="input-group-text">
                                                        {values.sales ? (
                                                            <i className='fa fa-pencil' onClick={() => { setuserval(values.sales); setuseradd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setuserval(0); setuseradd(true) }} />
                                                        )}
                                                        {useradd && (
                                                            <UserModal
                                                                useradd={useradd}
                                                                setuseradd={setuseradd}
                                                                getuserpost={getuserpost}
                                                                showTost={showTost}
                                                                userval={userval}
                                                                setuserval={setuserval}
                                                            />
                                                        )}
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Shipping Address</label>
                                                    <select defaultValue={values.shippingAddress} className={!!touched.shippingAddress && !!errors.shippingAddress ? "form-select is-invalid" : "form-select"} name='shippingAddress'>
                                                        <option value="">Select Shipping Address</option>
                                                        {ShippingAddress
                                                            .map((x, i) => {
                                                                if (values.shippingAddress == x._id) {
                                                                    return <option selected key={i} value={x._id}>{x.Address}</option>;
                                                                }
                                                                return <option key={i} value={x._id}>{x.Address}</option>;
                                                            })}
                                                    </select>
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Billing Address</label>
                                                    <select defaultValue={values.billingAddress} className={!!touched.billingAddress && !!errors.billingAddress ? "form-select is-invalid" : "form-select"} name='billingAddress'>
                                                        <option value="">Select Billing Address</option>
                                                        {BillingAddress
                                                            .map((x, i) => {
                                                                if (values.billingAddress == x._id) {
                                                                    return <option selected key={i} value={x._id}>{x.Address}</option>;
                                                                }
                                                                return <option key={i} value={x._id}>{x.Address}</option>;
                                                            })}
                                                    </select>
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Quotation Date</label>
                                                    <input type="date" placeholder="Quotation Date" onChange={handleQuatattionDateChange} defaultValue={quatattionDate} name='quatattionDate' className={!!touched.quatattionDate && !!errors.quatattionDate ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Valid till</label>
                                                    <input type="date" placeholder="Valid till"
                                                        value={vaidDate} name='vaidDate' className={!!touched.vaidDate && !!errors.vaidDate ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Total CGST</label>
                                                    <input type="text" readOnly={true} placeholder="CGST" value={values.CGST} defaultValue={values.CGST} name='CGST' className={!!touched.CGST && !!errors.CGST ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Total SGST</label>
                                                    <input type="text" readOnly={true} placeholder="SGST" value={values.SGST} defaultValue={values.SGST} name='SGST' className={!!touched.SGST && !!errors.SGST ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Total Tax</label>
                                                    <input type="text" readOnly={true} placeholder="Total Tax" value={values.totalTax} defaultValue={values.totalTax} name='totalTax' className={!!touched.totalTax && !!errors.totalTax ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Total Price(Before Tax)</label>
                                                    <input type="text" readOnly={true} placeholder="Total Price(Before Tax)" value={values.BeforeTaxPrice} defaultValue={values.BeforeTaxPrice} name='BeforeTaxPrice' className={!!touched.BeforeTaxPrice && !!errors.BeforeTaxPrice ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Total Price(After Tax)</label>
                                                    <input type="text" readOnly={true} placeholder="Total Price(After Tax)" value={values.AfterTaxPrice} defaultValue={values.AfterTaxPrice} name='AfterTaxPrice' className={!!touched.AfterTaxPrice && !!errors.AfterTaxPrice ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Other ChargeName</label>
                                                    <input type="text" placeholder="Other ChargeName" value={values.OtherChargeName} defaultValue={values.OtherChargeName} name='OtherChargeName' className={!!touched.OtherChargeName && !!errors.OtherChargeName ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Other Charge</label>
                                                    <input type="text" placeholder="Other Charge" onBlur={() => { TotalCalculator(values); }} value={values.OtherCharge} defaultValue={values.OtherCharge} name='OtherCharge' className={!!touched.OtherCharge && !!errors.OtherCharge ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Discount(%)</label>
                                                    <input type="text" placeholder="Discount" onBlur={() => { TotalCalculator(values); }} value={values.discount} defaultValue={values.discount} name='discount' className={!!touched.discount && !!errors.discount ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Amount</label>
                                                    <input type="text" readOnly={true} placeholder="Amount" value={values.Amount} defaultValue={values.Amount} name='Amount' className={!!touched.Amount && !!errors.Amount ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Round Off</label>
                                                    <input type="text" readOnly={true} placeholder="Round Off" value={values.RoundOff} defaultValue={values.RoundOff} name='RoundOff' className={!!touched.RoundOff && !!errors.RoundOff ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Final Amount</label>
                                                    <input type="text" readOnly={true} placeholder="Final Amount" value={values.finalPrice} defaultValue={values.finalPrice} name='finalPrice' className={!!touched.finalPrice && !!errors.finalPrice ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label>Note</label>
                                                    <textarea type="text" rows="3" placeholder="Notes" defaultValue={values.note} name='note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className='col-12'>
                                                    <div><h4>Terms And Condition</h4></div>
                                                    <CKEditor
                                                        name="TermsAndCondition"
                                                        id="TermsAndCondition"
                                                        content={values.TermsAndCondition}
                                                        events={{
                                                            change: (eve) => {
                                                                const data = eve.editor.getData();
                                                                setFieldValue("TermsAndCondition", data);
                                                            }
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className='row'>
                                                <div>
                                                    <h4>Products</h4>
                                                </div>
                                                {values.products.map((each, index) => (
                                                    <div key={index} className={`border border-1 border-info mt-1 p-2 rounded-sm`}>
                                                        <div className=' row'>
                                                            <div className="col-6 mb-2">
                                                                <label>Product</label>
                                                                <div className='input-group'>
                                                                    <select
                                                                        defaultValue={each.product}
                                                                        className={!!touched.each?.product && !!errors.each?.product ? " form-select is-invalid" : " form-select"}
                                                                        name={`products[${index}].product`}
                                                                        value={each?.product}
                                                                        onChange={handleChange}
                                                                        onBlur={() => {
                                                                            let name = productlist.filter(f => f._id == each?.product)
                                                                            each.note = name[0]?.Description;
                                                                            // each.price = name[0]?.SalePrice;
                                                                            values.products[index] = each;
                                                                            setinitValue(values);
                                                                            genrateKeys();
                                                                        }}
                                                                    >
                                                                        <option value="">Select Product</option>
                                                                        {productlist
                                                                            .map((x, i) => {
                                                                                if (each.product == x._id) {
                                                                                    return <option selected key={i} value={x._id}>{x.Name}</option>;
                                                                                }
                                                                                return <option key={i} value={x._id}>{x.Name}</option>;
                                                                            })}
                                                                    </select>
                                                                    <span className="input-group-text">
                                                                        {each?.product ? (
                                                                            <i className='fa fa-pencil' onClick={() => { setproductval(each?.product); setproductadd(true) }} />
                                                                        ) : (
                                                                            <i className='fa fa-plus' onClick={() => { setproductval(0); setproductadd(true) }} />
                                                                        )}
                                                                        {productadd && (
                                                                            <ProductModal
                                                                                setproductadd={setproductadd}
                                                                                productadd={productadd}
                                                                                getproductpost={getproductpost}
                                                                                showTost={showTost}
                                                                                setproductval={setproductval}
                                                                                productval={productval}
                                                                            />)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-2">
                                                                <label>Note</label>
                                                                <textarea type="text" rows="3" placeholder="Notes"
                                                                    value={each?.note}
                                                                    onChange={handleChange}
                                                                    defaultValue={each.note}
                                                                    name={`products[${index}].note`}
                                                                    onBlur={() => {
                                                                        TotalCalculator(values);
                                                                    }}
                                                                    className={!!touched.each?.note && !!errors.each?.note ? " form-control is-invalid" : " form-control"}
                                                                />
                                                            </div>
                                                            <div className="col-4 mb-2">
                                                                <label>Quantity</label>
                                                                <input type="text" placeholder="Quantity"
                                                                    defaultValue={each?.quantity}
                                                                    value={each?.quantity}
                                                                    onChange={handleChange}
                                                                    name={`products[${index}].quantity`}
                                                                    onBlur={() => {
                                                                        TotalCalculator(values);
                                                                    }}
                                                                    className={!!touched.each?.quantity && !!errors.each?.quantity ? " form-control is-invalid" : " form-control"}
                                                                />
                                                            </div>
                                                            <div className="col-4 mb-2">
                                                                <label>Price</label>
                                                                <input type="text" placeholder="Price"
                                                                    defaultValue={each?.price}
                                                                    onChange={handleChange}
                                                                    value={each.price}
                                                                    name={`products[${index}].price`}
                                                                    onBlur={() => {
                                                                        TotalCalculator(values);
                                                                    }}
                                                                    className={!!touched.each?.price && !!errors.each?.price ? " form-control is-invalid" : " form-control"} />
                                                            </div>
                                                            <div className="col-4 mb-2">
                                                                <label>Unit</label>
                                                                <div className='input-group'>
                                                                    <select
                                                                        defaultValue={each.unit}
                                                                        onChange={handleChange}
                                                                        value={each?.unit}
                                                                        className={!!touched.products?.unit && !!errors.products?.unit ? " form-select is-invalid" : " form-select"}
                                                                        onBlur={() => {
                                                                            TotalCalculator(values);
                                                                        }}
                                                                        name={`products[${index}].unit`}>
                                                                        <option value="">Select Unit</option>
                                                                        {unitlist
                                                                            .map((x, i) => {
                                                                                if (each.unit == x._id) {
                                                                                    return <option selected key={i} value={x._id}>{x.Name}</option>;
                                                                                }
                                                                                return <option key={i} value={x._id}>{x.Name}</option>;
                                                                            })}
                                                                    </select>
                                                                    <span className="input-group-text">
                                                                    {each?.unit ? (
                                                                        <i className='fa fa-pencil' onClick={() => { setunitval(each?.unit); setunitadd(true) }} />
                                                                    ) : (
                                                                        <i className='fa fa-plus' onClick={() => { setunitval(0); setunitadd(true) }} />
                                                                    )}
                                                                    {unitadd && (
                                                                        <UnitModal
                                                                            setunitadd={setunitadd}
                                                                            unitadd={unitadd}
                                                                            getunitpost={getunitpost}
                                                                            showTost={showTost}
                                                                            setunitval={setunitval}
                                                                            unitval={unitval}
                                                                        />)}
                                                                </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-4 mb-2">
                                                                <label>Amount</label>
                                                                <input type="text" placeholder="Amount"
                                                                    defaultValue={each.TotalAmount}
                                                                    onChange={handleChange}
                                                                    value={each?.TotalAmount}
                                                                    name={`products[${index}].TotalAmount`}
                                                                    readOnly={true}
                                                                    onBlur={() => {
                                                                        TotalCalculator(values);
                                                                    }}
                                                                    className={!!touched.products?.TotalAmount && !!errors.products?.TotalAmount ? " form-control is-invalid" : " form-control"}
                                                                />
                                                            </div>
                                                            <div className="col-4 mb-2">
                                                                <label>CGST(%)</label>
                                                                <input type="text" placeholder="CGST"
                                                                    defaultValue={each.CGST}
                                                                    value={each?.CGST}
                                                                    name={`products[${index}].CGST`}
                                                                    onChange={handleChange}
                                                                    onBlur={() => {
                                                                        TotalCalculator(values);
                                                                    }}
                                                                    className={!!touched.products?.CGST && !!errors.products?.CGST ? " form-control is-invalid" : " form-control"}
                                                                />
                                                            </div>
                                                            <div className="col-4 mb-2">
                                                                <label>SGST(%)</label>
                                                                <input type="text" placeholder="SGST"
                                                                    defaultValue={each.SGST}
                                                                    value={each?.SGST}
                                                                    onChange={handleChange}
                                                                    name={`products[${index}].SGST`}
                                                                    onBlur={() => {
                                                                        TotalCalculator(values);
                                                                    }}
                                                                    className={!!touched.products?.SGST && !!errors.products?.SGST ? " form-control is-invalid" : " form-control"}
                                                                />
                                                            </div>
                                                            <div className="col-8 mb-2">
                                                                <label>Final Amount</label>
                                                                <input type="text" placeholder="Final Amount"
                                                                    defaultValue={each.FinalAmount}
                                                                    onChange={handleChange}
                                                                    value={each?.FinalAmount}
                                                                    name={`products[${index}].FinalAmount`}
                                                                    readOnly={true}
                                                                    onBlur={() => {
                                                                        TotalCalculator(values);
                                                                    }}
                                                                    className={!!touched.products?.FinalAmount && !!errors.products?.FinalAmount ? " form-control is-invalid" : " form-control"}
                                                                />
                                                            </div>
                                                            <div className="col-2 mb-2">
                                                                <i className='fa fa-trash btn btn-sm btn-danger mt-4' onClick={() => { genrateKeys(); onDelete(index, values) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className='mt-1'>
                                                    <button onClick={() => { genrateKeys(); AddMore(values) }} className='btn btn-primary btn-sm float-right'>
                                                        <i className='fa fa-plus mr-2'></i> Add Product
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success mt-2'>
                                        <i className="fa fa-check" /> Save
                                    </button>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </CModal>

            <CModal alignment="center" visible={print}>
                <div className="modal-header">
                    <h4 className="modal-title">Select Template For Quotation</h4>
                    <button type="button" className="close" onClick={() => setprint(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={download}
                        initialValues={inittemplateValue}
                        validationSchema={templatevalidation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-1">
                                        <select
                                            defaultValue={values.template}
                                            className={!!touched.template && !!errors.template ? " form-select is-invalid" : " form-select"}
                                            name='template'>
                                            {template1.map((x, i) => {
                                                return (
                                                    <option value={x._id}>{x.Name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-12 mt-2'>
                                        {downloadSpinner ?
                                            <div className={`loader-box ${styles.DownloadQuoteLoader}`}>
                                                <Spinner className={'loader-7'} />
                                            </div>
                                            :
                                            <button type="submit" className='btn btn-sm font-weight-bold btn-success'>
                                                <i className="fa fa-check" /> Save
                                            </button>
                                        }
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </CModal>
            <CModal alignment="center" visible={pdfmodal} size='lg'>
                <div className="modal-header">
                    <h4 className="modal-title">Viewer</h4>
                    <button type="button" className="close" onClick={() => { setPdfModal(false); }}>&times;</button>
                </div>
                <div className="modal-body" style={{ marginLeft: '75px' }}>
                    <div>
                        {pdfBlob && <PDFViewer blob={pdfBlob} />}
                        {!pdfBlob && <p>Loading...</p>}
                    </div>
                </div>
            </CModal>
        </div >
    );
}

export default Quotes;
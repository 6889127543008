const FileUpload = (url, formdata) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var myHeaders = {
      'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("Token"))}`,
      'db_name': window.localStorage.getItem("DB_NAME"),
    };
    fetch(process.env.REACT_APP_APIURL + "api/" + url, {
      method: "POST",
      body: formdata,
      redirect: 'follow',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
}
const OrgFileUpload = (url, formdata,dbname) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var myHeaders = {
      'db_name': dbname,
    };
    fetch(process.env.REACT_APP_APIURL + "api/" + url, {
      method: "POST",
      body: formdata,
      redirect: 'follow',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
}
const EditFileUpload = (url, formdata) => {
  return new Promise((resolve, reject) => {
    var myHeaders = {
      'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("Token"))}`,
      'db_name': window.localStorage.getItem("DB_NAME"),
    };
    fetch(process.env.REACT_APP_APIURL + "api/" + url, {
      method: "PUT",
      body: formdata,
      redirect: 'follow',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const Post = (url, data) => {
  return new Promise((resolve, reject) => {
    try {
      var myHeaders = new Headers();
      var myHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("Token"))}`,
        'db_name': window.localStorage.getItem("DB_NAME"),
      };

      fetch(process.env.REACT_APP_APIURL + "api/" + url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: myHeaders,
      })
        .then(response => response.json())
        .then(json => {
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

const FileBlob = (url, data, fileName) => {
  return new Promise((resolve, reject) => {
    try {
      var myHeaders = new Headers();
      var myHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("Token"))}`,
        'db_name': window.localStorage.getItem("DB_NAME"),
      };

      fetch(process.env.REACT_APP_APIURL + "api/" + url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: myHeaders,
      })
        .then(async response => {
          const blob = await response.blob();
          resolve(blob);
        })
        .catch(error => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

const File = (url, data, fileName) => {
  return new Promise((resolve, reject) => {
    try {
      var myHeaders = new Headers();
      var myHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("Token"))}`,
        'db_name': window.localStorage.getItem("DB_NAME"),
      };

      fetch(process.env.REACT_APP_APIURL + "api/" + url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: myHeaders,
      })
        .then(response => {
          resolve();
          response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            // let alink = document.createElement('a');
            // alink.href = fileURL;
            // alink.target="_blank"
            // alink.download = fileName;
            // alink.click();
            window.open(fileURL, "_blank");
          })
        })
        .catch(error => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

const Get = (url, data) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var myHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("Token"))}`,
      'db_name': window.localStorage.getItem("DB_NAME"),
    };
    fetch(process.env.REACT_APP_APIURL + "api/" + url, {
      method: "GET",
      body: JSON.stringify(data),
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export {
  Post,
  FileUpload,
  OrgFileUpload,
  EditFileUpload,
  FileBlob,
  File,
  Get
};
import React, { useEffect, useState } from 'react';
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { immediateToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import { Link } from "react-router-dom";

const validation = Yup.object().shape({
    code: Yup.string().required(),
});

const initValue = {
    code: "",
};
const Organization = () => {
    const [loading, setloading] = useState(false);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    useEffect(() => {
        window.localStorage.clear()
    }, []);
    const handleSubmit = async (val) => {
        setloading(true)
        await axios
            .post(`${process.env.REACT_APP_APIURL}api/users/checkOrganization`, val)
            .then((res) => {
                setloading(false);
                if (res.data.success == true) {
                    window.localStorage.setItem("DB_NAME", res.data.DB_NAME);
                    window.localStorage.setItem("CompanyLogo", res.data.CompanyLogo);
                    window.location.replace("/Login")
                }
                else {
                    showTost(res.data.message, "warning");
                }
            })
            .catch((err) => {
                setloading(false);
                showTost(err.response.data.message, "error");
            });
        setloading(false)
    };
    return (
        <div className="page-wrapper compact-wrapper">
            <div className="authentication-main mt-0">
                <div className='row'>
                    <div className="col-12 p-0">
                        <div className="auth-innerright auth-minibox" style={{ background: 'linear-gradient(90deg, rgba(101,90,243,1) 0%, rgba(236,90,243,1) 98%)' }}>
                            <div className="authentication-box auth-minibox1 card p-5 shadow">
                                <Formik
                                    onSubmit={handleSubmit}
                                    initialValues={initValue}
                                    validationSchema={validation}
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        touched,
                                        errors,
                                        setFieldValue,
                                    }) => {
                                        return (
                                            <form onSubmit={handleSubmit} onChange={handleChange} className="theme-form">
                                                <h2>Welcome back!</h2>
                                                <div className="form-group">
                                                    <label>Organization Code</label>
                                                    <input type="text" name='code' className={!!touched.code && !!errors.code ? "border-danger form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="w-100 btn btn-primary submit">Sign In</button>
                                                </div>
                                                <div className="form-group text-center">
                                                    New Organization ? <Link className="m-l-10" to="/CreateOrganization">Sign Up</Link>
                                                </div>
                                            </form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <div className="preloader"></div> : <div></div>}
        </div>
    )
}

export default Organization;
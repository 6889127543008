import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import Select from "react-select";
import { Form } from "react-bootstrap";
import moment from "moment";
import { Post, Get, FileUpload } from '../../Layout/ajax';
import { CImage } from '@coreui/react';
import { immediateToast } from "izitoast-react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useParams } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";
import { CommentModal } from './Modal.js';
import { CModal } from '@coreui/react';

const validation = Yup.object().shape({
    Name: Yup.string().required(),
    Priority: Yup.string().required(),
    Status: Yup.string().required(),
});
const TaskAdd = () => {
    const { id } = useParams();
    const [tasks, setTasks] = useState([]);
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [addtaskcomment, setaddtaskcomment] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [users, setusers] = useState([]);
    const [initValue, setinitValue] = useState({
        ProjectId: "",
        ProcessId: "",
        Name: "",
        Priority: "",
        Assign: "",
        Status: "",
        Description: "",
        image: "",
        StartDate: null,
        EndDate: null,
        Reporter: [],
        requiredTime: ""
    });
    const clear = () => {
        setinitValue({
            ProjectId: "",
            ProcessId: "",
            Name: "",
            Priority: "",
            Assign: "",
            Status: "",
            Description: "",
            StartDate: null,
            EndDate: null,
            image: "",
            Reporter: [],
            requiredTime: ""
        });
        setSelectedFile("")
    }
    const [Status, setStatus] = useState([]);
    const [Project, setProject] = useState([]);
    const [Process, setProcess] = useState([]);
    const [AllTask, setAllTask] = useState([]);
    const [showTaskModal, setShowTaskModal] = useState(false);
    const getpost = async (assignId) => {
        await Post(`task/getStatustask`, { assignId })
            .then((res) => {
                setAllTask(res.data);
                setShowTaskModal(true);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    useEffect(() => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setusers(res)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        if (id) {
            getbyid(id);
        }
        Post(`master/getStatus`, { active: true })
            .then((res) => {
                setStatus(res.data?.filter(f => f.GroupName == "Tasks"));
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`contract/getAllContract`, { active: true })
            .then((res) => {
                setProject(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        getAllProcess();

    }, []);
    const getAllProcess = async (projectId) => {
        await Post(`contract/getAllProcess`, { contractId: projectId })
            .then((res) => {
                setProcess(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const handleProjectChange = (event, setFieldValue) => {
        const projectId = event.target.value;
        setFieldValue("ProjectId", projectId);
        if (projectId) {
            getAllProcess(projectId);
        } else {
            setProcess([]);
        }
    }
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile]);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const genrateKeys = () => {
        setFormKey(Math.random() * 1000000);
    }
    const handleChange1 = (image) => {
        setSelectedFile(image);
    };
    const taskcommit = async (val) => {
        await Post(`task/getAlltaskcomment`, { "taskId": val })
            .then((res) => {
                setaddtaskcomment(res.data);
                genrateKeys()
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getbyid = async (val) => {
        if (val != 0) {
            Get(`task/getById/${val}`)
                .then((res) => {
                    setinitValue({
                        ProjectId: res.data.ProjectId,
                        ProcessId: res.data.ProcessId,
                        Name: res.data.Name,
                        Priority: res.data.Priority,
                        Description: res.data.Description,
                        Assign: res.data.Assign,
                        Status: res.data.Status,
                        StartDate: moment(res.data.StartDate).format("YYYY-MM-DDTHH:mm"),
                        EndDate: moment(res.data.EndDate).format("YYYY-MM-DDTHH:mm"),
                        Reporter: res.data.Reporter,
                    });
                    genrateKeys();
                    taskcommit(val);
                    setPreview(process.env.REACT_APP_APIURL + 'static/' + res.data.image);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const handleSubmit = async (val) => {
        let formdata = new FormData();
        formdata.append("image", selectedFile);
        formdata.append("ProjectId", val.ProjectId);
        formdata.append("ProcessId", val.ProcessId);
        formdata.append("Name", val.Name);
        formdata.append("Priority", val.Priority);
        formdata.append("Assign", val.Assign);
        formdata.append("Status", val.Status);
        formdata.append("Description", val.Description);
        formdata.append("StartDate", val.StartDate);
        formdata.append("EndDate", val.EndDate);
        if (Array.isArray(val.Reporter)) {
            val.Reporter.forEach((reporter) => formdata.append("Reporter", reporter || ''));
        } else {
            formdata.append("Reporter", val.Reporter || []);
        }
        await FileUpload(`task/add`, formdata)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            window.location.replace('/Tasks');
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleEdit = async (val) => {
        let formdata = new FormData();
        formdata.append("id", id);
        formdata.append("image", selectedFile);
        formdata.append("ProjectId", val.ProjectId);
        formdata.append("ProcessId", val.ProcessId);
        formdata.append("Name", val.Name);
        formdata.append("Priority", val.Priority);
        if (val.Assign && val.Assign !== "null") {
            formdata.append("Assign", val.Assign);
        }
        formdata.append("Status", val.Status);
        formdata.append("Description", val.Description);
        formdata.append("StartDate", val.StartDate);
        formdata.append("EndDate", val.EndDate);
        if (Array.isArray(val.Reporter)) {
            val.Reporter.forEach((reporter) => formdata.append("Reporter", reporter || ''));
        } else {
            formdata.append("Reporter", val.Reporter || []);
        }
        await FileUpload(`task/edit`, formdata)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            window.location.replace('/Tasks');
                            setSelectedFile(null);
                            setPreview(null);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const changestatus = (detvalues) => {
        let values = { ...detvalues };
        let name = Status.filter(f => f._id == values.Status)
        values.Assign = name[0]?.Assign;
        setinitValue(values);
        genrateKeys();
    }
    const handleAssignChange = async (e, setFieldValue, values) => {
        const assignId = e.target.value;
        setFieldValue("Assign", assignId);
        setShowTaskModal(false);
        if (assignId) {
            try {
                const response = await Post(`task/getStatustask`, { assignId });
                const userTasks = response.data;
                const sortedTasks = userTasks.sort((a, b) =>
                    new Date(b.EndDate) - new Date(a.EndDate)
                );
                const lastTask = sortedTasks[0];
                if (lastTask && lastTask.EndDate) {
                    const endDate = moment(lastTask.EndDate).format("YYYY-MM-DDTHH:mm");
                    setFieldValue("StartDate", endDate);
                    updateEndDate({ ...values, StartDate: endDate }, setFieldValue);
                } else {
                    setFieldValue("StartDate", "");
                    setFieldValue("EndDate", "");
                }
            } catch (error) {
                console.error("Error fetching tasks:", error);
                showTost("Error fetching tasks", "error");
            }
        } else {
            setTasks([]);
            setFieldValue("StartDate", "");
            setFieldValue("EndDate", "");
        }
    };
    // const handleRequiredTimeChange = (e, setFieldValue, values) => {
    //     const requiredTime = e.target.value;
    //     const startDate = moment(values.StartDate);
    //     const newEndDate = startDate.add(parseInt(requiredTime), 'hours');
    //     setFieldValue('EndDate', newEndDate.format('YYYY-MM-DDTHH:mm'));
    // };
    const updateEndDate = (values, setFieldValue) => {
        const requiredTime = parseInt(values.requiredTime || 0);
        const startDate = moment(values.StartDate);
        if (startDate.isValid() && requiredTime) {
            const newEndDate = startDate.add(requiredTime, 'hours');
            setFieldValue('EndDate', newEndDate.format('YYYY-MM-DDTHH:mm'));
        }
    };
    const handleRequiredTimeChange = (e, setFieldValue, values) => {
        setFieldValue('requiredTime', e.target.value);
        updateEndDate({ ...values, requiredTime: e.target.value }, setFieldValue);
    };
    return (
        <div className="mx-md-3 mx-2">
            <CModal alignment="center" size="lg" visible={showTaskModal}>
                <div className="modal-header">
                    <h4 className="modal-title">Task</h4>
                    <button type="button" className="close" onClick={() => setShowTaskModal(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    {AllTask.length === 0 ? (
                        <p>No tasks available</p>
                    ) : (
                        <ul className="list-group">
                            {AllTask
                                .map((task) => (
                                    <li key={task._id} className="list-group-item">
                                        <h6>Task Name{task.Name}</h6>
                                        <h6>{task.Assign.name}</h6>
                                        <p>Status: {task.Status.Name}</p>
                                        <td>{task.EndDate ? moment(task.EndDate).format("YYYY-MM-DD HH:mm") : "N/A"}</td>
                                    </li>
                                ))}
                        </ul>
                    )}
                </div>
            </CModal>
            <div>
                <h4 className="">{id == 0 ? "Add Task" : "Update Task"}</h4>
                <Formik
                    onSubmit={id == 0 ? handleSubmit : handleEdit}
                    initialValues={initValue}
                    validationSchema={validation}
                    key={formkey} >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className="mb-2">
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-4 mt-2'>
                                                <label>Project Name</label>
                                                <select name="ProjectId" onChange={(e) => handleProjectChange(e, setFieldValue)} defaultValue={values.ProjectId} className={!!touched.ProjectId && !!errors.ProjectId ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Project</option>
                                                    {Project
                                                        .map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className='col-4 mt-2 pr-4'>
                                                <label>Process Name</label>
                                                <select name="ProcessId" defaultValue={values.ProcessId} className={!!touched.ProcessId && !!errors.ProcessId ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Process</option>
                                                    {
                                                        Process
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.Name}</option>
                                                            ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-md-8'>
                                            <div className='col-12 mt-2'>
                                                <label>Name</label>
                                                <input type='text' name="Name" defaultValue={values.Name} placeholder='Enter Task' className={!!touched.Name && !!errors.Name ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label>Description</label>
                                                <textarea type='text' rows="14" defaultValue={values.Description} name="Description" placeholder='Enter Description' className={!!touched.Description && !!errors.Description ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='col-12 mt-2'>
                                                <label>Status</label>
                                                <select name="Status" defaultValue={values.Status} onChange={handleChange} onBlur={() => { changestatus(values) }} className={!!touched.Status && !!errors.Status ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Status</option>
                                                    {Status
                                                        .map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label>Assign to :</label>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <select
                                                        name="Assign" value={values.Assign}
                                                        onChange={(e) => handleAssignChange(e, setFieldValue, values)}
                                                        className={!!touched.Assign && !!errors.Assign ? "form-select is-invalid w-75" : "form-select w-75"}>
                                                        <option value="">Select Assign</option>
                                                        {users.map((x, i) => (
                                                            <option key={i} value={x._id}>{x.name}</option>
                                                        ))}
                                                    </select>
                                                    <span><button type="button" className="btn btn-info ml-2 btn-sm" disabled={!values.Assign}
                                                        onClick={() => {
                                                            getpost(values.Assign);
                                                            setShowTaskModal(true);
                                                        }}
                                                    >All Tasks
                                                    </button></span>
                                                </div>
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label>Priority</label>
                                                <select name="Priority" defaultValue={values.Priority} className={!!touched.Priority && !!errors.Priority ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Priority</option>
                                                    <option>High</option>
                                                    <option>Normal</option>
                                                    <option>Low</option>
                                                </select>
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label>Start Date</label>
                                                <input
                                                onChange={(e) => {
                                                    setFieldValue('StartDate', e.target.value);
                                                    updateEndDate({ ...values, StartDate: e.target.value }, setFieldValue);
                                                }}
                                                 type="datetime-local" placeholder="Date" defaultValue={values.StartDate} name='StartDate' className={!!touched.StartDate && !!errors.StartDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label>Required Time(In hour)</label>
                                                <input
                                                    onChange={(e) => handleRequiredTimeChange(e, setFieldValue, values)}
                                                    type="number" placeholder="Required Time" name='requiredTime' className={!!touched.requiredTime && !!errors.requiredTime ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label>End Date</label>
                                                <input type="datetime-local" placeholder="Date" defaultValue={values.EndDate} name='EndDate' className={!!touched.EndDate && !!errors.EndDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label>Reporter</label>
                                                <Select
                                                    isMulti
                                                    name="Reporter"
                                                    value={
                                                        users.filter(
                                                            (x) => {
                                                                if (values.Reporter && values.Reporter.length) {
                                                                    return values.Reporter.indexOf(x._id) != -1;
                                                                } else {
                                                                    return values.Reporter == x._id;
                                                                }
                                                            }
                                                        )
                                                            .map((x) => ({
                                                                value: x._id,
                                                                label: x.name,
                                                            }))}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "Reporter",
                                                            e.map((x) => x.value)
                                                        )
                                                    }
                                                    options={users.map((x) => ({
                                                        value: x._id,
                                                        label: x.name,
                                                    }))}
                                                    className={`slectname ${touched.Reporter && errors.Reporter ? "is-invalid" : ""
                                                        }`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row px-3 align-items-center'>
                                        <div className='col-8 mt-2'>
                                            <label>Upload Screenshort</label>
                                            <FileUploader handleChange={handleChange1} name="image" types={["JPEG", "JPG", "PNG"]} />
                                        </div>
                                        <div className='col-4 mt-2 ps-4'>
                                            {preview ? <CImage rounded thumbnail src={preview} width={100} height={100} /> : ""}
                                        </div>
                                        <div className='col-12 mt-3 text-center'>
                                            <button type="submit" className='btn font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            {
                id != 0 ? (<CommentModal
                    taskcommit={taskcommit}
                    id={id}
                    addtaskcomment={addtaskcomment}
                    genrateKeys={genrateKeys}
                    showTost={showTost}
                    formkey={formkey}
                />) : ""
            }
        </div>
    )
}
export default TaskAdd;
import React, { useEffect, useState } from 'react';
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { immediateToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import { Link } from "react-router-dom";
import { Post, Get, OrgFileUpload } from '../Layout/ajax';
import { CModal, CImage } from '@coreui/react';
import { FileUploader } from "react-drag-drop-files";

const validation = Yup.object().shape({
    Name: Yup.string().required(),
    // Code: Yup.string().required(),
    Code: Yup.string().required().min(6, 'Code must be at least 6 characters or Numeraters'),
    Email: Yup.string().email().required(),
    UserEmail: Yup.string().email().required(),
    password: Yup.string().required(),
    confirmpassword: Yup.string().required()
        .oneOf([Yup.ref('password')], 'Passwords must and should match'),
});

const CreateOrganization = () => {
    const [initValue, setinitValue] = useState({
        Name: "",
        PhoneNo: "",
        Email: "",
        UserEmail: "",
        Description: "",
        password: "",
        confirmpassword: "",
        Website: "",
        Code: "",
        firstName: "",
    });
    const [loading, setloading] = useState(false);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const [selectedFile, setFile] = useState(null);

    const [preview, setPreview] = useState(null);
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const handleChange1 = (image) => {
        setFile(image);
    };
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const genrateKeys = () => {
        setFormKey(Math.random() * 1000000);
    }
    const handleSubmit = async (val) => {
        setloading(true)
        let formdata = new FormData();
        formdata.append("CompanyLogo", selectedFile);
        formdata.append("Name", val.Name);
        formdata.append("PhoneNo", val.PhoneNo);
        formdata.append("Email", val.Email);
        formdata.append("UserEmail", val.UserEmail);
        formdata.append("Description", val.Description);
        formdata.append("password", val.password);
        formdata.append("Website", val.Website);
        formdata.append("Code", val.Code);
        formdata.append("firstName", val.firstName);
        await OrgFileUpload(`users/addOrganization`, formdata, val.Name)
            .then((res) => {
                debugger
                if (res.success == true) {
                    setloading(false)
                    window.location.replace("/")
                }
                else {
                    showTost(res.message, "warning");
                    setloading(false)
                }
            })
            .catch((err) => {
                showTost(err.response.data.message, "error");
                setloading(false)
            });
    };
    const generatecode = async (detvalues) => {
        debugger
        let values = { ...detvalues };
        let gencode = "";
        let length = values.Name.length;
        if (length > 6) {
            values.Code = values.Name.substring(0, 6);
        }
        else if (length == 6) {
            values.Code = values.Name;
        }
        else {
            let code = values.Name + Math.random() * 1000000;
            values.Code = code.substring(0, 6);
        }
        setinitValue(values);

        genrateKeys();
    }

    return (
        <div className="page-wrapper compact-wrapper">
            <div className="authentication-main mt-0">
                <div className='row'>
                    <div className="col-12 p-0">
                        <div className="auth-innerright auth-minibox" style={{ background: 'linear-gradient(90deg, rgba(101,90,243,1) 0%, rgba(236,90,243,1) 98%)' }}>
                            <div className="authentication-box w-50 card shadow p-5 pt-4 pb-0 m-50">
                                <Formik
                                    onSubmit={handleSubmit}
                                    initialValues={initValue}
                                    validationSchema={validation}
                                    key={formkey}
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        touched,
                                        errors,
                                        setFieldValue,
                                    }) => {
                                        return (
                                            <form onSubmit={handleSubmit} onChange={handleChange} className="row theme-form">
                                                <h4 className="text-center text-uppercase">New Organization</h4>
                                                <fieldset className='my-3 pt-4'>
                                                    <legend>Organization</legend>
                                                    <div className='row'>
                                                        <div className="col-6 mb-2">
                                                            <label className='mb-1'>Organization Name</label>
                                                            <input type="text" name='Name' value={values.Name} defaultValue={values.Name} onChange={handleChange} onBlur={(e) => { generatecode(values); }} className={!!touched.Name && !!errors.Name ? "border-danger form-control is-invalid" : "form-control"} />
                                                        </div>
                                                        <div className="col-6 mb-md-0 mb-2">
                                                            <label className='mb-1'>Organization Code</label>
                                                            <input type="text" name='Code' value={values.Code} defaultValue={values.Code}
                                                                className={!!touched.Code && !!errors.Code ? "border-danger form-control is-invalid" : "form-control"} />
                                                            {!!touched.Code && !!errors.Code && (
                                                                <div className="invalid-feedback">
                                                                    {errors.Code}
                                                                </div>
                                                            )}
                                                            <small className='text-secondary'>Googel, Facebook</small>
                                                        </div>
                                                        <div className="form-group mb-2">
                                                            <label className='mb-1'>Description</label>
                                                            <textarea type="text" name='Description' value={values.Description} defaultValue={values.Description} className={!!touched.Description && !!errors.Description ? "border-danger form-control is-invalid" : "form-control"} />
                                                        </div>
                                                        <div className='col-6 mb-2'>
                                                            <label className='mb-1'>Upload Company Logo</label>
                                                            <FileUploader handleChange={handleChange1} multiple={false} name="image" types={["JPEG", "JPG", "PNG"]} />
                                                        </div>
                                                        <div className='col-6 mb-2'>
                                                            {preview && <CImage rounded thumbnail src={preview} width={100} height={100} />}
                                                        </div>
                                                    </div>

                                                </fieldset>
                                                <fieldset className='my-3 pt-4'>
                                                    <legend>Contact Detail</legend>
                                                    <div className='row'>
                                                        <div className="form-group col-6 mb-2">
                                                            <label className='mb-1'>Phone No</label>
                                                            <input type="number" name='PhoneNo' value={values.PhoneNo} defaultValue={values.PhoneNo} className={!!touched.PhoneNo && !!errors.PhoneNo ? "border-danger form-control is-invalid" : "form-control"} />
                                                        </div>
                                                        <div className="form-group col-6 mb-2">
                                                            <label className='mb-1'>Website</label>
                                                            <input type="text" name='Website' value={values.Website} defaultValue={values.Website} className={!!touched.Website && !!errors.Website ? "border-danger form-control is-invalid" : "form-control"} />
                                                        </div>
                                                        <div className="form-group col-6 mb-2">
                                                            <label className='mb-1'>Email</label>
                                                            <input type="text" name='Email' value={values.Email} defaultValue={values.Email} className={!!touched.Email && !!errors.Email ? "border-danger form-control is-invalid" : "form-control"} />
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <fieldset className='my-3 pt-4'>
                                                    <legend>Administrative Login Detail</legend>
                                                    <div className='row'>
                                                        <div className="form-group col-6 mb-2">
                                                            <label className='mb-1'>Your Name</label>
                                                            <input type='text' name="firstName" value={values.firstName} defaultValue={values.firstName} className={!!touched.firstName && !!errors.firstName ? "border-danger form-control is-invalid" : "form-control"} />
                                                        </div>
                                                        <div className="form-group col-6 mb-2">
                                                            <label className='mb-1'>User Email</label>
                                                            <input type="text" name='UserEmail' value={values.UserEmail} defaultValue={values.UserEmail} className={!!touched.UserEmail && !!errors.UserEmail ? "border-danger form-control is-invalid" : "form-control"} />
                                                        </div>
                                                        <div className="form-group col-6 mb-2">
                                                            <label className='mb-1'>Password</label>
                                                            <input type='password' name="password" value={values.password} defaultValue={values.password} className={!!touched.password && !!errors.password ? "border-danger form-control is-invalid" : "form-control"} />
                                                        </div>
                                                        <div className="form-group col-6 mb-2">
                                                            <label className='mb-1'>Confirm Password</label>
                                                            <input type='password' name="confirmpassword" value={values.confirmpassword} defaultValue={values.confirmpassword} className={!!touched.confirmpassword && !!errors.confirmpassword ? "border-danger form-control is-invalid" : "form-control"} />
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <div className="form-group d-flex justify-content-center">
                                                    <button type="submit" className="w-100 my-3 btn btn-primary submit">Submit</button>
                                                </div>
                                                <div className="form-group text-center">
                                                    Already have an Organization ? <Link className="m-l-10" to="/">Sign In</Link>
                                                </div>
                                            </form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <div className="preloader"></div> : <div></div>}
        </div>
    )
}

export default CreateOrganization;
import React, { useEffect } from 'react';
import moment from "moment";
import { Link, useNavigate } from 'react-router-dom';

const NormalTasks = ({filteruser, setfilteruser, users, filterstatus, setfilterstatus, Status, inbox, settask, setreason, taskcommit, setcomment, removetask, outbox, getpost}) => {
    const navigate = useNavigate();
    // useEffect(() => {
    //     getpost(true);
    //   }, []);
    return (
        <div className=''>
            <div className="d-flex justify-content-end my-2">
                <div className="row">
                    <div className="col-auto">
                        {JSON.parse(window.localStorage.getItem('RoleName')) == "SuperAdmin" || JSON.parse(window.localStorage.getItem('RoleName')) == "Admin" ?
                            <select className="form-select" value={filteruser} onChange={(e) => setfilteruser(e.target.value || null)} >
                                <option value="">All</option>
                                {users
                                    .map((x, i) => (
                                        <option key={i} value={x._id}>{x.name}</option>
                                    ))}
                            </select>
                            : ""}
                    </div>
                    <div className="col-auto">
                        <select className="form-select" value={filterstatus} onChange={(e) => setfilterstatus(e.target.value)} >
                            <option value="">All</option>
                            {Status
                                .map((x, i) => (
                                    <option key={i} value={x._id}>{x.Name}</option>
                                ))}
                        </select>
                    </div>                            
                </div>
            </div>
            <div className="row overall-report m-0">
                <div className="col-lg-6 col-md-12 pr-3">
                    <div className='row my-2'>
                        <div title="Tasks for Me" className="mb-3 px-0"><h4> Inbox </h4></div>
                        {inbox.map((x, i) =>
                            <div className='boxShadow mb-3 p-3'>
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="card-title f-16 mb-2 text-capitalize">{x.Name}</h6>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Status </h6>
                                            <h6 className='col-8 f-13' style={{ color: x.Status?.Color }}> {x.Status?.Name}</h6>
                                        </div>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Priority </h6>
                                            <h6 className='col-8 f-13'> {x.Priority}</h6>
                                        </div>
                                        {
                                            x.EndDate === null ? " " : moment(x.EndDate).isBefore(moment()) && x.Reason ? (
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>End Date</h6>
                                                    <h6 className='col-8 text-danger f-13'>
                                                        {moment(x.EndDate).format('lll')}
                                                    </h6>
                                                </div>
                                            ) : moment(x.EndDate).isBefore(moment()) ? (
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>End Date</h6>
                                                    <h6 className='col-8 text-danger f-13'>
                                                        {moment(x.EndDate).format('lll')}
                                                        <i className="fa fa-clock-o btn btn-light btn-sm ml-1" title="add late reason" onClick={() => { settask(x._id); setreason(true) }} />
                                                    </h6>
                                                </div>
                                            ) : (
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>End Date</h6>
                                                    <h6 className="col-8 font-weight-normal f-13 text-secondary">
                                                        {moment(x.EndDate).format('lll')}
                                                    </h6>
                                                </div>
                                            )
                                        }
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Assign to</h6>
                                            <h6 className='col-8 text-dark f-13 text-capitalize'> {x.Assign?.name}</h6>
                                        </div>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Created By</h6>
                                            <h6 className='col-8 font-weight-normal f-13 text-capitalize text-secondary'> {x.addedBy?.name}</h6>
                                        </div>
                                        {x.Reason ? (
                                            <div className="row mb-1 ">
                                                <h6 className="col-4 font-weight-normal f-13 text-secondary">Reason</h6>
                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-secondary">{x.Reason}</h6>
                                            </div>
                                        ) : null}
                                        {x.ProspectId?._id ? (
                                            <div className="d-flex row mb-1" onClick={() =>
                                                navigate('/Prospects', { state: { ProspectId: x.ProspectId?._id } })}>
                                                <h6 className="col-4 font-weight-normal f-13 text-secondary">Task Comapny</h6>
                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-secondary">{x.ProspectId?.Company} </h6>
                                            </div>
                                        ) : " "
                                        }
                                        {x.LeadId?._id ? (
                                            <div className="d-flex row mb-1" onClick={() => navigate('/Leads', { state: { leadId: x.LeadId?._id } })}>
                                                <h6 className="col-4 font-weight-normal f-13 text-secondary">Task Comapny</h6>
                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-secondary">{x.LeadId?.Company} </h6>
                                            </div>
                                        ) : " "
                                        }
                                    </div>
                                    <div className="col-4">
                                        {JSON.parse(window.localStorage.getItem('RoleName')) == "SuperAdmin" || JSON.parse(window.localStorage.getItem('RoleName')) == "Admin" ?
                                            <div className="d-flex">
                                                <span className="ml-auto">
                                                    <i className={x.LastComment == null ? 'fa fa-comment-o btn btn-light btn-sm' : 'fa fa-comment-o btn btn-danger btn-sm'} onClick={() => { taskcommit(x._id); settask(x._id); setcomment(true) }} />
                                                    <Link to={`/TaskAdd/${x._id}`}>
                                                        <i className='btn btn-sm btn-success fa fa-pencil ml-2'/>
                                                    </Link>                                                    
                                                    <i className='btn btn-danger btn-sm fa fa-trash ml-2' onClick={() => removetask(x._id)} /></span>
                                            </div> : moment(x.EndDate).isBefore(moment()) ? "" : <div className="d-flex">
                                                <span className="ml-auto">
                                                    <i className={x.LastComment == null ? 'fa fa-comment-o btn btn-light btn-sm' : 'fa fa-comment-o btn btn-danger btn-sm'} onClick={() => { taskcommit(x._id); settask(x._id); setcomment(true) }} />
                                                    <Link to={`/TaskAdd/${x._id}`} >
                                                        <i className='btn btn-sm btn-success fa fa-pencil ml-2'/>
                                                   </Link>
                                                    <i className='btn btn-danger btn-sm fa fa-trash ml-2' onClick={() => removetask(x._id)} /></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 pl-3">
                    <div className='row my-2'>
                        <div title="Tasks for Me" className="mb-3 pl-0"><h4> Outbox </h4></div>
                        {outbox.map((x, i) =>
                            <div className='boxShadow mb-3 p-3'>
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="card-title f-16 mb-2 text-capitalize">{x.Name}</h6>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Status </h6>
                                            <h6 className='col-8 f-13 text-capitalize' style={{ color: x.Status?.Color }}> {x.Status?.Name}</h6>
                                        </div>
                                        {
                                            x.EndDate === null ? " " : moment(x.EndDate).isBefore(moment()) && x.Reason ?
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>End Date</h6>
                                                    <h6 className='col-8 text-danger f-13'>{moment(x.EndDate).format('lll')}</h6>
                                                </div>
                                                :
                                                moment(x.EndDate).isBefore(moment()) ?
                                                    <div className='row mb-1'>
                                                        <h6 className='col-4 font-weight-normal f-13 text-secondary'>End Date</h6>
                                                        <h6 className='col-8 text-danger f-13'>{moment(x.EndDate).format('lll')}<i className="fa fa-clock-o btn btn-light btn-sm ml-1" title="add late reason" onClick={() => { settask(x._id); setreason(true) }} /></h6>
                                                    </div>
                                                    :
                                                    <div className='row mb-1'>
                                                        <h6 className='col-4 font-weight-normal f-13 text-secondary'>End Date</h6>
                                                        <h6 className='col-8 font-weight-normal f-13 text-secondary'> {moment(x.EndDate).format('lll')}</h6>
                                                    </div>
                                        }
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Assign to</h6>
                                            <h6 className='col-8 f-13 text-dark text-capitalize'> {x.Assign?.name}</h6>
                                        </div>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Created By</h6>
                                            <h6 className='col-8 font-weight-normal f-13 text-capitalize text-secondary'> {x.addedBy?.name}</h6>
                                        </div>
                                        {x.Reason ? (
                                            <div className="row mb-1">
                                                <h6 className="col-4 font-weight-normal f-13 text-secondary">Reason</h6>
                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-secondary">{x.Reason}</h6>
                                            </div>
                                        ) : null
                                        }
                                        {x.ProspectId?._id ? (
                                            <div className='row mb-1' onClick={() => navigate('/Prospects', { state: { ProspectId: x.ProspectId?._id } })}>
                                                <h6 className="col-4 font-weight-normal f-13 text-secondary">Task Comapny </h6>
                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-secondary">{x.ProspectId?.Company} </h6>
                                            </div>
                                        ) : " "
                                        }
                                        {x.LeadId?._id ? (
                                            <div className='row mb-1' onClick={() => navigate('/Leads', { state: { leadId: x.LeadId?._id } })}>
                                                <h6 className="col-4 font-weight-normal f-13 text-secondary">Task Comapny</h6>
                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-secondary">{x.LeadId?.Company}</h6>
                                            </div>
                                        ) : " "
                                        }
                                    </div>
                                    <div className="col-4">
                                        {JSON.parse(window.localStorage.getItem('RoleName')) == "SuperAdmin" || JSON.parse(window.localStorage.getItem('RoleName')) == "Admin" ?
                                            <div className="d-flex">
                                                <span className="ml-auto">
                                                    <i className={x.LastComment == null ? 'fa fa-comment-o btn btn-light btn-sm' : 'fa fa-comment-o btn btn-danger btn-sm'} onClick={() => { taskcommit(x._id); settask(x._id); setcomment(true) }} />
                                                    <Link to={`/TaskAdd/${x._id}`} >
                                                        <i className='btn btn-sm btn-success fa fa-pencil ml-2'></i>
                                                    </Link>
                                                    <i className='btn btn-danger btn-sm fa fa-trash ml-2' onClick={() => removetask(x._id)} /></span>
                                            </div> : moment(x.EndDate).isBefore(moment()) ? "" : <div className="d-flex">
                                                <span className="ml-auto">
                                                    <i className={x.LastComment == null ? 'fa fa-comment-o btn btn-light btn-sm' : 'fa fa-comment-o btn btn-danger btn-sm'} onClick={() => { taskcommit(x._id); settask(x._id); setcomment(true) }} />
                                                    <Link to={`/TaskAdd/${x._id}`} >
                                                        <i className='btn btn-sm btn-success fa fa-pencil ml-2'></i>
                                                    </Link>
                                                    <i className='btn btn-danger btn-sm fa fa-trash ml-2' onClick={() => removetask(x._id)} /></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>            
        </div>
    );
}
export default NormalTasks;
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import {
    CModal,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
} from '@coreui/react';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get, FileUpload } from '../../Layout/ajax';
// import { CModal } from '@coreui/react';
import * as Yup from "yup";
import { immediateToast } from "izitoast-react";
import moment from "moment";
import { Link, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import Select from "react-select";
import { UserModal } from './Modal.js';

const validation = Yup.object().shape({
    name: Yup.string().required(),
    executive: Yup.string().required(),
    startDate: Yup.string().required(),
    dueDate: Yup.string().required(),
    // note: Yup.string().required(),
});
// const subvalidation = Yup.object().shape({
//     name: Yup.string().required(),
//     executive: Yup.string().required(),
//     startDate: Yup.string().required(),
//     dueDate: Yup.string().required(),
//     note: Yup.string().required(),
// });
const Dailyvalidation = Yup.object().shape({
    Date: Yup.string().required(),
    note: Yup.string().required(),
});
const validationprocesstask = Yup.object().shape({
    Name: Yup.string().required(),
    Priority: Yup.string().required(),
    Status: Yup.string().required(),
});
const Process = () => {

    const $ = window.$;
    const [initValue, setinitValue] = useState({
        name: "",
        executive: "",
        startDate: "",
        dueDate: "",
        note: "",
    });
    // const [initsubValue, setinitsubValue] = useState({
    //     name: "",
    //     executive: "",
    //     startDate: "",
    //     dueDate: "",
    //     note: "",
    // });
    const [initDailyValue, setinitDailyValue] = useState({
        progress: 0,
        Date: "",
        note: "",
    });
    const [activeTab, setActiveTab] = useState("addtask");
    const [data, setData] = useState([]);
    const [executive, setexecutive] = useState([]);
    const [dailydata, setdailydata] = useState([]);
    const [processadd, setprocessadd] = useState(false);
    // const [Dailyadd, setDailyadd] = useState(false);
    // const [subprocessadd, setsubprocessadd] = useState(false);
    const [useradd, setuseradd] = useState(false);
    // const [subprocess, setsubprocess] = useState(0);
    const [process, setprocess] = useState(0);
    const [lastprogress, setlastprogress] = useState(0);
    const [userval, setuserval] = useState(0);
    const { id } = useParams();
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const [Project, setProject] = useState([]);
    const [Process, setProcess] = useState([]);
    useEffect(() => {
        getpost();
        getuserpost();
        getstatuspost();
        Post(`contract/getAllContract`, { active: true })
            .then((res) => {
                setProject(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        getAllProcess();
    }, []);
    const [users, setusers] = useState([]);
    const getuserpost = async () => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setexecutive(res);
                setusers(res);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getpost = async () => {
        await Post(`contract/getAllProcess`, { contractId: id })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const [expandedRowId, setExpandedRowId] = useState(null);
    const [currentProcessData, setCurrentProcessData] = useState(null);
    const get = async (val) => {
        if (val != 0) {
            Post(`contract/getProcessById`, { processId: val })
                .then((res) => {
                    setinitValue({
                        name: res.data.ProcessList[0].Name,
                        executive: res.data.ProcessList[0].executive,
                        startDate: moment(res.data.ProcessList[0].startDate).format("YYYY-MM-DD"),
                        dueDate: moment(res.data.ProcessList[0].dueDate).format("YYYY-MM-DD"),
                        note: res.data.ProcessList[0].note,
                    });
                    setdatanewtask(res.data.tasklist);
                    // setprocessadd(true);
                    setCurrentProcessData(res.data);
                    setprocess(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    // const subget = async (val) => {
    //     if (val != 0) {
    //         Post(`contract/getSubProcessById`, { subProcessId: val })
    //             .then((res) => {
    //                 setinitsubValue({
    //                     name: res.data.ProcessList[0].Name,
    //                     executive: res.data.ProcessList[0].executive,
    //                     startDate: moment(res.data.ProcessList[0].startDate).format("YYYY-MM-DD"),
    //                     dueDate: moment(res.data.ProcessList[0].dueDate).format("YYYY-MM-DD"),
    //                     note: res.data.ProcessList[0].note,
    //                 });
    //                 setdatanewtask(res.data.tasklist);                   
    //                 // setsubprocessadd(true);
    //                 setsubprocess(val);                    
    //             })
    //             .catch((err) => {
    //                 showTost("SOMETHING WENT WRONG ON SERVER", "error");
    //             });
    //     }
    // }
    const clear = () => {
        setinitValue({
            name: "",
            executive: "",
            startDate: "",
            dueDate: "",
            note: "",
        });
    }
    const handleSubmit = async (val) => {
        val.contractId = id;
        if (process == 0) {
            await Post(`contract/addProcess`, val)
                .then((res) => {
                    if (res.success == true) {
                        clear();
                        getpost();
                        setprocessadd(false);
                        showTost("Your data has been Successfully added", "success");
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
        else {
            val.id = process;
            await Post(`contract/editProcess`, val)
                .then((res) => {
                    if (res.success == true) {
                        clear();
                        getpost();
                        setprocessadd(false);
                        showTost("Your data has been successfully updated", "success");
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    // const subclear = () => {
    //     setinitsubValue({
    //         name: "",
    //         executive: "",
    //         startDate: "",
    //         dueDate: "",
    //         note: "",
    //     });
    // }
    // const handlesubSubmit = async (val) => {
    //     val.processId = process;
    //     if (subprocess == 0) {
    //         await Post(`contract/addSubProcess`, val)
    //             .then((res) => {
    //                 if (res.success == true) {
    //                     subclear();
    //                     getpost();
    //                     setsubprocessadd(false);
    //                     showTost("Your data has been Successfully added", "success");
    //                 }
    //                 else {
    //                     showTost(res.message, "warning");
    //                 }
    //             })
    //             .catch((err) => {
    //                 showTost("SOMETHING WENT WRONG ON SERVER", "error");
    //             });
    //     }
    //     else {
    //         val.id = subprocess;
    //         await Post(`contract/editSubProcess`, val)
    //             .then((res) => {
    //                 if (res.success == true) {
    //                     subclear();
    //                     getpost();
    //                     setsubprocessadd(false);
    //                     showTost("Your data has been successfully updated", "success");
    //                 }
    //                 else {
    //                     showTost(res.message, "warning");
    //                 }
    //             })
    //             .catch((err) => {
    //                 showTost("SOMETHING WENT WRONG ON SERVER", "error");
    //             });
    //     }
    // };
    const getdailypost = async (val) => {
        try {
            await Get(`contract/getAllDailyStatus/${val}`)
            .then((res) => {
                setdailydata(res.data);
                if (res.data.length != 0) {
                    setlastprogress(res.data[0].progress);
                }
                // setdatanewtask(res.data[0].addedBy);
                // setTaskName(res.data[0].addedBy.name);                      
                // setDailyadd(true);
                    else {               
                        showTost("No daily status data", "info");
                        setdailydata([]);
                        setlastprogress(0);
                    }
            })
        } catch(err){
            showTost("SOMETHING WENT WRONG ON SERVER", "error");
        }
    }
    const Dailyclear = () => {
        setinitDailyValue({
            progress: 0,
            Date: "",
            note: "",
        });
    }

    const handleDailySubmit = async (val) => {
        // val.subProcessId = subprocess;
        val.taskId = task;
        await Post(`contract/addDailyStatus`, val)
            .then((res) => {
                if (res.success == true) {
                    getpost();
                    getdailypost(task);
                    Dailyclear();
                    setFormKey(Math.random() * 1000000);
                    // setDailyadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    // task functionality
    const [addtask, setaddtask] = useState(false);
    const [task, settask] = useState(0);
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [initValueProcessTask, setInitValueProcessTask] = useState({
        id: "",
        Name: "",
        Priority: "",
        Assign: "",
        Status: "",
        Description: "",
        StartDate: null,
        EndDate: null,
        Reporter: [],
        ProcessId: "",
        ProjectId: ""
    });
    const clearaddtask = () => {
        setInitValueProcessTask({
            Name: "",
            Priority: "",
            Assign: "",
            Status: "",
            Description: "",
            StartDate: null,
            EndDate: null,
            Reporter: [],
            ProcessId: "",
            ProjectId: ""
        });
    }
    const [Status, setStatus] = useState([]);
    const getstatuspost = async (val) => {
        await Post(`master/getStatus`, { active: true })
            .then((res) => {
                setStatus(res.data?.filter(f => f.GroupName == "Tasks"))
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }

    const handleSubmitprocesstask = async (val) => {
        // if (subprocess) {
        // val.SubProcessId = subprocess;
        // } else {
        val.ProcessId = process;
        // }
        await Post(`task/add`, val)
            .then((res) => {
                if (res.success == true) {
                    clearaddtask();
                    setFormKey(Math.random() * 1000000);
                    // setaddtask(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            // if (subprocess) {
                            //     subget(subprocess);
                            // } else {
                            get(process);
                            // }
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getbyid = async (val) => {
        if (val != 0) {
            Get(`task/getById/${val}`)
                .then((res) => {
                    setInitValueProcessTask({
                        ProjectId: res.data.ProjectId,
                        ProcessId: res.data.ProcessId,
                        id: res.data._id,
                        Name: res.data.Name,
                        Priority: res.data.Priority,
                        Description: res.data.Description,
                        Assign: res.data.Assign,
                        Status: res.data.Status,
                        StartDate: moment(res.data.StartDate).format("YYYY-MM-DDTHH:mm"),
                        EndDate: moment(res.data.EndDate).format("YYYY-MM-DDTHH:mm"),
                        Reporter: res.data.Reporter
                    });
                    setaddtask(true);
                    settask(val);
                    setFormKey(Math.random() * 1000000);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const handleEditprocesstask = async (val) => {
        val.id = task;
        await Post(`task/edit`, val)
            .then((res) => {
                if (res.success == true) {
                    clearaddtask();
                    // setaddtask(false);
                    setFormKey(Math.random() * 1000000);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            // if (subprocess) {
                            //     subget(subprocess);
                            // } else {
                            get(process);
                            // }
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const changestatus = (detvalues) => {
        let values = { ...detvalues };
        let name = Status.filter(f => f._id == values.Status)
        values.Assign = name[0]?.Assign;
        setinitValue(values);
    }
    const [datanewtask, setdatanewtask] = useState([]);
    const removetask = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Post(
                    `task/remove/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            // if (subprocess) {
                            //     subget(subprocess);
                            // } else {
                            get(process);
                            // }
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const getAllProcess = async (projectId) => {
        await Post(`contract/getAllProcess`, { contractId: projectId })
            .then((res) => {
                setProcess(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const handleProjectChange = (event, setFieldValue) => {
        const projectId = event.target.value;
        setFieldValue("ProjectId", projectId);
        // getAllProcess(projectId);
        if (projectId) {
            getAllProcess(projectId);
        } else {
            setProcess([]);
        }
    }
    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    useEffect(() => {
        if (expandedRowId) {
            get(expandedRowId);
        }
    }, [expandedRowId]);
    return (
        <div className='content-wrapper'>
                <div className="my-2 row align-items-center">
                    <div className="col-10 d-flex align-items-center">
                        <Link to={'/Projects'}><i className="fa fa-arrow-left text-dark" /></Link>
                        <h4 className='ml-2'>Project Process</h4>
                    </div>
                    <div className="col-2 mt-2">
                        <span className='btn btn-sm btn-primary float-right' onClick={() => { setprocessadd(true); setprocess(0); }}>
                            <i className="fa fa-plus mr-2" /> Add Process
                        </span>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Executive</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">Due Date</th>
                                <th scope="col">Progress (%)</th>
                                <th scope="col">Note</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((x, i) => <>
                                <tr className='bg-light'>
                                    <td>{x.Name}</td>
                                    <td>{x.executive?.name}</td>
                                    <td>{moment(x.startDate).format("DD-MMM")}</td>
                                    <td>{moment(x.dueDate).format("DD-MMM")}</td>
                                    <td>
                                        <p className='m-0'>{x.progress}% </p>
                                        <div className="progress" style={{ height: '5px', width: "80px" }}>
                                            <div className="bg-primary progress-bar-striped" role="progressbar" aria-valuenow={x.progress} aria-valuemin={0} aria-valuemax={100} style={{ width: `${x.progress}%` }} />
                                        </div>
                                    </td>
                                    <td>{x.note}</td>
                                    <td className='input-group'>
                                        <i className='btn btn-sm btn-success fa fa-pencil mr-2' onClick={() => { get(x._id); setprocessadd(true); }} />
                                        <span className='btn btn-sm btn-primary mr-2' onClick={() => { get(x._id); setaddtask(true); settask(0); setprocess(x._id); }}>
                                            Task
                                        </span>
                                        {/* <span className='btn btn-sm btn-primary mr-2' onClick={() => { setsubprocessadd(true); setsubprocess(0); setprocess(x._id); }}>
                                            Add SubProcess
                                        </span> */}
                                        {/* <div className={`hide${x._id}`}>
                                            <span className="icon icon-plus mt-1 fa fa-plus" onClick={() => { 
                                                $(`.hide${x._id}`).hide(); 
                                                $(`.show${x._id}`).show(); 
                                                setprocess(x._id);
                                                get(x._id) }} />
                                        </div> */}
                                        {/* <span
                            className="icon icon-plus mt-1 fa fa-plus"
                            onClick={() => {
                                if (expandedRowId === x._id) {
                                    setExpandedRowId(null); 
                                } else {
                                    setExpandedRowId(x._id); 
                                    get(x._id);  
                                }
                            }}
                        /> */}

                                        {expandedRowId === x._id ? (
                                            <span
                                                className="icon icon-minus mt-1 fa fa-minus"
                                                onClick={() => setExpandedRowId(null)} 
                                            />
                                        ) : (
                                            <span
                                                className="icon icon-plus mt-1 fa fa-plus"
                                                onClick={() => setExpandedRowId(x._id)}
                                            />
                                        )}


                                        {/* <div className={`show${x._id}`} style={{ display: "none" }}>
                                            <span className="icon icon-minus mt-1 fa fa-minus" onClick={() => { $(`.hide${x._id}`).show(); $(`.show${x._id}`).hide(); }} />
                                        </div> */}
                                    </td>
                                </tr>
                                {/* {datanewtask && (
                                    <tr className={`show${x._id}`} style={{ display: "none" }}>
                                        <td colSpan="3">
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Task Name</th>
                                                <th>Assigned To</th>
                                                <th>Status</th>
                                                <th>End Date</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {datanewtask.map((task) => (
                                                <tr key={task._id} className={`show${x._id}`} >
                                                <td>{task.Name}</td>
                                                <td>{task.Assign?.name}</td>
                                                <td>{task.Status?.Name}</td>
                                                <td>{moment(task.EndDate).format('LL')}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        </td>
                                    </tr>
                                )} */}
                                {expandedRowId === x._id && currentProcessData && (
                                    <tr>
                                        <td colSpan="8">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Task Name</th>
                                                        <th>Assigned To</th>
                                                        <th>Status</th>
                                                        <th>End Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentProcessData.tasklist.map((task) => (
                                                        <tr key={task._id}>
                                                            <td>{task.Name}</td>
                                                            <td>{task.Assign?.name}</td>
                                                            <td>{task.Status?.Name}</td>
                                                            <td><b className='ml-2 text-danger'>{moment(task.EndDate).format('lll')}</b></td>
                                                            <td>
                                                                <i className='btn btn-sm btn-success fa fa-pencil-square-o pt-2 mr-2' onClick={() => { getbyid(task._id); getdailypost(task._id);}} />
                                                                <i className='btn btn-danger btn-sm fa fa-trash' onClick={() => removetask(task._id)} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                )}
                                {/* {x?.subProcess.map((f, i) =>
                                    <tr className={`show${x._id}`} style={{ display: "none" }}>
                                        <td>{f.Name}</td>
                                        <td>{f.executive?.name}</td>
                                        <td>{moment(f.startDate).format("DD-MMM")}</td>
                                        <td>{moment(f.dueDate).format("DD-MMM")}</td>
                                        <td><p className='m-0'>{f.progress}% </p>
                                            <div className="progress" style={{ height: '5px', width: "80px" }}>
                                                <div className="bg-primary progress-bar-striped" role="progressbar" aria-valuenow={f.progress} aria-valuemin={0} aria-valuemax={100} style={{ width: `${f.progress}%` }} />
                                            </div>
                                        </td>
                                        <td>{f.note}</td>
                                        <td>
                                            <i className='btn btn-sm btn-success fa fa-pencil mr-2' onClick={() => { subget(f._id); setsubprocessadd(true); }} />
                                            <span className='btn btn-sm btn-primary mr-2' onClick={() => { setaddtask(true); settask(0); setsubprocess(f._id); subget(f._id);}}>
                                                Task
                                            </span>
                                            <span className='btn btn-sm btn-primary' onClick={() => { getdailypost(f._id); }}>Daily Satus</span>
                                        </td>
                                    </tr>
                                )} */}
                            </>
                            )}
                        </tbody>
                    </table>
                </div>
            {/* <CModal alignment="center" size="lg" visible={addtask}>
                <div className="modal-header">
                    <h4 className="modal-title">{task == 0 ? "Add Task" : "Update Task"}</h4>
                    <button type="button" className="close" onClick={() => { clearaddtask(); setaddtask(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={task == 0 ? handleSubmitprocesstask : handleEditprocesstask}
                        initialValues={initValueProcessTask}
                        validationSchema={validationprocesstask}
                        key={formkey}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className='col-12 mt-2'>
                                                <label>Name</label>
                                                <input type='text' name="Name" defaultValue={values.Name} placeholder='Enter Task' className={!!touched.Name && !!errors.Name ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Status</label>
                                                <select name="Status" defaultValue={values.Status} onChange={handleChange} onBlur={() => { changestatus(values) }} className={!!touched.Status && !!errors.Status ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Status</option>
                                                    {Status
                                                        .map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Assign to :</label>
                                                <select name="Assign" defaultValue={values.Assign} className={!!touched.Assign && !!errors.Assign ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Assign</option>
                                                    {users
                                                        .map((x, i) => (
                                                            <option key={i} value={x._id}>{x.name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Priority</label>
                                                <select name="Priority" defaultValue={values.Priority} className={!!touched.Priority && !!errors.Priority ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Priority</option>
                                                    <option>High</option>
                                                    <option>Normal</option>
                                                    <option>Low</option>
                                                </select>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Start Date</label>
                                                <input type="datetime-local" placeholder="Date" defaultValue={values.StartDate} name='StartDate' className={!!touched.StartDate && !!errors.StartDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>End Date</label>
                                                <input type="datetime-local" placeholder="Date" defaultValue={values.EndDate} name='EndDate' className={!!touched.EndDate && !!errors.EndDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Reporter</label>
                                                <Select
                                                    isMulti
                                                    name="Reporter"
                                                    value={
                                                        users.filter(
                                                            (x) => {
                                                                if (values.Reporter && values.Reporter.length) {
                                                                    return values.Reporter.indexOf(x._id) != -1;
                                                                } else {
                                                                    return values.Reporter == x._id;
                                                                }
                                                            }
                                                        )
                                                            .map((x) => ({
                                                                value: x._id,
                                                                label: x.name,
                                                            }))}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "Reporter",
                                                            e.map((x) => x.value)
                                                        )
                                                    }
                                                    options={users.map((x) => ({
                                                        value: x._id,
                                                        label: x.name,
                                                    }))}
                                                    className={touched.Reporter && errors.Reporter ? "is-invalid" : ""}
                                                />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Project Name</label>
                                                <select name="ProjectId" onChange={(e) => handleProjectChange(e, setFieldValue)} defaultValue={values.ProjectId} className={!!touched.ProjectId && !!errors.ProjectId ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Project</option>
                                                    {Project
                                                        .map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Process Name</label>
                                                <select name="ProcessId" defaultValue={values.ProcessId} className={!!touched.ProcessId && !!errors.ProcessId ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Project</option>
                                                    {
                                                        Process
                                                            // .filter((x) => x.ProjectId?._id == values.ProjectId)
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.Name}</option>
                                                            ))}
                                                </select>
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label>Description</label>
                                                <textarea type='text' rows="3" defaultValue={values.Description} name="Description" placeholder='Enter Description' className={!!touched.Description && !!errors.Description ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <button type="submit" className='btn font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                    <div className="border rounded mt-3 p-2 mx-3">
                        <div className="text-primary"><span>Tasks</span></div>
                        {datanewtask?.map((x) => (
                            <div className='card m-2 p-2' key={x._id}>
                                <div className='row m-0'>
                                    <div className='col-md-6'>
                                        <div className='icnClr f-13'>Task Name <b className='ml-2 text-dark text-capitalize'>{x.Name}</b></div>
                                    </div>
                                    <div className='col-md-6 d-flex flex-wrap justify-content-end align-items-center'>
                                        <div className='mr-2 icnClr f-13'>End Date <b className='ml-2 text-danger'>{moment(x.EndDate).format('lll')}</b></div>
                                        <i className='btn btn-sm btn-success fa fa-pencil-square-o pt-2 mr-2' onClick={() => { getbyid(x._id); getdailypost(x._id); }} />
                                        <i className='btn btn-danger btn-sm fa fa-trash' onClick={() => removetask(x._id)} />
                                    </div>
                                </div>
                                <div className='row m-0'>
                                    <div className='col-md-12 d-flex align-items-center flex-wrap'>
                                        <div className='d-flex align-items-center'>
                                            <p className='icnClr f-13 mt-0'>Assign</p>
                                            <p className='text-dark f-13 mt-0 font-weight-bold text-capitalize ml-2'>{x.Assign?.name}</p>
                                        </div>
                                        <div className='dot mx-2'></div>
                                        <div className='d-flex align-items-center ml-1'>
                                            <p className='icnClr f-13 mt-0'>Status</p>
                                            <p className='text-dark f-13 mt-0 font-weight-bold ml-2 text-capitalize'>{x.Status?.Name}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {task == 0 ? "" : (
                        <>
                            <div className="text-dark my-3 ms-3"><h4>Daily Status</h4></div>
                            <Formik
                                onSubmit={handleDailySubmit}
                                initialValues={initDailyValue}
                                validationSchema={Dailyvalidation}
                                key={formkey}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    touched,
                                    errors,
                                    setFieldValue,
                                }) => {
                                    return (
                                        <Form
                                            onSubmit={handleSubmit}
                                            onChange={handleChange}>
                                            <div className="col-12 mb-2">
                                                <div className="row">
                                                    <div className="col-6 mt-2">
                                                        <label><i className="fa fa-calendar text-warning mr-2" />Date</label>
                                                        <input type="date" placeholder="Start Date" defaultValue={values.Date} name='Date' className={!!touched.Date && !!errors.Date ? "form-control is-invalid" : "form-control"} />
                                                    </div>
                                                    <div className='mt-2 col-6'>
                                                        <label>Progress ( {values.progress == 0 ? lastprogress : values.progress} %)</label>
                                                        <input type='range' name="progress" step='2' defaultValue={lastprogress} className={!!touched.progress && !!errors.progress ? "w-100 is-invalid mt-2" : "w-100 mt-2"} />
                                                    </div>
                                                    <div className='mt-2 col-12'>
                                                        <label>Note</label>
                                                        <textarea type='text' rows="3" defaultValue={values.note} name="note" placeholder='Enter Note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                                    </div>
                                                    <div className='mt-2 col-12'>
                                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                                    </div>
                                                </div>
                                            </div>                                       
                                            <div className="table-responsive mt-3">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Date</th>
                                                            <th scope="col">Progress (%)</th>
                                                            <th scope="col">Note</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dailydata.length > 0 ? (
                                                            dailydata.map((x) => (
                                                                <tr key={x._id} className='bg-light'>
                                                                    <td>{moment(x.Date).format("DD-MMM-YYYY")}</td>
                                                                    <td>
                                                                        <p className='m-0'>{x.progress}% </p>
                                                                        <div className="progress" style={{ height: '5px', width: "80px" }}>
                                                                            <div className="bg-primary progress-bar-striped" role="progressbar" style={{ width: `${x.progress}%` }} />
                                                                        </div>
                                                                    </td>
                                                                    <td>{x.note}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="3">No daily status updates available.</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </>
                    )}
                </div>
            </CModal>  */}
            <CModal alignment="center" size="lg" visible={addtask} onClose={() => setaddtask(false)}>
                <div className="modal-header">
                    <h4 className="modal-title">Process Details</h4>
                    <button type="button" className="close" onClick={() => setaddtask(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    {/* <CNav variant="tabs" role="tablist">
                        <CNavItem>
                            <CNavLink active={activeTab === "addtask"} onClick={() => toggleTab("addtask")}>
                                Add Task
                            </CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink active={activeTab === "tasklist"} onClick={() => toggleTab("tasklist")}>
                                Task List & Daily Status
                            </CNavLink>
                        </CNavItem>
                    </CNav>
                    <CTabContent>
                        <CTabPane visible={activeTab === "addtask"}> */}
                            <Formik
                                onSubmit={task == 0 ? handleSubmitprocesstask : handleEditprocesstask}
                                initialValues={initValueProcessTask}
                                validationSchema={validationprocesstask}
                                key={formkey}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    touched,
                                    errors,
                                    setFieldValue,
                                }) => {
                                    return (
                                        <Form
                                            onSubmit={handleSubmit}
                                            onChange={handleChange}>
                                            <div className="col-12 mb-2">
                                                <div className="row">
                                                    <div className='col-12 mt-2'>
                                                        <label>Name</label>
                                                        <input type='text' name="Name" defaultValue={values.Name} placeholder='Enter Task' className={!!touched.Name && !!errors.Name ? "form-control is-invalid" : "form-control"} />
                                                    </div>
                                                    <div className='col-6 mt-2'>
                                                        <label>Status</label>
                                                        <select name="Status" defaultValue={values.Status} onChange={handleChange} onBlur={() => { changestatus(values) }} className={!!touched.Status && !!errors.Status ? "form-select is-invalid" : "form-select"}>
                                                            <option value="">Select Status</option>
                                                            {Status
                                                                .map((x, i) => (
                                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className='col-6 mt-2'>
                                                        <label>Assign to :</label>
                                                        <select name="Assign" defaultValue={values.Assign} className={!!touched.Assign && !!errors.Assign ? "form-select is-invalid" : "form-select"}>
                                                            <option value="">Select Assign</option>
                                                            {users
                                                                .map((x, i) => (
                                                                    <option key={i} value={x._id}>{x.name}</option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className='col-6 mt-2'>
                                                        <label>Priority</label>
                                                        <select name="Priority" defaultValue={values.Priority} className={!!touched.Priority && !!errors.Priority ? "form-select is-invalid" : "form-select"}>
                                                            <option value="">Select Priority</option>
                                                            <option>High</option>
                                                            <option>Normal</option>
                                                            <option>Low</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-6 mt-2'>
                                                        <label>Start Date</label>
                                                        <input type="datetime-local" placeholder="Date" defaultValue={values.StartDate} name='StartDate' className={!!touched.StartDate && !!errors.StartDate ? "form-control is-invalid" : "form-control"} />
                                                    </div>
                                                    <div className='col-6 mt-2'>
                                                        <label>End Date</label>
                                                        <input type="datetime-local" placeholder="Date" defaultValue={values.EndDate} name='EndDate' className={!!touched.EndDate && !!errors.EndDate ? "form-control is-invalid" : "form-control"} />
                                                    </div>
                                                    <div className='col-6 mt-2'>
                                                        <label>Reporter</label>
                                                        <Select
                                                            isMulti
                                                            name="Reporter"
                                                            value={
                                                                users.filter(
                                                                    (x) => {
                                                                        if (values.Reporter && values.Reporter.length) {
                                                                            return values.Reporter.indexOf(x._id) != -1;
                                                                        } else {
                                                                            return values.Reporter == x._id;
                                                                        }
                                                                    }
                                                                )
                                                                    .map((x) => ({
                                                                        value: x._id,
                                                                        label: x.name,
                                                                    }))}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "Reporter",
                                                                    e.map((x) => x.value)
                                                                )
                                                            }
                                                            options={users.map((x) => ({
                                                                value: x._id,
                                                                label: x.name,
                                                            }))}
                                                            className={touched.Reporter && errors.Reporter ? "is-invalid" : ""}
                                                        />
                                                    </div>
                                                    <div className='col-6 mt-2'>
                                                        <label>Project Name</label>
                                                        <select name="ProjectId" onChange={(e) => handleProjectChange(e, setFieldValue)} defaultValue={values.ProjectId} className={!!touched.ProjectId && !!errors.ProjectId ? "form-select is-invalid" : "form-select"}>
                                                            <option value="">Select Project</option>
                                                            {Project
                                                                .map((x, i) => (
                                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className='col-6 mt-2'>
                                                        <label>Process Name</label>
                                                        <select name="ProcessId" defaultValue={values.ProcessId} className={!!touched.ProcessId && !!errors.ProcessId ? "form-select is-invalid" : "form-select"}>
                                                            <option value="">Select Project</option>
                                                            {
                                                                Process
                                                                    // .filter((x) => x.ProjectId?._id == values.ProjectId)
                                                                    .map((x, i) => (
                                                                        <option key={i} value={x._id}>{x.Name}</option>
                                                                    ))}
                                                        </select>
                                                    </div>
                                                    <div className='col-12 mt-2'>
                                                        <label>Description</label>
                                                        <textarea type='text' rows="3" defaultValue={values.Description} name="Description" placeholder='Enter Description' className={!!touched.Description && !!errors.Description ? "form-control is-invalid" : "form-control"} />
                                                    </div>
                                                    <div className='col-12 mt-2'>
                                                        <button type="submit" className='btn font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                            {task == 0 ? "" : (
                                <>
                                    <div className="text-dark my-3 ms-3"><h4>Daily Status</h4></div>
                                    <Formik
                                        onSubmit={handleDailySubmit}
                                        initialValues={initDailyValue}
                                        validationSchema={Dailyvalidation}
                                        key={formkey}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            values,
                                            touched,
                                            errors,
                                            setFieldValue,
                                        }) => {
                                            return (
                                                <Form
                                                    onSubmit={handleSubmit}
                                                    onChange={handleChange}>
                                                    <div className="col-12 mb-2">
                                                        <div className="row">
                                                            <div className="col-6 mt-2">
                                                                <label><i className="fa fa-calendar text-warning mr-2" />Date</label>
                                                                <input type="date" placeholder="Start Date" defaultValue={values.Date} name='Date' className={!!touched.Date && !!errors.Date ? "form-control is-invalid" : "form-control"} />
                                                            </div>
                                                            <div className='mt-2 col-6'>
                                                                <label>Progress ( {values.progress == 0 ? lastprogress : values.progress} %)</label>
                                                                <input type='range' name="progress" step='2' defaultValue={lastprogress} className={!!touched.progress && !!errors.progress ? "w-100 is-invalid mt-2" : "w-100 mt-2"} />
                                                            </div>
                                                            <div className='mt-2 col-12'>
                                                                <label>Note</label>
                                                                <textarea type='text' rows="3" defaultValue={values.note} name="note" placeholder='Enter Note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                                            </div>
                                                            <div className='mt-2 col-12'>
                                                                <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive mt-3">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    {/*  <th scope="col" className='text-dark'>Task Name</th>*/}
                                                                    <th scope="col">Date</th>
                                                                    <th scope="col">Progress (%)</th>
                                                                    <th scope="col">Note</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dailydata.length > 0 ? (
                                                                    dailydata?.map((x) => (
                                                                        <tr key={x._id} className='bg-light'>
                                                                            {/*  <td><p className='m-0'>{taskName} </p></td>*/}
                                                                            <td>{moment(x.Date).format("DD-MMM-YYYY")}</td>
                                                                            <td>
                                                                                <p className='m-0'>{x.progress}% </p>
                                                                                <div className="progress" style={{ height: '5px', width: "80px" }}>
                                                                                    <div className="bg-primary progress-bar-striped" role="progressbar" style={{ width: `${x.progress}%` }} />
                                                                                </div>
                                                                            </td>
                                                                            <td>{x.note}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="3">No daily status updates available.</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </>
                            )}
                         {/*</CTabPane>
                        <CTabPane visible={activeTab === "tasklist"}>
                            <div className="border rounded mt-3 p-2 mx-3">
                                <div className="text-primary"><span>Tasks</span></div>
                                {datanewtask?.map((x) => (
                                    <div className='card m-2 p-2' key={x._id}>
                                        <div className='row m-0'>
                                            <div className='col-md-6'>
                                                <div className='icnClr f-13'>Task Name <b className='ml-2 text-dark text-capitalize'>{x.Name}</b></div>
                                            </div>
                                            <div className='col-md-6 d-flex flex-wrap justify-content-end align-items-center'>
                                                <div className='mr-2 icnClr f-13'>End Date <b className='ml-2 text-danger'>{moment(x.EndDate).format('lll')}</b></div>
                                                <i className='btn btn-sm btn-success fa fa-pencil-square-o pt-2 mr-2' onClick={() => { getbyid(x._id); getdailypost(x._id); }} />
                                                <i className='btn btn-danger btn-sm fa fa-trash' onClick={() => removetask(x._id)} />
                                            </div>
                                        </div>
                                        <div className='row m-0'>
                                            <div className='col-md-12 d-flex align-items-center flex-wrap'>
                                                <div className='d-flex align-items-center'>
                                                    <p className='icnClr f-13 mt-0'>Assign</p>
                                                    <p className='text-dark f-13 mt-0 font-weight-bold text-capitalize ml-2'>{x.Assign?.name}</p>
                                                </div>
                                                <div className='dot mx-2'></div>
                                                <div className='d-flex align-items-center ml-1'>
                                                    <p className='icnClr f-13 mt-0'>Status</p>
                                                    <p className='text-dark f-13 mt-0 font-weight-bold ml-2 text-capitalize'>{x.Status?.Name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> */}
                            {/* <div className="text-dark my-3 ms-3"><h4>Daily Status</h4></div>
                            <Formik
                                onSubmit={handleDailySubmit}
                                initialValues={initDailyValue}
                                validationSchema={Dailyvalidation}
                                key={formkey}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => {
                                    return (
                                        <Form onSubmit={handleSubmit}>
                                            <div className="col-12 mb-2">
                                                <div className="row">
                                                    <div className="col-6 mt-2">
                                                        <label><i className="fa fa-calendar text-warning mr-2" />Date</label>
                                                        <input
                                                            type="date"
                                                            placeholder="Start Date"
                                                            defaultValue={values.Date}
                                                            name="Date"
                                                            className={!!touched.Date && !!errors.Date ? "form-control is-invalid" : "form-control"}
                                                        />
                                                    </div>
                                                    <div className="col-12 mt-2">
                                                        <button type="submit" className="btn btn-sm font-weight-bold btn-success">
                                                            <i className="fa fa-check" /> Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive mt-3">
                                                <table className="table">
                                                    <tbody>
                                                        {dailydata.length > 0 ? (
                                                            dailydata.map((x) => (
                                                                <tr key={x._id} className="bg-light">
                                                                    <td>{moment(x.Date).format("DD-MMM-YYYY")}</td>
                                                                    <td>
                                                                        <p className="m-0">{x.progress}%</p>
                                                                        <div className="progress" style={{ height: "5px", width: "80px" }}>
                                                                            <div className="bg-primary progress-bar-striped" role="progressbar" style={{ width: `${x.progress}%` }} />
                                                                        </div>
                                                                    </td>
                                                                    <td>{x.note}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="3">No daily status updates available.</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik> */}
                            {/* {task == 0 ? "" : (
                                <>
                                    <div className="text-dark my-3 ms-3"><h4>Daily Status</h4></div>
                                    <Formik
                                        onSubmit={handleDailySubmit}
                                        initialValues={initDailyValue}
                                        validationSchema={Dailyvalidation}
                                        key={formkey}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            values,
                                            touched,
                                            errors,
                                            setFieldValue,
                                        }) => {
                                            return (
                                                <Form
                                                    onSubmit={handleSubmit}
                                                    onChange={handleChange}>
                                                    <div className="col-12 mb-2">
                                                        <div className="row">
                                                            <div className="col-6 mt-2">
                                                                <label><i className="fa fa-calendar text-warning mr-2" />Date</label>
                                                                <input type="date" placeholder="Start Date" defaultValue={values.Date} name='Date' className={!!touched.Date && !!errors.Date ? "form-control is-invalid" : "form-control"} />
                                                            </div>
                                                            <div className='mt-2 col-6'>
                                                                <label>Progress ( {values.progress == 0 ? lastprogress : values.progress} %)</label>
                                                                <input type='range' name="progress" step='2' defaultValue={lastprogress} className={!!touched.progress && !!errors.progress ? "w-100 is-invalid mt-2" : "w-100 mt-2"} />
                                                            </div>
                                                            <div className='mt-2 col-12'>
                                                                <label>Note</label>
                                                                <textarea type='text' rows="3" defaultValue={values.note} name="note" placeholder='Enter Note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                                            </div>
                                                            <div className='mt-2 col-12'>
                                                                <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive mt-3">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Date</th>
                                                                    <th scope="col">Progress (%)</th>
                                                                    <th scope="col">Note</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dailydata.length > 0 ? (
                                                                    dailydata.map((x) => (
                                                                        <tr key={x._id} className='bg-light'>
                                                                            <td>{moment(x.Date).format("DD-MMM-YYYY")}</td>
                                                                            <td>
                                                                                <p className='m-0'>{x.progress}% </p>
                                                                                <div className="progress" style={{ height: '5px', width: "80px" }}>
                                                                                    <div className="bg-primary progress-bar-striped" role="progressbar" style={{ width: `${x.progress}%` }} />
                                                                                </div>
                                                                            </td>
                                                                            <td>{x.note}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="3">No daily status updates available.</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </>
                            )} */}
                        {/* </CTabPane>
                    </CTabContent> */}
                </div>
            </CModal>




            {/* <CModal alignment="center" size="lg" visible={subprocessadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{subprocess == 0 ? "Add SubProcess" : "Edit SubProcess"}</h4>
                    <button type="button" className="close" onClick={() => { subclear(); setsubprocessadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handlesubSubmit}
                        initialValues={initsubValue}
                        validationSchema={subvalidation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className='mt-2 col-6'>
                                                <label>Name</label>
                                                <input type='text' name="name" defaultValue={values.name} placeholder='Enter Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-6'>
                                                <label>Executive</label>
                                                <div className='input-group'>
                                                    <select defaultValue={values.executive} className={!!touched.executive && !!errors.executive ? "form-select is-invalid" : "form-select"} name='executive'>
                                                        <option value=''>Select Executive</option>
                                                        {executive
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.executive ? (
                                                            <i className='fa fa-pencil' onClick={() => { setuserval(values.executive); setuseradd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setuserval(0); setuseradd(true) }} />
                                                        )}
                                                        {useradd && (
                                                            <UserModal
                                                                useradd={useradd}
                                                                setuseradd={setuseradd}
                                                                getuserpost={getuserpost}
                                                                showTost={showTost}
                                                                userval={userval}
                                                                setuserval={setuserval}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Start Date</label>
                                                <input type="date" placeholder="Start Date" defaultValue={values.startDate} name='startDate' className={!!touched.startDate && !!errors.startDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Due Date</label>
                                                <input type="date" placeholder="Expiry Date" min={values.startDate} defaultValue={values.dueDate} name='dueDate' className={!!touched.dueDate && !!errors.dueDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <label>Note</label>
                                                <textarea type='text' rows="3" defaultValue={values.note} name="note" placeholder='Enter Note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal> */}
            <CModal alignment="center" size="lg" visible={processadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{process == 0 ? "Add Process" : "Edit Process"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setprocessadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className='mt-2 col-6'>
                                                <label>Name</label>
                                                <input type='text' name="name" defaultValue={values.name} placeholder='Enter Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-6'>
                                                <label>Executive</label>
                                                <div className='input-group'>
                                                    <select defaultValue={values.executive} className={!!touched.executive && !!errors.executive ? "form-select is-invalid" : "form-select"} name='executive'>
                                                        <option value=''>Select Executive</option>
                                                        {executive
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.executive ? (
                                                            <i className='fa fa-pencil' onClick={() => { setuserval(values.executive); setuseradd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setuserval(0); setuseradd(true) }} />
                                                        )}
                                                        {useradd && (
                                                            <UserModal
                                                                useradd={useradd}
                                                                setuseradd={setuseradd}
                                                                getuserpost={getuserpost}
                                                                showTost={showTost}
                                                                userval={userval}
                                                                setuserval={setuserval}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Start Date</label>
                                                <input type="date" placeholder="Start Date" defaultValue={values.startDate} name='startDate' className={!!touched.startDate && !!errors.startDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Due Date</label>
                                                <input type="date" placeholder="Expiry Date" min={values.startDate} defaultValue={values.dueDate} name='dueDate' className={!!touched.dueDate && !!errors.dueDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <label>Note</label>
                                                <textarea type='text' rows="3" defaultValue={values.note} name="note" placeholder='Enter Note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
            {/* <CModal alignment="center" size="lg" visible={Dailyadd}>
                <div className="modal-header">
                    <h4 className="modal-title">Add Daily Status</h4>
                    <button type="button" className="close" onClick={() => { Dailyclear(); setDailyadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handleDailySubmit}
                        initialValues={initDailyValue}
                        validationSchema={Dailyvalidation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Date</label>
                                                <input type="date" placeholder="Start Date" defaultValue={values.Date} name='Date' className={!!touched.Date && !!errors.Date ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <label>Progress ( {values.progress == 0 ? lastprogress : values.progress} %)</label>
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <input type='range' name="progress" step='2' defaultValue={lastprogress} className={!!touched.progress && !!errors.progress ? "w-100 is-invalid" : "w-100"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <label>Note</label>
                                                <textarea type='text' rows="3" defaultValue={values.note} name="note" placeholder='Enter Note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Progress (%)</th>
                                                    <th scope="col">Note</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dailydata.map((x, i) =>
                                                    <tr className='bg-light'>
                                                        <td>{moment(x.Date).format("DD-MMM-YYYY")}</td>
                                                        <td>
                                                            <p className='m-0'>{x.progress}% </p>
                                                            <div className="progress" style={{ height: '5px', width: "80px" }}>
                                                                <div className="bg-primary progress-bar-striped" role="progressbar" aria-valuenow={x.progress} aria-valuemin={0} aria-valuemax={100} style={{ width: `${x.progress}%` }} />
                                                            </div>
                                                        </td>
                                                        <td>{x.note}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal> */}
        </div >
    );
}

export default Process;
import React, { Fragment, useEffect, useState } from "react";
import { Bell } from "react-feather";
import { H6, LI, P, UL } from "../../AbstractElements";
import { Link } from "react-router-dom";
import { immediateToast } from "izitoast-react";
import { Post, Get } from '../../Layout/ajax';
import moment from "moment";

const BellCompo = () => {
  const [notification, setnotification] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [unreadCount, setUnreadCount] = useState(0);

  const showTost = (msg, theme) => {
    immediateToast(theme, {
      message: msg,
      timeout: 3000
    });
  }
  const getPost = async () => {
    try {
      let skipa = perPage * (pageNo - 1);
      const res = await Post(`notification/getNotification`, { "userId": JSON.parse(window.localStorage.getItem('Id')) });
      const allNotifications = res.data.data || [];
      const unreadNotifications = allNotifications.filter(n => !n.Isread);
      const latestNotifications = allNotifications.slice(0, 5);            
      const combinedNotifications = [...unreadNotifications.slice(0, 5)];     
      if (combinedNotifications.length < 5) {
        const remainingCount = 5 - combinedNotifications.length;
        const latestToAdd = latestNotifications.filter(n => !combinedNotifications.find(cn => cn._id === n._id)).slice(0, remainingCount);
        combinedNotifications.push(...latestToAdd);
      }
      setnotification(combinedNotifications);
      setUnreadCount(res.data.Count);
    } catch (err) {
      showTost("SOMETHING WENT WRONG ON SERVER", "error");
    }
  };
  useEffect(() => {
    getPost();
  }, [])
  const markread = async (val) => {
    Post(`notification/markasread`, { "id": val })
      .then((res) => {
        if (res.success == true) {
          getPost();
        }
      })
      .catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
      });
  }
  return (
    <Fragment>
      <LI className="onhover-dropdown position-relative">
        <Bell />
        {unreadCount === 0 ? " " : ( <div className="notificationlen">{unreadCount}</div> )}
        
        <UL className="chat-dropdown notification-dropdown onhover-show-div simple-list">
          <LI>
            <H6 className="f-w-600">Notifications</H6>
          </LI>
          {notification.map((x, i) => (
            <Link to={`/${x.link}`} key={i}>
              <LI
                className={x.Isread === false ? 'bg-unread' : ''}
                onClick={() => !x.Isread && markread(x._id)}
              >
                <div>
                  <div>
                    <span><i className="fa fa-calendar-o mr-2 text-primary"></i></span>
                    <span>{moment(x.date).format("DD-MMM-YYYY")}</span>
                  </div>
                  <div>
                    {x.description}
                  </div>
                </div>
              </LI>
            </Link>
          ))}
          <LI className="bg-light txt-dark">
            <Link to={'/Notification'}>All notification</Link>
          </LI>
        </UL>
      </LI>
    </Fragment>
  );
};

export default BellCompo;

import { Col, Container, Row, Card, CardBody } from "reactstrap";
import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get, FileUpload } from '../Layout/ajax';
import { immediateToast } from "izitoast-react";
import { Chart } from "react-google-charts";
import ReactApexChart from "react-apexcharts";

const Dashboard = () => {
    const [data, setdata] = useState({});

    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    useEffect(() => {
        getpost();
    }, []);
    const getpost = async (val) => {
        await Get(`dashboard/getDashboardCount`)
            .then((res) => {
                setdata(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 box-col-12">
                    <div className="col-xl-12 xl-100 col-md-12 box-col-12">
                        <div className="col-xl-12 xl-100 box-col-12">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="mt-4 mb-4">Dashboard</h2>
                                </div>
                                <div className="col-12">
                                    <h6 className="float-right">Last Update :- {moment(data?.user?.updatedAt).format("DD-MMM-YYYY, h:mm a")}</h6>
                                </div>
                                <div className="col-12">
                                    <div className="project-overview">
                                        <div className="row">
                                            <div className="col-md-2 col-sm-3 col-6 mb-2">
                                                <Link to={"/Leads"}>
                                                    <h2 className="f-w-600 counter font-primary">{data?.user?.Lead}</h2>
                                                    <p className="mb-0">Leads</p>
                                                </Link>
                                            </div>
                                            <div className="col-md-2 col-sm-3 col-6 mb-2">
                                                <Link to={"/Prospects"}>
                                                    <h2 className="f-w-600 counter font-secondary">{data?.user?.Prospect}</h2>
                                                    <p className="mb-0">Prospects</p>
                                                </Link>
                                            </div>
                                            <div className="col-md-2 col-sm-3 col-6 mb-2">
                                                <Link to={"/Quotes"}>
                                                    <h2 className="f-w-600 counter font-danger">{data?.user?.Quatation}</h2>
                                                    <p className="mb-0">Quatations</p>
                                                </Link>
                                            </div>
                                            <div className="col-md-2 col-sm-3 col-6 mb-2">
                                                <Link to={"/Orders"}>
                                                    <h2 className="f-w-600 counter font-danger">{data?.user?.Order}</h2>
                                                    <p className="mb-0">Orders</p>
                                                </Link>
                                            </div>
                                            <div className="col-md-2 col-sm-3 col-6 mb-2">
                                                <Link to={"/Invoices"}>
                                                    <h2 className="f-w-600 counter font-danger">{data?.user?.Invoice}</h2>
                                                    <p className="mb-0">Invoices</p>
                                                </Link>
                                            </div>
                                            <div className="col-md-2 col-sm-3 col-6 mb-2">
                                                <Link to={"/Recovery"}>
                                                    <h2 className="f-w-600 counter font-info">{data?.user?.Recovery}</h2>
                                                    <p className="mb-0">Recovery</p>
                                                </Link>
                                            </div>
                                            <div className="col-md-2 col-sm-3 col-6 mb-2">
                                                <Link to={"/Projects"}>
                                                    <h2 className="f-w-600 counter font-warning">{data?.user?.Project}</h2>
                                                    <p className="mb-0">Projects</p>
                                                </Link>
                                            </div>
                                            <div className="col-md-2 col-sm-3 col-6 mb-2">
                                                <Link to={"/Support"}>
                                                    <h2 className="f-w-600 counter font-success">{data?.user?.Support}</h2>
                                                    <p className="mb-0">Support</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="col-sm-12 box-col-12">
                            <div className="row overall-report">
                                <div className="col-xl-6 xl-50 col-md-6 box-col-6">
                                    <div className="card card-with-border">
                                        <div className="card-body">
                                            <div className="text-center">
                                                <Link to={"/Customer"} className="text-dark">
                                                    <h3 className="counter f-w-600">{data?.user?.Customer}</h3>
                                                    <p>Total Customer</p>
                                                    <div className="employees">
                                                        <i className="fa fa-female" />
                                                        <i className="fa fa-male" />
                                                        <i className="fa fa-female" />
                                                        <i className="fa fa-male" />
                                                        <i className="fa fa-female" />
                                                        <i className="fa fa-male light-icon" />
                                                        <i className="fa fa-female light-icon" />
                                                        <i className="fa fa-male light-icon" />
                                                        <i className="fa fa-female light-icon" />
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 xl-50 col-md-6 box-col-6">
                                    <div className="card card-with-border">
                                        <div className="card-body">
                                            <div className="text-center">
                                                <Link to={"/Product"} className="text-dark">
                                                    <h3 className="counter f-w-600">{data?.user?.Product}</h3>
                                                    <p>Total Products</p>
                                                    <div className="employees">
                                                        <i className="fa fa-cube" />
                                                        <i className="fa fa-cube" />
                                                        <i className="fa fa-cube" />
                                                        <i className="fa fa-cube" />
                                                        <i className="fa fa-cube" />
                                                        <i className="fa fa-cube" />
                                                        <i className="fa fa-cube light-icon" />
                                                        <i className="fa fa-cube light-icon" /></div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 box-col-12">
                            <div className="row">
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <Chart
                                                data={data?.leadCountArray}
                                                chartType={"Bar"}
                                                options={{
                                                    chart: {
                                                        title: "Lead",
                                                      },
                                                    hAxis: { title: 'Month', titleTextStyle: { color: '#333' } },
                                                    vAxis: { minValue: 0 },
                                                    width: '100%',
                                                    height: 400,
                                                    colors: ["#51bb25"]
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <Chart
                                                data={data?.prospectCountArray}
                                                chartType={"Bar"}
                                                options={{
                                                    chart: {
                                                        title: "Prospect",
                                                      },
                                                    hAxis: { title: 'Month', titleTextStyle: { color: '#333' } },
                                                    vAxis: { minValue: 0 },
                                                    width: '100%',
                                                    height: 400,
                                                    colors: ["#ff5f24"]
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <Chart
                                                data={data?.quatationCountArray}
                                                chartType={"Bar"}
                                                options={{
                                                    chart: {
                                                        title: "Quatation",
                                                      },
                                                    hAxis: { title: 'Month', titleTextStyle: { color: '#333' } },
                                                    vAxis: { minValue: 0 },
                                                    width: '100%',
                                                    height: 400,
                                                    colors: ["#148df6"]
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <Chart
                                                data={data?.orderCountArray}
                                                chartType={"Bar"}
                                                options={{
                                                    chart: {
                                                        title: "Order",
                                                      },
                                                    hAxis: { title: 'Month', titleTextStyle: { color: '#333' } },
                                                    vAxis: { minValue: 0 },
                                                    width: '100%',
                                                    height: 400,
                                                    colors: ["#fd2e64"]
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <Chart
                                                data={data?.invoiceCountArray}
                                                chartType={"Bar"}
                                                options={{
                                                    chart: {
                                                        title: "Invoice",
                                                      },
                                                    hAxis: { title: 'Month', titleTextStyle: { color: '#333' } },
                                                    vAxis: { minValue: 0 },
                                                    width: '100%',
                                                    height: 400,
                                                    colors: ["#655af3"]
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <ReactApexChart id="circlechart" options={{
                                                chart: {
                                                    height: 310,
                                                    type: 'radialBar',
                                                },
                                                plotOptions: {
                                                    radialBar: {
                                                        startAngle: -135,
                                                        endAngle: 135,
                                                        offsetY: 0,
                                                        hollow: {
                                                            size: '50%',
                                                        },
                                                        dataLabels: {
                                                            name: {
                                                                fontSize: '22px',
                                                            },
                                                            value: {
                                                                fontSize: '16px',
                                                            },
                                                            total: {
                                                                show: true,
                                                                label: 'Total',
                                                                formatter: function (w) {
                                                                    return "75"
                                                                }
                                                            }
                                                        }
                                                    }
                                                },
                                                fill: {
                                                    colors: ["#0d6efd", '#148df6', '#51bb25'],
                                                    type: 'gradient',
                                                    gradient: {
                                                        shade: 'light',
                                                        type: 'horizontal',
                                                        shadeIntensity: 0.2,
                                                        inverseColors: true,
                                                        opacityFrom: 1,
                                                        opacityTo: 1,
                                                        stops: [0, 100]
                                                    }
                                                },
                                                legend: {
                                                    show: true,
                                                    position: 'top',
                                                    horizontalAlign: 'left',
                                                    floating: false,
                                                    fontSize: '14px',
                                                    offsetX: 0,
                                                    offsetY: 0,
                                                },
                                                colors: ["#0d6efd", '#148df6', '#51bb25'],
                                                series: [60, 67, 100],
                                                labels: ['Completed', 'Assigned', 'Due'],
                                                stroke: {
                                                    lineCap: "round",
                                                }
                                            }} series={[60, 67, 100]} type="radialBar" height={400} />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </div >
    );
}

export default Dashboard;
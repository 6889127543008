import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import { immediateToast } from "izitoast-react";
import CKEditor from 'react-ckeditor-component';
import Swal from "sweetalert2";

const validation = Yup.object().shape({
    TemplateFor: Yup.string().required(),
    Name: Yup.string().required(),
    Detail: Yup.string().required(),
});

const Template = () => {
    const [initValue, setinitValue] = useState({
        TemplateFor: "",
        Name: "",
        Detail: "",
    });
    const [data, setData] = useState([]);
    const [templateadd, settemplateadd] = useState(false);
    const [template, settemplate] = useState(0);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    useEffect(() => {
        getpost(true);
    }, []);

    const getpost = async (val) => {
        await Post(`template/getAll`, { active: val })
            .then((res) => {
                setData(res);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const handleSubmit = async (val) => {
        await Post(`template/add`, val)
            .then((res) => {
                if (!res.message) {
                    clear();
                    settemplateadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleEdit = async (val) => {
        val.id = template;
        await Post(`template/update`, val)
            .then((res) => {
                if (!res.message) {
                    clear();
                    settemplateadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const clear = () => {
        setinitValue({
            TemplateFor: "",
            Name: "",
            Detail: "",
        });
    }
    const get = async (val) => {
        if (val != 0) {
            Get(`template/getById/${val}`)
                .then((res) => {
                    setinitValue({
                        Name: res.Name,
                        TemplateFor: res.TemplateFor,
                        Detail: res.Detail,
                    });
                    settemplateadd(true);
                    settemplate(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const changeStatus = async (val, status) => {
        await Post(`template/remove/${val}`, { active: status })
            .then((res) => {
                if (res.success == true) {
                    if (status == true) {
                        getpost(false);
                        showTost("Your data has been successfully activated", "success");
                    }
                    else {
                        getpost(true);
                        showTost("Your data has been successfully deactivated", "success");
                    }
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const Default = async (val) => {
        var valnew = {}
        valnew.id = val._id;
        valnew.type = val.TemplateFor;
        valnew.default = true;
        await Post(`template/setDefault`, valnew)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.msg, "warning");
                }
                else {
                    getpost(true);
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const deleteTemplate = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Get(
                    `template/deleteTemplate/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(true);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const columns = [
        {
            name: "Template For",
            selector: (row) => row.TemplateFor,
        },
        {
            name: "Name",
            selector: (row) => row.Name,
        },
        {
            name: "Default",
            selector: (row) => <input type='checkbox' checked={row.is_default == true ? "checked" : ""} disabled={row.is_active == true ? false : true} onClick={() => Default(row)} />,
        },
        {
            name: "Status",
            selector: (row) => <input type='checkbox' value={row.is_active} checked={row.is_active == true ? "checked" : ""} onClick={() => { row.is_active == true ? changeStatus(row._id, false) : changeStatus(row._id, true) }} />,
        },
        {
            name: "",
            selector: (row) => row.is_active == true ? <><i className='btn btn-sm btn-success fa fa-pencil mr-1' onClick={() => { get(row._id); }} /><i className='btn btn-sm btn-danger fa fa-trash' onClick={() => { deleteTemplate(row._id); }} /></> : "",
        }
    ];

    return (
        <div className='content-wrapper'>
            <div className="my-2 row">
                <div className="col-10">
                    <h2>Template</h2>
                </div>
                <div className="col-2 mt-md-0 mt-2">
                    <span className='btn btn-sm btn-warning float-right' onClick={() => { settemplateadd(true); settemplate(0); }}>
                        <i className="fa fa-plus mr-2" />
                        Add Template
                    </span>
                </div>
                <div className="col-12">
                    <div className="custom-control custom-switch float-right">
                        <input type="checkbox" className="custom-control-input" defaultChecked id="customSwitch1" data-check_true={true} data-check_false={false} onChange={(event) => {
                            let ele = event.target;
                            let selectedId = ele.checked ? event.target.getAttribute("data-check_true") : event.target.getAttribute("data-check_false");
                            getpost(selectedId)
                        }} />
                        <label className="custom-control-label" for="customSwitch1"> Active/Inactive </label>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 mt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </div>
            </div>
            <CModal alignment="center" size='lg' visible={templateadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{template == 0 ? "Add Template" : "Update Template"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); settemplateadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={template == 0 ? handleSubmit : handleEdit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='row'>
                                        <div className="col-6 mt-2">
                                            <label>Template For</label>
                                            <select disabled={template == 0 ? false : true} defaultValue={values.TemplateFor} className={!!touched.TemplateFor && !!errors.TemplateFor ? "form-select is-invalid" : "form-select"} name='TemplateFor'>
                                                <option value=''>Select Template For</option>
                                                <option value='Quotation'>Quotation</option>
                                                <option value='Order'>Order</option>
                                                <option value='Invoice'>Invoice</option>
                                                <option value='Support'>Support</option>
                                            </select>
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label>Name</label>
                                            <input type='text' name="Name" defaultValue={values.Name} placeholder='Enter Template Name' className={!!touched.Name && !!errors.Name ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className="col-12 mt-2">
                                            <CKEditor
                                                name="Detail"
                                                id="Detail"
                                                content={values.Detail}
                                                events={{
                                                    change: (eve) => {
                                                        const data = eve.editor.getData();
                                                        setFieldValue("Detail", data);
                                                    }
                                                }} />
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <button type="submit" className='btn  btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div >
    );
}

export default Template;
import React, { useEffect, useState } from 'react';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import axios from "axios";
import * as Yup from "yup";
import { Link } from 'react-router-dom';
import { immediateToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import $ from 'jquery';
import "../Layout/login.css";
import styles from './Login.module.scss';
import { Btn, Spinner } from '../AbstractElements';

const validation = Yup.object().shape({
    email: Yup.string().required(),
});

const initValue = {
    email: "",
};

const ForgotPassword = () => {
    const [loading, setloading] = useState(false);

    useEffect(() => {
        $("#root").addClass('loginpage');
    }, []);

    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }

    const handleSubmit = async (val) => {
        setloading(true)
        const headers = {
            'Content-Type': 'application/json',
            'db_name': window.localStorage.getItem("DB_NAME"),
        }
        setTimeout(async () => {
            await axios
                .post(`${process.env.REACT_APP_APIURL}api/users/forgotPassword`, val, {
                    headers: headers
                })
                .then((res) => {
                    if (res.data.success == true) {
                        showTost(res.data.msg, "success");
                        window.location.replace("/Login");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
            setloading(false)
        }, 2000);
    };

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initValue}
            validationSchema={validation}
        >
            {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                setFieldValue,
            }) => {
                return (
                    <section classn="ftco-section">
                        <div className="container loginform">
                            <div className="row justify-content-center">
                                <div className="col-md-7 col-lg-5 mt-5">
                                    <div className="wrap">
                                        <div className="login-wrap p-4 p-md-5">
                                            <div className="d-flex justify-content-center">
                                                <div className="row justify-content-center">

                                                    <div className="text-center mb-4">
                                                        <img className={`me-3 rounded-circle ${styles.fitimg}`} src="/../logo.jpg" alt=""></img>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="row justify-content-center">
                                                    <div className="text-center mb-5">
                                                        <h2 className="heading-section">Forgot Password</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <form onSubmit={handleSubmit} className="signin-form">
                                                <div className="form-group mt-3">
                                                    <input type="email" name='email' onChange={handleChange} className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} />
                                                    <label className="form-control-placeholder">Email</label>
                                                </div>
                                                <div className="form-group">
                                                    {loading ?
                                                        <div className={`loader-box ${styles.Loader}`}>
                                                            <Spinner className={'loader-16'} />
                                                        </div>
                                                        :
                                                        <button type="submit" className="form-control btn btn-sm btn-primary rounded submit px-3" style={{ backgroundColor: "blue" }}>Submit</button>
                                                    }
                                                </div>
                                                <div className='text-end'>
                                                    <Link to={"/Login"}>Back to Login</Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }}
        </Formik>
    )
}

export default ForgotPassword;
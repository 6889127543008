import React, { useEffect, useState } from 'react';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import * as Yup from "yup";
import moment from "moment";
import Select from "react-select";
import { immediateToast } from "izitoast-react";
import { Link, useParams, useLocation } from 'react-router-dom';
import Swal from "sweetalert2";
import { ProductModal, UserModal, CustomerModal } from './Modal.js';
import { InlineEdit, Input } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const validation = Yup.object().shape({
    customer: Yup.string().required(),
    qty: Yup.number().required(),
    price: Yup.number().required(),
    ticketDate: Yup.date().required(),
    dueDate: Yup.date().required(),
    note: Yup.string().required(),
    sales: Yup.string().required(),
    product: Yup.string().required(),
    // ticketcode: Yup.string().required(),
});
const validationnext = Yup.object().shape({
    // date: Yup.string().required(),
    note: Yup.string().required(),
});
const Supportadd = () => {
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const genrateKeys = () => {
        setFormKey(Math.random() * 1000000);
    }
    const [initValue, setinitValue] = useState({
        customer: "",
        qty: "",
        price: "",
        ticketDate: "",
        dueDate: "",
        note: "",
        sales: "",
        product: "",
        ticketcode: "",
    });
    const clear = () => {
        setinitValue({
            customer: "",
            qty: "",
            price: "",
            ticketDate: "",
            dueDate: "",
            note: "",
            sales: "",
            product: "",
            ticketcode: "",
        });
    }
    const { id } = useParams();
    const location = useLocation();
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [productadd, setproductadd] = useState(false);
    const [useradd, setuseradd] = useState(false);
    const [customeradd, setcustomeradd] = useState(false);
    const [Manual, setManual] = useState(false);
    const [product, setproduct] = useState([]);
    const [customer, setcustomer] = useState([]);
    const [sales, setsales] = useState([]);
    const [productval, setproductval] = useState(0);
    const [customerval, setcustomerval] = useState(0);
    const [userval, setuserval] = useState(0);
    const getproductpost = async (val) => {
        Post(`master/getProduct`, { active: true })
            .then((res) => {
                setproduct(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getcustomerpost = async () => {
        Post(`customer/getAllCustomer`, { active: true })
            .then((res) => {
                setcustomer(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getuserpost = async (val) => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setsales(res)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    useEffect(() => {
        getproductpost();
        getuserpost();
        getcustomerpost();
        Get(`master/getApplicationSetting`)
            .then((res) => {
                setManual(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        if (id) {
            getbyid(id)
        }
    }, [])
    const handleSubmit = async (val) => {
        await Post(`support/addSupport`, val)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.msg, "warning");
                }
                else {
                    clear();
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            window.location.replace('/Support');
                        }
                    });
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleEdit = async (val) => {
        val.id = id;
        await Post(`support/editSupport`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            window.location.replace('/Support');
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const getbyid = async (val, ste) => {
        if (val != 0) {
            Get(`support/getSupportById/${val}`)
                .then((res) => {
                    setinitValue({
                        customer: res.data[0].Customer?._id,
                        qty: res.data[0].Qty,
                        ticketcode: res.data[0].TicketCode,
                        price: res.data[0].Price,
                        ticketDate: moment(res.data[0].TicketDate).format("YYYY-MM-DD"),
                        dueDate: moment(res.data[0].DueDate).format("YYYY-MM-DD"),
                        note: res.data[0].Note,
                        sales: res.data[0].Sales?._id,
                        product: res.data[0].Products?._id,
                    });
                    genrateKeys();
                    gettonext(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const [initValuenext, setinitValuenext] = useState({
        date: new Date(),
        note: "",
    });
    const clearnext = () => {
        setinitValuenext({
            date: new Date(),
            note: "",
            id: "",
            supportid: ""
        });
    }

    const [Next, setNext] = useState([]);
    // const editnext =  (val) => { 
    //     setinitValuenext({
    //         id: val._id,
    //         date: moment(val.date).format("YYYY-MM-DD"),
    //         note: val.note,
    //     });
    //     genrateKeys();
    // };
    const gettonext = async (val) => {
        Get(`support/getNext/${val}`)
            .then((res) => {
                setNext(res.data);
                genrateKeys();
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleNoteChange = (id, note) => {
        setNext((prevNext) => prevNext.map((item) => {
            if (item._id === id) {
                return { ...item, note };
            }
            return item;
        }));
    };
    const saveNote = async (id) => {
        const noteToSave = Next.find((item) => item._id === id);
        await Post(`support/editNext`, { id: noteToSave._id, note: noteToSave.note, date: noteToSave.date, })
            .then((res) => {
                if (res.success) {
                    showTost("Your note has been successfully updated", "success");
                } else {
                    showTost("Failed to update the note", "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const savenext = async (val) => {
        // if (val.id) {
        //     await Post(`support/editNext`, {
        //         id: val.id,
        //         date: new Date().toISOString(),
        //         note: val.note
        //       })
        // await Post(`support/editNext`, val)
        //     .then((res) => {
        //         if (res.success == false) {
        //             showTost(res.msg, "warning");
        //         }
        //         else {
        //             gettonext(id);
        //             clearnext();                      
        //             genrateKeys();
        //             showTost("Your data has been successfully updated", "success");
        //         }
        //     })
        //     .catch((err) => {
        //         showTost("SOMETHING WENT WRONG ON SERVER", "error");
        //     });
        // }
        // else {
        val.supportid = id;
        await Post(`support/addNext`, val)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.msg, "warning");
                }
                else {
                    gettonext(id);
                    clearnext();
                    genrateKeys();
                    showTost("Your data has been Successfully added", "success");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        // }

    }
    return (
        <div className='py-2 mx-3'>
            <div className='d-flex '>
                <Link to={'/Support'}>
                    <button className='btn btn-sm font-weight-bold btn-primary'>
                        <i className="fa fa-angle-double-left f-16 mr-1" />Back
                    </button>
                </Link>
                <h4 className="ml-2 mb-0">{id == 0 ? "Add Support" : "Edit Support"}</h4>
            </div>
            <div className='card bg-white'>
                <div className='py-2 mb-2'>
                    <Formik
                        onSubmit={id == 0 ? handleSubmit : handleEdit}
                        initialValues={initValue}
                        validationSchema={validation}
                        key={formkey} >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div>
                                        <div className='row m-0 my-2'>
                                            <div className='col-lg-6 mt-2'>
                                                <label>Customer</label>
                                                <div className='input-group'>
                                                    <Select
                                                        name="customer"
                                                        value={customer.map((x) => ({
                                                            value: x._id,
                                                            label: `${x.Company} (${x.FirstName} ${x.LastName})`,
                                                        })).find((option) => option.value === values.customer) || null}
                                                        onChange={(e) => {
                                                            setFieldValue("customer", e.value);
                                                        }}
                                                        options={customer.map((x) => ({
                                                            value: x._id,
                                                            label: `${x.Company} (${x.FirstName} ${x.LastName})`,
                                                        }))}
                                                        className={touched.customer && errors.customer ? "is-invalid w-75" : "w-75"}
                                                    />
                                                   <span className="input-group-text">
                                                            {values.customer ? (
                                                                <i className='fa fa-pencil' onClick={() => { setcustomerval(values.customer); setcustomeradd(true) }} />
                                                            ) : (
                                                                <i className='fa fa-plus' onClick={() => { setcustomerval(0); setcustomeradd(true) }} />
                                                            )}
                                                            {customeradd && (
                                                                <CustomerModal
                                                                    setcustomeradd={setcustomeradd}
                                                                    customeradd={customeradd}
                                                                    getcustomerpost={getcustomerpost}
                                                                    showTost={showTost}
                                                                    Manual={Manual}
                                                                    setcustomerval={setcustomerval}
                                                                    customerval={customerval}
                                                                />)}
                                                        </span>
                                                </div>
                                            </div>
                                            {
                                                Manual?.Ticket == true ? <div className='col-lg-6 mt-2'>
                                                    <label>Ticket Code</label>
                                                    <input type='text' name="ticketcode" defaultValue={values.ticketcode} placeholder='Enter Ticket Code' className={!!touched.ticketcode && !!errors.ticketcode ? "form-control is-invalid" : "form-control"} />
                                                    <div>{id == 0 ?
                                                        <span>Last Ticket Code:
                                                        <b>{location.state}</b></span>:""}
                                                    </div>
                                                </div> : ""
                                            }
                                            <div className='col-lg-6 mt-2'>
                                                <label>Ticket Date</label>
                                                <input type='date' defaultValue={values.ticketDate} name="ticketDate" max={new Date().getFullYear() + '-' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '-' + ("0" + new Date().getDate()).slice(-2)} className={!!touched.ticketDate && !!errors.ticketDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-lg-6 mt-2'>
                                                <label>Due Date</label>
                                                <input type='date' defaultValue={values.dueDate} name="dueDate" min={new Date().getFullYear() + '-' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '-' + ("0" + new Date().getDate()).slice(-2)} className={!!touched.dueDate && !!errors.dueDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-lg-6 mt-2'>
                                                <label>Sales</label>
                                                <div className='input-group'>
                                                    <select defaultValue={values.sales} className={!!touched.sales && !!errors.sales ? "form-select is-invalid" : "form-select"} name='sales'>
                                                        <option>Select Sales</option>
                                                        {sales
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.sales ? (
                                                            <i className='fa fa-pencil' onClick={() => { setuserval(values.sales); setuseradd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setuserval(0); setuseradd(true) }} />
                                                        )}
                                                        {useradd && (
                                                            <UserModal
                                                                useradd={useradd}
                                                                setuseradd={setuseradd}
                                                                getuserpost={getuserpost}
                                                                showTost={showTost}
                                                                userval={userval}
                                                                setuserval={setuserval}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 mt-2'>
                                                <label>Note</label>
                                                <textarea type='text' defaultValue={values.note} rows="3" name="note" placeholder='Enter Note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-lg-6 mt-2'>
                                                <label>Product</label>
                                                <div className='input-group'>
                                                    <select defaultValue={values.product} className={!!touched.product && !!errors.product ? "form-select is-invalid" : "form-select"} name='product'>
                                                        <option>Select Product</option>
                                                        {product
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.Name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                            {values.product ? (
                                                                <i className='fa fa-pencil' onClick={() => { setproductval(values.product); setproductadd(true) }} />
                                                            ) : (
                                                                <i className='fa fa-plus' onClick={() => { setproductval(0); setproductadd(true) }} />
                                                            )}
                                                            {productadd && (
                                                                <ProductModal
                                                                    setproductadd={setproductadd}
                                                                    productadd={productadd}
                                                                    getproductpost={getproductpost}
                                                                    showTost={showTost}
                                                                    setproductval={setproductval}
                                                                    productval={productval}
                                                                />)}
                                                        </span>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 mt-2'>
                                                <label>Quantity</label>
                                                <input type='text' name="qty" defaultValue={values.qty} placeholder='Enter Quantity' className={!!touched.qty && !!errors.qty ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-lg-6 mt-2'>
                                                <label>Price</label>
                                                <input type='text' name="price" defaultValue={values.price} placeholder='Enter Price' className={!!touched.price && !!errors.price ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-12 mt-4 align-items-center d-flex justify-content-center'>
                                                <div className=''>
                                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                                </div>
                                                <div className='ml-2'>
                                                    <Link to={'/Support'}>
                                                        <button className='btn btn-sm font-weight-bold bg-danger'><i className="fa fa-times" /> Cancel</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <div>
                {id == 0 ? "" : (
                    <div className='row m-0 '>
                        <div className='col-lg-12' >
                            <div className='mt-4'>
                                <h4 className='m-0'>Comments</h4>
                            </div>
                            <div>
                                <Formik
                                    onSubmit={savenext}
                                    initialValues={initValuenext}
                                    validationSchema={validationnext}
                                    key={formkey} >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        touched,
                                        errors,
                                        setFieldValue,
                                    }) => {
                                        return (
                                            <Form
                                                onSubmit={handleSubmit}
                                                onChange={handleChange}>
                                                <div className='row mt-2'>
                                                    <div className='col-lg-12'>
                                                        <textarea rows="5" type='text' value={values.note} name='note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} placeholder='Note' />
                                                    </div>
                                                    <div className='col-lg-12 text-right'>
                                                        <input type='date' name='date' value={values.date} className={!!touched.date && !!errors.date ? "form-control is-invalid" : "form-control"} style={{ display: 'none' }} />
                                                        <div className='mt-2 '>
                                                            <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </div>
                            <div className="mt-2 p-2">
                                <div className="text-info">
                                    <span>Interactions</span>
                                </div>
                                <div>
                                    {Next.map((x, i) => (
                                        <div className='card p-3'>
                                            <div className='row'>
                                                <div className='col-12 d-flex justify-content-between align-items-center'>
                                                    <div>
                                                        <span className='text-dark text-capitalize' style={{ fontSize: '14px' }}>
                                                            <span><i class="fa fa-user text-primary f-14 mr-2"></i></span>
                                                            <span>{x.user?.name}</span>
                                                        </span>
                                                        <span className='mx-2 f-12'>
                                                            <span><i class="fa fa-calendar text-primary f-11 mr-2" ></i></span>
                                                            <span>{moment(x.date).format("DD-MMM")}</span>
                                                        </span>
                                                    </div>
                                                    {/* <div>
                                                    <button className='btn btn-sm btn-success' onClick={() => editnext(x) }>
                                                        <i className='fa fa-pencil' />
                                                    </button>
                                                </div> */}
                                                </div>
                                                <div className='col-12 mt-2 inlineEdit'>
                                                    <InlineEdit
                                                        value={x.note}
                                                        onChange={(newNote) => handleNoteChange(x._id, newNote)}
                                                        onBlur={() => saveNote(x._id)}
                                                    // onBlur={() => savenext(x._id)}
                                                    >
                                                        <Input as="textarea" rows={5} className='w-100' />
                                                    </InlineEdit>

                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )

}



export default Supportadd;
import { Image } from "../../AbstractElements";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Post, Get, FileUpload } from '../../Layout/ajax';
const LeftHeader = () => {
  const [preview, setPreview] = useState(null);
  useEffect(() => {
    getpost();
  }, []);
  const getpost = async (val) => {
    await Get(`master/getApplicationSetting`)
      .then((res) => {
        setPreview(process.env.REACT_APP_APIURL + 'static/' + res.data.CompanyLogo);
      })
      .catch((err) => {
        console.log("SOMETHING WENT WRONG ON SERVER", "error");
      });
  }
  return (
    <div className="main-header-left">
      <div className="logo-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/Taskboard`}>
        <Image body={true} src={preview} alt="logo" style={{ width: "51px" }}/>     
      </Link>
      </div> 
    </div>
  );
};

export default LeftHeader;
